/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Container,
  TextField,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Typography,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetApplicationByApplicationIdQuery, usePostValidateNextStageMutation } from "@api/applications";
import { CustomDialog, CustomSwitch, ProgressBar } from "@components/index";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { useCiecValidatorMutation, CiecValidatorRequest } from "@api/sat_apis_new/satApsi";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomCircularProgress from "@components/CustomCircularProgress";
import { Visibility, VisibilityOff, ErrorOutline } from "@mui/icons-material";
import img from "@assets/img/ciec_img_right.svg";
import { setCompanyData, setLoanStatus } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";

const SECONDS_TO_FETCH = 10 * 1000;

const ConsultaCiec = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const timer = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [ciecValidation] = useCiecValidatorMutation();
  const [getLoadApplication] = useLazyGetApplicationByApplicationIdQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isValidCiec, setIsValidCiec] = useState<boolean | null>(null);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isActiveQuery, setIsActiveQuery] = useState(false);
  const [closeDialog, setCloseDialog] = useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      ciec: "",
      switch: false,
    },
    validationSchema: yup.object().shape({
      ciec: yup.string().min(8, "Formato incorrecto, debe tener 8 dígitos").required("Campo requerido."),
      switch: yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);

          const payload_ciec: CiecValidatorRequest = {
            rfc: companyData.rfc,
            ciec: values.ciec,
            application_code: application.company_application_code,
            client_details_id: String(application.client_details_id),
            loan_application_conditions_id: String(application.application_condition_id),
            loan_application_id: String(application.id),
            requested_amount: Number(application.loan_amount),
            requested_term: Number(application.loan_term),
          };
          await ciecValidation(payload_ciec).unwrap();

          setOpen(true);
          setIsValidCiec(null);
          setIsActiveQuery(true);
        } catch (error: any) {
          if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
            closeModal();
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            closeModal();
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          }
        } finally {
          setSubmitting(false);
        }
      }
    },
    validateOnMount: true,
  });

  const closeModal = (isError = false) => {
    setIsValidCiec(null);
    setOpen(false);
    if (isError) {
      formik.setFieldError("ciec", "La CIEC ingresada es inválida.");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }
  };

  const nexPage = async () => {
    try {
      const next_status = STATUS_CATALOG.uso_de_credito.code;
      const next_url = STATUS_CATALOG.uso_de_credito.url;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: next_status,
        username: `TOFU - ${accountUser.email}`,
      }).unwrap();
      closeModal();
      dispatch(setLoanStatus(next_status));
      dispatch(
        setCompanyData({
          ...companyData,
          is_correct_ciec: true,
        }),
      );
      navigate(next_url);
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500 || error?.status === 504) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      }
    }
  };

  useEffect(() => {
    if (isValidCiec !== null) {
      setIsActiveQuery(false);
      if (isValidCiec === true) {
        nexPage();
      }
    }
  }, [isValidCiec]);

  useEffect(() => {
    if (isActiveQuery) {
      timer.current = setInterval(async () => {
        try {
          const resultado = await getLoadApplication(application.id).unwrap();
          if (resultado.data[0].is_correct_ciec !== null) {
            setIsValidCiec(resultado.data[0].is_correct_ciec);
          }
        } catch (error: any) {
          console.error("Ha ocurrido un error en la consulta de CIEC");
        }
      }, SECONDS_TO_FETCH);
    } else {
      timer.current && clearInterval(timer.current);
    }

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, [isActiveQuery]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const resultado = await getLoadApplication(application.id).unwrap();
        if (resultado.data[0].is_correct_ciec === true) {
          setIsValidCiec(true);
        }
      } catch (error) {
        console.error("Ha ocurrido un error al consultar el estatus de la solicitud");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleOnCloseDialog = () => {
    setCloseDialog(true);
  };

  useEffect(() => {
    if (formik.values.switch) {
      setCloseDialog(false);
    } else {
      setCloseDialog(true);
    }
  }, [formik.values.switch]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center" color="#002652">
              Registro de empresa
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={application.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 4 : 5} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                <span>
                  Haremos una consulta al SAT y al buró de crédito <br />
                  del siguiente RFC, para obtener tu oferta personalizada
                </span>
              </Typography>
            </Box>

            <Typography mt={isMobileOrTablet ? 2 : 3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
              {companyData.legal_name}
            </Typography>

            <Typography mt={isMobileOrTablet ? 2 : 3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
              {companyData.rfc}
            </Typography>

            <Typography mt={isMobileOrTablet ? 2 : 3} variant={isMobileOrTablet ? "body2" : "body1"}>
              Ingresa la contraseña
            </Typography>

            <Container maxWidth="sm" sx={{}}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="ciec"
                  name="ciec"
                  label="Contraseña del SAT (CIEC)"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ciec}
                  error={formik.touched.ciec && Boolean(formik.errors.ciec)}
                  helperText={formik.touched.ciec && formik.errors.ciec}
                  inputProps={{ maxLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isLoading || formik.isSubmitting}
                  inputRef={inputRef}
                />

                <Box display={"flex"} justifyContent={"center"} mt={isMobileOrTablet ? 2 : 3} textAlign="center">
                  <Typography textAlign={"center"} color={"#002652"}>
                    <span>
                      <small>
                        Realizaremos una evaluación que nos determinará la factibilidad de poder acceder a
                        financiamiento. Al continuar, el solicitante forma una relación jurídica como cliente de Lendit
                        Group SAPI de CV y {companyData.legal_name} y las autoriza a realizar consultas y evaluar
                        información por mecanismos digitales.
                      </small>
                    </span>
                  </Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"} mt={isMobileOrTablet ? 2 : 3} textAlign="center">
                  <FormControlLabel
                    control={<CustomSwitch />}
                    label={
                      <Typography variant={isMobileOrTablet ? "body2" : "body1"}>
                        Autorizo a Lendit Group a consultar mi información ante el SAT. Consulta{" "}
                        <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                          términos y condiciones
                        </Link>{" "}
                        y{" "}
                        <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                          aviso de privacidad
                        </Link>{" "}
                      </Typography>
                    }
                    name="switch"
                    checked={formik.values.switch}
                    onChange={formik.handleChange}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Box>

                <Box display={"flex"} justifyContent={"center"} mt={isMobileOrTablet ? 2 : 3} mb={2}>
                  <Button
                    type="submit"
                    variant="default"
                    disabled={!formik.isValid || isLoading || formik.isSubmitting}
                  >
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick") {
            closeModal();
          }
        }}
      >
        <DialogTitle>
          {isValidCiec === false ? (
            <Typography color={"#002652"} textAlign={"center"} fontSize={"20px"} fontWeight={700}>
              La CIEC ingresada
              <br />
              es inválida.
            </Typography>
          ) : (
            <Typography color={"#002652"} textAlign={"center"} fontSize={"20px"} fontWeight={700}>
              Estamos estableciendo
              <br />
              conexión con el SAT
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {isValidCiec === false ? (
            <>
              <Typography color={"#002652"} textAlign={"center"} fontSize={"18px"} fontWeight={400}>
                Inténtalo de nuevo.
              </Typography>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <ErrorOutline style={{ color: "red", fontSize: "40px" }} />
              </Box>

              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button variant="default" onClick={() => closeModal(true)}>
                  Cerrar
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <CustomCircularProgress size={150} />
              </Box>

              <Box mt={2} mb={2}>
                <Typography color={"#528CD6"} fontSize={"20px"} fontWeight={700} textAlign={"center"}>
                  Esto puede demorar unos
                  <br />
                  minutos
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      <CustomDialog
        colorClose="#A3D4E8"
        open={!closeDialog}
        onClose={handleOnCloseDialog}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600}>
            Consulta a Buró de crédito
          </Typography>
        }
        itemContent={
          <>
            <Typography textAlign={"center"} variant="body2" color={"#002652"}>
              Por este conducto autorizo expresamente a Lendit Group SAPI de CV y a {companyData.legal_name}, para que
              por conducto de sus funcionarios facultados lleve a cabo investigaciones sobre mi comportamiento
              crediticio en las Sociedades de Información Crediticia que estime conveniente.
            </Typography>

            <Typography textAlign={"center"} variant="body2" color={"#002652"} mt={2}>
              Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que
              Lendit Group SAPI de CV hará de tal información y de que ésta podrá realizar consultas periódicas de mi
              historial crediticio, consintiendo que esta autorización se encuentre vigente por un periodo de 3 años
              contados a partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos relación
              jurídica.
            </Typography>

            <Typography textAlign={"center"} variant="body2" color={"#002652"} mt={2} mb={3}>
              Declaro bajo protesta de decir verdad ser Representante Legal de la empresa mencionada en esta
              autorización; manifestando que a la fecha de la presente autorización los poderes no me han sido
              revocados, ni limitados en forma alguna.
            </Typography>
          </>
        }
      />
    </>
  );
};

export default ConsultaCiec;
