/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UUID } from "crypto";
import * as yup from "yup";
import { FormikErrors } from "formik";
import { useGetDocsConfigQuery } from "@api/catalogs";
import { DocumentConfig, DocumentResponse, ErrorResponse } from "@interfaces/index";
import {
  useLazyGetSignUrlToUploadQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  useUploadFileMutation,
} from "@api/documents";
import { useAppDispatch } from "@store/store";
import { setUploadModalErrorVisible } from "@store/slices/appSlice";
import CloseIcon from "@mui/icons-material/Close";
import Done from "@assets/img/done.svg";

interface CustomUploadFileProps extends BoxProps {
  inputName: string;
  disabled?: boolean;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  registerFormik?: (params: { name: string; schema: yup.Schema }) => void;
  uploadedFile?: DocumentResponse;
  setUploadedFile?: (file: DocumentResponse | undefined) => void;
  optionalName?: string;
  optionalDescription?: string;
  index?: number;
  metadata: {
    fileCode: string;
    taxCode: string;
    uploadType: "client" | "person";
    loanApplicationId: string;
    regimen: string;
    clientDetailsId?: UUID;
    personType?: string;
    personCode?: string;
    personId?: UUID;
  };
}

const CustomUploadFileV2 = ({
  inputName,
  setFieldValue,
  registerFormik,
  uploadedFile,
  setUploadedFile,
  optionalName,
  optionalDescription,
  metadata,
  disabled,
  ...props
}: CustomUploadFileProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const [fileCategory, setFileCategory] = useState<DocumentConfig>();
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any | null>(null);

  const documentConfig = useGetDocsConfigQuery({ taxCode: metadata.taxCode, personCode: metadata.personCode });
  const [triggerUploadFile] = useUploadFileMutation();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerGetSignUrl] = useLazyGetSignUrlToUploadQuery();

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.item(0);
    const fileName: string = file ? file.name : "";
    try {
      if (file && fileCategory) {
        setLoading(true);

        const signUrlResponse = await triggerGetSignUrl({
          upload_type: metadata.uploadType,
          file_name: file.name,
          regimen: metadata.regimen === import.meta.env.VITE_CODE_TAXS_CS_PM ? "pm" : "pfae",
          client_details_id: metadata.clientDetailsId ? metadata.clientDetailsId : undefined,
          person_id: metadata.personId ? metadata.personId : undefined,
          file_category_id: fileCategory.file_category_id,
          person_type: metadata.personType ? metadata.personType : undefined,
          loan_application_id: metadata.loanApplicationId,
        }).unwrap();

        const tmp = new FormData();

        tmp.append("key", signUrlResponse.fields.key);
        tmp.append("AWSAccessKeyId", signUrlResponse.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrlResponse.fields["x-amz-security-token"]);
        tmp.append("policy", signUrlResponse.fields.policy);
        tmp.append("signature", signUrlResponse.fields.signature);
        tmp.append("file", file);

        await triggerUploadFile({
          url: signUrlResponse.url,
          body: tmp,
        }).unwrap();

        const documentResponse = await triggerPostDocument({
          loan_application_id: metadata.loanApplicationId,
          file_category_id: fileCategory.file_category_id,
          client_details_id: metadata.uploadType === "client" ? metadata.clientDetailsId : undefined,
          person_id: metadata.uploadType === "person" ? metadata.personId : undefined,
          url: signUrlResponse.fields.key,
          file_name: fileName,
        }).unwrap();

        setUploadedFile?.(documentResponse);
      }
      dispatch(setUploadModalErrorVisible({ file_name: "", visible: false }));
    } catch (error) {
      dispatch(setUploadModalErrorVisible({ file_name: fileName ? fileName : "", visible: true }));
    } finally {
      event.target.value = "";
      setLoading(false);
    }
  };

  const handleOnClickDelete = async () => {
    try {
      if (!uploadedFile) return;

      setLoading(true);

      await triggerPatchDocument({
        documentId: uploadedFile.id,
        body: { status: false },
      }).unwrap();

      setUploadedFile?.(undefined);
    } catch (error) {
      const apiError = error as ErrorResponse;
      if (apiError.status === 403) {
        setErrorMessage("El archivo no pudo ser eliminado debido a que se encuentra validado");
      } else {
        setErrorMessage("Ha ocurrido un error al eliminar el archivo");
      }
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOnReuploaded = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      if (!uploadedFile) return;

      setLoading(true);

      await triggerPatchDocument({
        documentId: uploadedFile.id,
        body: { status: false },
      }).unwrap();

      await handleOnChange(event);
    } catch (error) {
      console.log("Ha ocurrido un error al re-subir el archivo");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (documentConfig.isSuccess) {
      const fileCategory = documentConfig.data.find((file) => file.file_category.code === metadata.fileCode);
      setFileCategory(fileCategory);

      if (fileCategory?.optional === false) {
        registerFormik?.({
          name: inputName,
          schema: yup.boolean().isTrue("Este documento es requerido").required("Este documento es requerido"),
        });
      }
    }
  }, [documentConfig]);

  return (
    <Box {...props}>
      <Grid container>
        <Grid item xs={8} pr={2}>
          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            // color={isRequired ? "#CD0202" : "#002652"}
            textAlign={"left"}
          >
            {documentConfig.isFetching ? (
              <Skeleton variant="text" animation={"wave"} />
            ) : (
              <>
                {optionalName ? optionalName : fileCategory?.file_category.name}{" "}
                {fileCategory?.optional == false && (
                  <Typography component={"span"} color={"#CD0202"}>
                    *
                  </Typography>
                )}
              </>
            )}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <label htmlFor={`upload-file_${inputName}`}>
              <input
                type="file"
                id={`upload-file_${inputName}`}
                name={`upload-file_${inputName}`}
                accept="application/pdf,image/jpeg,image/png"
                hidden
                onChange={uploadedFile?.validated === false ? handleOnReuploaded : handleOnChange}
              />

              {!uploadedFile?.id && (
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  component={"span"}
                  disabled={loading || documentConfig.isFetching || disabled}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white", paddingTop: "5px", paddingBottom: "5px" }} />
                  ) : (
                    "Cargar"
                  )}
                </Button>
              )}

              {uploadedFile?.id && uploadedFile?.validated === false && (
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  component={"span"}
                  sx={{ backgroundColor: theme.palette.secondary.main, width: isMobileOrTablet ? "150px" : "160px" }}
                  disabled={loading || documentConfig.isFetching || disabled}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white", paddingTop: "5px", paddingBottom: "5px" }} />
                  ) : (
                    "Volver a cargar"
                  )}
                </Button>
              )}
            </label>
          </Box>
        </Grid>
      </Grid>

      {uploadedFile?.id && uploadedFile.validated !== false && (
        <Grid container>
          <Grid item xs={4} textAlign="left">
            <Typography
              component={"span"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={"#00A024"}
              fontWeight={400}
            >
              Archivo cargado:
            </Typography>
          </Grid>
          <Grid item xs={7} textAlign="left">
            <Typography
              noWrap
              display={"inline-block"}
              maxWidth={"100%"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={"#002652"}
              fontWeight={400}
            >
              {uploadedFile.file_name}
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" flexDirection="row" alignItems="center">
            <img src={Done} width={20} height={15} />
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={7} textAlign={"left"} pr={4}>
          <Typography
            variant={isMobileOrTablet ? "body3" : "body2"}
            fontWeight={400}
            // color={isRequired ? "#CD0202" : "#002652"}
          >
            {documentConfig.isFetching ? (
              <Skeleton variant="text" animation={"wave"} />
            ) : (
              optionalDescription || fileCategory?.file_category.description
            )}
          </Typography>
        </Grid>

        <Grid item xs={5} display="flex" justifyContent="flex-end">
          {uploadedFile?.id && (
            <Box>
              {uploadedFile?.validated === null && (
                <Button
                  variant="expediente"
                  onClick={handleOnClickDelete}
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                  }}
                  disabled={loading || documentConfig.isFetching || disabled}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white", paddingTop: "5px", paddingBottom: "5px" }} />
                  ) : (
                    "Eliminar"
                  )}
                </Button>
              )}
              {uploadedFile?.validated === true && (
                <Button variant="expediente_validado" size={isMobileOrTablet ? "small" : "medium"}>
                  Validado
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ backgroundColor: "#A3D4E8", mt: 2 }} />

      <Dialog open={showErrorMessage && errorMessage} maxWidth="xs">
        <DialogTitle>
          <Box position={"relative"} mt={2}>
            <IconButton sx={{ position: "absolute", top: -20, right: -20 }} onClick={() => setShowErrorMessage(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} textAlign={"center"}>
              {errorMessage}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="contained" color="primary" onClick={() => setShowErrorMessage(false)}>
              Aceptar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomUploadFileV2;
