/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import img from "@assets/img/img_tofu/img_tofu_15.svg";
import { RootState, useAppDispatch } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import { useEffect, useRef, useState } from "react";
import { useLazyGetPersonsQuery } from "@api/personasApi";
import { useLazyGetMetaMapDataQuery } from "@api/metaMapApi";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { PERSON_CODES_AVAL, PERSON_CODES_LEGAL_REPRESENTATIVE } from "@helpers/constantes";
import { ErrorResponse } from "@interfaces/response";
import { MetaDataJson } from "@interfaces/catalogs";

interface ValidarIdentidadProps {
  person: "REP_LEGAL" | "AVAL";
}

const ValidacionIdentidadPersona: React.FC<ValidarIdentidadProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const metamapButtonRef = useRef<HTMLDivElement>(null);

  const applicationData = useSelector((state: RootState) => state.register.application);

  const [getDataMetaMap] = useLazyGetMetaMapDataQuery();
  const [getPersons] = useLazyGetPersonsQuery();

  const [personName, setPersonName] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [metaDataJson, setMetaDataJson] = useState<MetaDataJson | null>(null);

  const goBack = () => {
    if (props.person === "REP_LEGAL") {
      navigate("/producto/creditosimple/avance_expediente_digital");
    } else {
      navigate("/producto/creditosimple/docs_aval");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let person_list = [];
        if (props.person === "REP_LEGAL") {
          person_list = await getPersons({
            loanApplicationId: applicationData.id,
            personTypeCode: [...PERSON_CODES_LEGAL_REPRESENTATIVE],
          }).unwrap();
        } else {
          person_list = await getPersons({
            loanApplicationId: applicationData.id,
            personTypeCode: [...PERSON_CODES_AVAL],
          }).unwrap();
        }

        const person_selected = person_list[0];

        if (!person_selected || person_selected.identity_validation_completed) {
          goBack();
          return;
        }

        setPersonName([person_selected.name, person_selected.last_name, person_selected.last_name_2].join(" ").trim());

        const metamap_res = await getDataMetaMap(person_selected.id).unwrap();
        const metamap_info = metamap_res?.data;

        if (metamap_info.attempts > 3 || true) {
          return;
        }

        setMetaDataJson({
          client_details_id: metamap_info.client_details_id,
          loan_application_id: metamap_info.loan_application_id,
          person_id: metamap_info.person_id,
          person_type: metamap_info.person_type,
          regimen: metamap_info.regimen,
          attempts: metamap_info.attempts,
          upload_type: metamap_info.upload_type,
        });
        setEnableButton(true);
      } catch (error) {
        const error_response = error as ErrorResponse;
        if (error_response.status === 500 || error_response.status === 403 || error_response.status === "FETCH_ERROR") {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const handleUserFinishedSdk = (_event: CustomEvent) => {
      setMetaDataJson(null);
      setEnableButton(false);
      goBack();
    };

    const metamapButton = metamapButtonRef.current;

    if (metamapButton) {
      metamapButton.addEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
    }

    return () => {
      if (metamapButton) {
        metamapButton.removeEventListener("metamap:userFinishedSdk", handleUserFinishedSdk as EventListener);
      }
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto">
          <Typography
            textAlign={"center"}
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={"#002652"}
          >
            Validación identidad
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={"#528CD6"}
            mt={4}
          >
            {personName || ""}
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={"#002652"}
            mt={4}
          >
            Validaremos tu identidad por medio de fotografías faciales y tu identificación oficial
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={"#002652"}
            mt={4}
          >
            {props.person === "REP_LEGAL"
              ? "En caso de no ser tú el representante legal, podrás reenviar la solicitud de validación vía correo al iniciar el proceso."
              : "En caso de no ser tú el Aval de la Empresa, podrás reenviar la solicitud de validación vía correo al iniciar el proceso."}
          </Typography>

          <Box mt={4} sx={{ pointerEvents: isLoading ? "none" : "auto", opacity: isLoading ? 0.5 : 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                pointerEvents: !enableButton ? "none" : "auto",
                opacity: !enableButton ? 0.5 : 1,
                cursor: !enableButton ? "not-allowed" : "pointer",
              }}
            >
              <metamap-button
                ref={metamapButtonRef}
                clientid={import.meta.env.VITE_META_CLIENT_ID}
                flowId={import.meta.env.VITE_META_FLOW_ID}
                merchantToken={import.meta.env.VITE_MERCHANT_TOKEN}
                language="es"
                metadata={JSON.stringify(metaDataJson || {})}
                color="#528CD6"
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" mt={4}>
            <Button variant="blue_outlined" type="button" onClick={goBack}>
              Regresar al expediente
            </Button>
          </Box>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ValidacionIdentidadPersona;
