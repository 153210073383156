import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const CustomCircularProgress = (props: CircularProgressProps) => {
  const size = props.size || 40;
  return (
    <Box sx={{ position: 'relative', width: size, height: size }}>
      <CircularProgress
        variant="determinate"
        thickness={7}
        size={size}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          animationDuration: '1.4s',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...theme.applyStyles('dark', {
            color: '#308fe8',
          }),
        })}
        thickness={7}
        size={size}
        {...props}
      />
    </Box>
  );
}

export default CustomCircularProgress;