import { useEffect } from "react";
import { LenditRoute } from "./routes/LenditRoute";

export const LenditApp = () => {

  useEffect(() => {
    const printAppVersion = () => {
      console.log(`[Lendia] App version: ${import.meta.env.APP_VERSION}`);
    };
    printAppVersion();
  }, []);

  return (
    <LenditRoute></LenditRoute>
  )
}
