/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { RootState } from "@store/store";
import { setCurrentOwnerId } from "@store/slices/bofuSlice";
import img from "@assets/img/img_tofu/img_tofu_8.svg";
import { TabStepBofuPm } from "@components/index";
import { usePatchPersonDataMutation, useLazyGetPersonsQuery } from "@api/personasApi";
import { DocumentResponse, PersonaStructure } from "@interfaces/index";
import { useLazyGetDocumentAppIdQuery, usePostDocumentGenarateMutation } from "@api/documents";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { usePostValidateNextStageMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";
import { DOCUMENTS_CATALOG, TAX_SYSTEMS, STATUS_CATALOG, PERSON_CODES_PROPIETARIO_REAL } from "@helpers/constantes";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";

interface Resultado123 {
  data: PersonaStructure;
  identificacion_oficial: DocumentResponse | undefined;
  constancia_situacion_fiscal: DocumentResponse | undefined;
  anexo_2: DocumentResponse | undefined;
  buttonProps: {
    isFilled: boolean;
    buttonLabel: string;
    backgroundColor: string;
    color: string;
  };
}

export const PropietarioRealPM: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const applicationData = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [createDocument] = usePostDocumentGenarateMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [patchPersonData] = usePatchPersonDataMutation();
  const [getPersons] = useLazyGetPersonsQuery();
  const [getDocumentsPerson] = useLazyGetDocumentAppIdQuery();

  const [isLoadingList, setIsLoadingList] = useState(true);
  const [listaPropietarios, setListaPropietarios] = useState<Resultado123[]>([]);
  const [tabKey, setTabKey] = useState(0);

  const formik = useFormik({
    initialValues: {
      propietarios: [],
    },
    validationSchema: yup.object().shape({
      propietarios: yup.array().of(
        yup.object().shape({
          identificacion_oficial: yup.string().required("Campo requerido"),
          constancia_situacion_fiscal: yup.string().required("Campo requerido"),
        }),
      ),
    }),
    onSubmit: async (_values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        // const data = {
        //   status_id: statusValidacionId,
        // };
        // await upDateStatus({ id_sol: applicationData.id, body: data }).unwrap();
        // navigate("/producto/creditosimple/validando_expediente_pm");

        // const { validando_expediente_pm } = STATUS_CATALOG;
        const { code: next_status, url: next_url } = STATUS_CATALOG.validando_expediente_pm;

        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();

        navigate(next_url);
      } catch (error: any) {
        if (error?.status === 500 || error?.status === 409) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleButtonClick = (propietario: PersonaStructure) => {
    dispatch(setCurrentOwnerId(propietario.id));
    setTimeout(() => {
      navigate("/producto/creditosimple/datos_propietario_real_1");
    }, 200);
  };

  const getButtonProps = (form_status: boolean | null, document_status: string | any) => {
    const validado = {
      isFilled: true,
      buttonLabel: "Validado",
      backgroundColor: "#A3D4E8",
      color: "#528CD6",
    };

    const completo = {
      isFilled: true,
      buttonLabel: "Completo",
      backgroundColor: "#002652",
      color: "#A3D4E8",
    };

    const editar = {
      isFilled: false,
      buttonLabel: "Editar",
      backgroundColor: "#F2704F",
      color: "#FFFFFF",
    };

    const llenar = {
      isFilled: false,
      buttonLabel: "Llenar",
      backgroundColor: "#528CD6",
      color: "#FFFFFF",
    };

    if (form_status === true && document_status === true) {
      return validado;
    }

    if (form_status === true) {
      return completo;
    } else if (form_status === false) {
      return editar;
    } else {
      return llenar;
    }
  };

  const generateSolicitudDocument = async (personId: string) => {
    try {
      const data = {
        loan_application_id: applicationData.id,
        document_type: "anexo_2",
        person_id: personId,
      };

      await createDocument(data).unwrap();
      setTabKey((prev) => prev + 1);
    } catch (error: any) {
      console.log("Ha ocurrido un error al generar el anexo 2");
    }
  };

  const updateStatusComplete = async (id: string) => {
    try {
      const data = {
        completed: null,
        id: id,
      };

      await patchPersonData(data).unwrap();
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingList(true);
        const propietarios = await getPersons({
          loanApplicationId: applicationData.id,
          personTypeCode: [...PERSON_CODES_PROPIETARIO_REAL],
        }).unwrap();

        const { identificacion_oficial, constancia_situacion_fiscal, anexo_2 } = DOCUMENTS_CATALOG;

        const resultados_123: Resultado123[] = [];

        for (let index = 0; index < propietarios.length; index++) {
          const propietarioItem = propietarios[index];

          const documents = await getDocumentsPerson({
            loanApplicationId: applicationData.id,
            personId: propietarioItem.id,
            status: true,
          }).unwrap();

          const doc_identificacion_oficial = documents.find((item) => item.file_code === identificacion_oficial.code);
          const doc_constancia_situacion_fiscal = documents.find(
            (item) => item.file_code === constancia_situacion_fiscal.code,
          );
          const doc_anexo_2 = documents.find((item) => item.file_code === anexo_2.code);

          formik.setFieldValue(`propietarios[${index}].identificacion_oficial`, doc_identificacion_oficial?.id);
          formik.setFieldValue(
            `propietarios[${index}].constancia_situacion_fiscal`,
            doc_constancia_situacion_fiscal?.id,
          );

          if (propietarioItem.information_person_completed && doc_anexo_2 === undefined) {
            generateSolicitudDocument(propietarioItem.id);
          }

          if (propietarioItem.completed && !propietarioItem.information_person_completed) {
            updateStatusComplete(propietarioItem.id);
          }

          resultados_123.push({
            data: propietarioItem,
            identificacion_oficial: doc_identificacion_oficial,
            constancia_situacion_fiscal: doc_constancia_situacion_fiscal,
            anexo_2: doc_anexo_2,
            buttonProps: getButtonProps(propietarioItem.information_person_completed, doc_anexo_2?.validated || null),
          });
        }

        setListaPropietarios(resultados_123);
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      } finally {
        setIsLoadingList(false);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
            Expediente digital
          </Typography>

          <TabStepBofuPm key={tabKey} />

          <Typography mt={4} variant="body1" sx={{ fontWeight: "bold", fontSize: "16px" }}>
            Ahora llena los formatos relacionados al o los propietarios reales
          </Typography>

          {isLoadingList ? (
            <Box mt={3}>
              <Skeleton variant="rectangular" height={100} />
            </Box>
          ) : (
            <Box>
              {listaPropietarios.length > 0 ? (
                <form onSubmit={formik.handleSubmit} noValidate>
                  {listaPropietarios.map((propietario, index) => (
                    <Box key={index} mt={3}>
                      <Typography color={"#002652"} fontSize={"15px"} fontWeight={400}>
                        Propietario real {index + 1}:{" "}
                        {[
                          propietario.data.name || "",
                          propietario.data.last_name || "",
                          propietario.data.last_name_2 || "",
                        ].join(" ")}
                      </Typography>

                      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                          Información del propietario real
                        </Typography>

                        <Button
                          variant="expediente"
                          size={isMobileOrTablet ? "small" : "medium"}
                          onClick={() => handleButtonClick(propietario.data)}
                          disabled={propietario.buttonProps.isFilled}
                          sx={{
                            paddingTop: 1,
                            backgroundColor: propietario.buttonProps.backgroundColor,
                            colors: propietario.buttonProps.color,
                            "&:hover": {
                              backgroundColor: propietario.buttonProps.backgroundColor,
                            },
                            "&.Mui-disabled": {
                              backgroundColor: propietario.buttonProps.backgroundColor,
                              color: propietario.buttonProps.color,
                              opacity: 1,
                            },
                          }}
                        >
                          {propietario.buttonProps.buttonLabel}
                        </Button>
                      </Box>

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`identificacion_oficial_${index}`}
                        uploadedFile={propietario.identificacion_oficial}
                        setUploadedFile={(newUpload) => {
                          const copy = [...listaPropietarios];
                          copy[index].identificacion_oficial = newUpload;
                          setListaPropietarios(copy);
                          formik.setFieldValue(`propietarios[${index}].identificacion_oficial`, newUpload?.id || "");
                          setTabKey((prev) => prev + 1);
                        }}
                        optionalDescription="Vigente, puede ser INE, Pasaporte o FM2"
                        metadata={{
                          taxCode: TAX_SYSTEMS.PM.code,
                          personCode: "PTPR",
                          fileCode: DOCUMENTS_CATALOG.identificacion_oficial.code,
                          uploadType: "person",
                          loanApplicationId: applicationData.id,
                          regimen: applicationData.type,
                          personId: propietario.data.id as UUID,
                          clientDetailsId: clientDetails.id,
                          personType: "propietario_real",
                        }}
                        disabled={formik.isSubmitting}
                      />

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`constancia_situacion_fiscal${index}`}
                        uploadedFile={propietario.constancia_situacion_fiscal}
                        setUploadedFile={(newUpload) => {
                          const copy = [...listaPropietarios];
                          copy[index].constancia_situacion_fiscal = newUpload;
                          setListaPropietarios(copy);
                          formik.setFieldValue(
                            `propietarios[${index}].constancia_situacion_fiscal`,
                            newUpload?.id || "",
                          );
                          setTabKey((prev) => prev + 1);
                        }}
                        optionalDescription="Deberá estar actualizada"
                        metadata={{
                          taxCode: TAX_SYSTEMS.PM.code,
                          personCode: "PTPR",
                          fileCode: DOCUMENTS_CATALOG.constancia_situacion_fiscal.code,
                          uploadType: "person",
                          loanApplicationId: applicationData.id,
                          regimen: applicationData.type,
                          personId: propietario.data.id as UUID,
                          clientDetailsId: clientDetails.id,
                          personType: "propietario_real",
                        }}
                        disabled={formik.isSubmitting}
                      />
                    </Box>
                  ))}

                  <Box mt={3}>
                    <Button
                      variant="default"
                      type="submit"
                      sx={{ mt: 4, width: "180px", padding: "12px", mb: 5 }}
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      Continuar
                    </Button>
                  </Box>
                </form>
              ) : (
                <Typography color={"#002652"} fontSize={"20px"} textAlign={"center"} fontWeight={600}>
                  No se encontraron propietarios registrados
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
