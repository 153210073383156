import {
  getPersonByIdResponse,
  PatchResponse,
  PayloadPersonPatch,
  Response,
  PersonaStructure,
  getPersonResponse,
} from "src/types";
import { baseApi } from "./baseApi";

const personasApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPersons: build.query<
      PersonaStructure[],
      { loanApplicationId: string; personTypeId?: string; personTypeCode?: string[] }
    >({
      query: ({ loanApplicationId, personTypeId, personTypeCode }) => {
        let url = `personas?q=loan_application_id:${loanApplicationId}`;
        if (personTypeId) url += `&q=person_type_id:${personTypeId}`;
        if (personTypeCode?.length) {
          personTypeCode.forEach((item) => {
            url += `&q_person_type_code=${item}`;
          });
        }
        return {
          url,
        };
      },
      transformResponse: (response: getPersonResponse) => {
        return response.data.data;
      },
    }),
    getPersonById: build.query<getPersonByIdResponse, string>({
      query: (personId) => `personas/${personId}`,
    }),
    getPersonByTypePersonId: build.query<any, { personId: string; applicationId: string }>({
      query: ({ personId, applicationId }) =>
        `personas?q=loan_application_id:${applicationId}&q=person_type_id:${personId}`,
    }),
    patchPerson: build.mutation<Response, { personId: string; body: PayloadPersonPatch }>({
      query: ({ personId, body }) => ({
        url: `personas/${personId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    // TODO: actualizar servicio en otros archivos por patchPerson
    patchPersonData: build.mutation<PatchResponse, PayloadPersonPatch>({
      query: (data) => ({
        url: `personas/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetPersonsQuery,
  useLazyGetPersonByIdQuery,
  usePatchPersonDataMutation,
  useLazyGetPersonByTypePersonIdQuery,
  usePatchPersonMutation,
} = personasApi;
