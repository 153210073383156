export const customStylesAsterisk = {
  "& .MuiInputLabel-asterisk": {
    color: "#FF6060",
  },
};

export const customSelect = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A3D4E8",
    },
    "&:hover fieldset": {
      borderColor: "#A3D4E8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A3D4E8",
    },
  },
  "& .MuiSelect-icon": {
    color: "#A3D4E8",
  },
};

export const bordeLeft = {
  borderLeft: `1px solid #A3D4E8`,
  borderTop: `1px solid #A3D4E8`,
  borderBottom: `1px solid #A3D4E8`,
  backgroundColor: "white",
  borderRight: `0px solid`,
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#F1F1F1",
  },
  marginBottom: "10px",
};

export const bordeCenter = {
  borderLeft: `0px solid`,
  borderTop: `1px solid #A3D4E8`,
  borderBottom: `1px solid #A3D4E8`,
  backgroundColor: "white",
  borderRight: `0px solid`,
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#F1F1F1",
  },
  marginBottom: "10px",
};

export const borderRight = {
  borderLeft: `0px solid`,
  borderTop: `1px solid #A3D4E8`,
  borderBottom: `1px solid #A3D4E8`,
  backgroundColor: "white",
  borderRight: `1px solid #A3D4E8`,
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#F1F1F1",
  },
  marginBottom: "10px",
};

export const tableHeaderText = {
  color: "#002652",
  fontSize: "16px",
  fontWeight: 700,
};

export const bordeHeaderLeft = {
  borderLeft: `1px solid #002652`,
  borderTop: `1px solid #002652`,
  borderBottom: `1px solid #002652`,
  backgroundColor: "white",
  borderRight: `0px solid`,
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
};

export const bordeHeaderTop = {
  borderLeft: `0px solid `,
  borderTop: `1px solid #002652`,
  borderBottom: `1px solid #002652`,
  backgroundColor: "white",
  borderRight: `0px solid`,
};

export const bordeHeaderRight = {
  borderLeft: `0px solid`,
  borderTop: `1px solid #002652`,
  borderBottom: `1px solid #002652`,
  backgroundColor: "white",
  borderRight: `1px solid #002652`,
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px",
};

export const iconStyle = {
  marginLeft: "8px",
  verticalAlign: "middle",
  width: "18px",
  height: "18px",
};
