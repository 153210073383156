import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import NotFound from "@pages/NotFound";

interface ProtectedTofuProps {
  allowedStatus: string[];
  children: JSX.Element;
}

const ProtectedRouteByStatus = ({ children, allowedStatus }: ProtectedTofuProps) => {
  const loan_status = useSelector((state: RootState) => state.register.loan_status);
  const previus_status = useRef<string>("");

  useEffect(() => {
    setTimeout(() => {
      previus_status.current = loan_status;
    }, 2000);
  }, [loan_status]);

  if (!allowedStatus.includes(loan_status) && !allowedStatus.includes(previus_status.current)) {
    return <NotFound />;
  }

  return children;
};

export default ProtectedRouteByStatus;
