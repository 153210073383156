/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PHONE_NUMBER } from "@utils/expreciones";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { useEffect, useState } from "react";
import { useLazyGetCivilStatusQuery, useLazyGetMatrimonialRegimesQuery } from "@api/catalogs";
import { CivilStatus, MatrimonialRegimesData, PayloadPersonPatch, PersonaStructure } from "@interfaces/index";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetPersonsQuery, usePatchPersonMutation } from "@api/personasApi";
import { useNavigate } from "react-router-dom";
import img from "@assets/img/img_tofu/img_tofu_17.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { PERSON_CODES_AVAL } from "@helpers/constantes";

export const InformacionAval = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const aval = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const [getEstadoCivil] = useLazyGetCivilStatusQuery();
  const [getEstadoMatrimonial] = useLazyGetMatrimonialRegimesQuery();
  const [patchPersonData] = usePatchPersonMutation();
  const [getPersons] = useLazyGetPersonsQuery();

  const [listEdoCivil, setListEdoCivil] = useState<CivilStatus[]>([]);
  const [listMatrimonialRegimen, setListMatrimonialRegimen] = useState<MatrimonialRegimesData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [idEdoCivilCasado, setIdEdoCivilCasado] = useState<string>("");
  const [aval, setAval] = useState<PersonaStructure | null>(null);

  const formik = useFormik({
    initialValues: {
      ocupacion: "",
      country_code: "+52",
      phone: "",
      estado_civil_id: "",
      matrimonial_regimes_id: "",
    },
    validationSchema: yup.object().shape({
      country_code: yup.string().required("Campo requerido"),
      phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
      ocupacion: yup.string().required("Campo requerido"),
      estado_civil_id: yup.string().required("Campo requerido"),
      matrimonial_regimes_id: yup.string().when("estado_civil_id", {
        is: idEdoCivilCasado,
        then: (schema) => schema.required("Campo requerido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!aval) {
          return;
        }

        setSubmitting(true);

        const payload: PayloadPersonPatch = {
          occupation: values.ocupacion,
          code_phone_number: values.country_code,
          phone_number: values.phone,
          civil_status_id: values.estado_civil_id,
          matrimonial_regimes_id: values.estado_civil_id === idEdoCivilCasado ? values.matrimonial_regimes_id : null,
        };

        await patchPersonData({
          personId: aval.id,
          body: payload,
        }).unwrap();

        navigate("/producto/creditosimple/informacion_datos_aval_2");
      } catch (error: any) {
        if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const [res_avales, res_edo_civil, res_edo_matrimonial] = await Promise.all([
          getPersons({
            loanApplicationId: applicationData.id,
            personTypeCode: PERSON_CODES_AVAL,
          }).unwrap(),
          getEstadoCivil().unwrap(),
          getEstadoMatrimonial().unwrap(),
        ]);

        const response_person = res_avales[0];
        if (!response_person) {
          navigate("/producto/creditosimple/docs_aval");
          return;
        }

        const edo_civil_selected: CivilStatus | undefined = res_edo_civil.find(
          (item) => item.id === response_person.civil_status_id,
        );
        const edo_civil_casado: CivilStatus | undefined = res_edo_civil.find(
          (item) => item.name.toLowerCase() === "casado",
        );
        const edo_matrimonial_selected = res_edo_matrimonial.find(
          (item) => item.id === response_person.matrimonial_regimes_id,
        );

        setListEdoCivil(res_edo_civil);
        setListMatrimonialRegimen(res_edo_matrimonial);
        setIdEdoCivilCasado(edo_civil_casado?.id || "NOT_VALID");
        setAval(response_person);

        formik.setFieldValue("country_code", response_person.code_phone_number || "+52");
        formik.setFieldValue("phone", response_person.phone_number || "");
        formik.setFieldValue("ocupacion", response_person.occupation || "");
        formik.setFieldValue("estado_civil_id", edo_civil_selected?.id || "");
        formik.setFieldValue("matrimonial_regimes_id", edo_matrimonial_selected?.id || "");

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } catch (error) {
        console.log("Ha ocurrido un error al consultar la información del aval", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto">
          <Typography mt={4} textAlign="center" variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={700}>
            Información del aval
          </Typography>

          <Typography mt={3} textAlign="center" variant={isMobileOrTablet ? "body2" : "body1"}>
            <strong>1</strong> de 2
          </Typography>

          <Typography
            mt={3}
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={"#528CD6"}
            fontWeight={600}
          >
            {aval?.name} {aval?.last_name} {aval?.last_name_2}
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={4} sm={4} md={4}>
                  <CustomContryCode
                    label="Código"
                    name="country_code"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Grid>

                <Grid item xs={8} sm={8} md={8}>
                  <TextField
                    type="text"
                    name="phone"
                    variant="outlined"
                    label="Teléfono"
                    fullWidth
                    required
                    value={formik.values.phone}
                    onChange={(event) => {
                      formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Grid>
              </Grid>

              <TextField
                id="ocupacion"
                name="ocupacion"
                label="Ocupación"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.ocupacion && Boolean(formik.errors.ocupacion)}
                helperText={formik.touched.ocupacion && formik.errors.ocupacion}
                value={formik.values.ocupacion}
                disabled={isLoading || formik.isSubmitting}
              />

              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  ...customSelect,
                  mt: 2,
                }}
                error={formik.touched.estado_civil_id && Boolean(formik.errors.estado_civil_id)}
              >
                <InputLabel id="estado_civil_id">Estado civil</InputLabel>
                <Select
                  labelId="estado_civil_id"
                  id="estado_civil_id"
                  name="estado_civil_id"
                  fullWidth
                  required
                  label="Estado civil"
                  value={formik.values.estado_civil_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.estado_civil_id && Boolean(formik.errors.estado_civil_id)}
                  disabled={isLoading || formik.isSubmitting}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {listEdoCivil.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.estado_civil_id && formik.errors.estado_civil_id && (
                  <FormHelperText>{formik.errors.estado_civil_id}</FormHelperText>
                )}
              </FormControl>

              {formik.values.estado_civil_id === idEdoCivilCasado && (
                <FormControl
                  fullWidth
                  required
                  sx={{
                    ...customStylesAsterisk,
                    ...customSelect,
                    mt: 2,
                  }}
                  error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
                >
                  <InputLabel id="matrimonial_regimes_id">Régimen matrimonial</InputLabel>
                  <Select
                    labelId="matrimonial_regimes_id"
                    id="matrimonial_regimes_id"
                    name="matrimonial_regimes_id"
                    fullWidth
                    required
                    label="Régimen matrimonial *"
                    value={formik.values.matrimonial_regimes_id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
                    disabled={isLoading}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {listMatrimonialRegimen.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.matrimonial_regimes_id && formik.errors.matrimonial_regimes_id && (
                    <FormHelperText>{formik.errors.matrimonial_regimes_id}</FormHelperText>
                  )}
                </FormControl>
              )}

              <Grid container spacing={1} my={1}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    variant="blue_outlined"
                    type="button"
                    fullWidth
                    onClick={() => {
                      navigate("/producto/creditosimple/docs_aval");
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
