import { baseApi } from "./baseApi";
import { PatchAddressResponse, GetAddressResponse, PayloadAddressPatch, AddressData } from "../types";

const addressApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAddress: build.query<AddressData[], { accountId?: string; personId?: string }>({
      query: ({ accountId, personId }) => {
        let url = `direcciones?limit=100`;
        if (accountId) url += `&q=client_details_id:${accountId}`;
        if (personId) url += `&q=person_id:${personId}`;
        return {
          url,
        };
      },
      transformResponse: (response: GetAddressResponse) => {
        return response.data.data;
      },
    }),
    postAddress: build.mutation<PatchAddressResponse, PayloadAddressPatch>({
      query: (data) => ({
        url: `direcciones`,
        method: "POST",
        body: data,
      }),
    }),
    patchAddress: build.mutation<PatchAddressResponse, { addressId: string; data: PayloadAddressPatch }>({
      query: ({ addressId, data }) => ({
        url: `direcciones/${addressId}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const { usePostAddressMutation, useLazyGetAddressQuery, usePatchAddressMutation } = addressApi;
