import { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { setNewConditionLoan } from "@store/slices/registerSlice";
import { LoanCondiciones } from "@interfaces/condiciones";
import { TaxSystem } from "@interfaces/catalogs";
import { useLazyGetTaxSystemsQuery } from "@api/catalogs";
import { useLazyGetConditionsByIdQuery, usePostConditionMutation } from "@api/applications";
import { QuotationOferta } from "@components/index";

interface NewLoanCondition {
  has_change: boolean;
  amount: number;
  term: number;
  monthlyPayment: number;
}

export const QuotationModal = () => {
  const dispatch = useAppDispatch();

  const modalState = useSelector((state: RootState) => state.app.modal_quotation);
  const loanApplication = useSelector((state: RootState) => state.register.application);
  const [triggerPostCondition] = usePostConditionMutation();
  const [getConditions] = useLazyGetConditionsByIdQuery();
  const [getTaxSystems] = useLazyGetTaxSystemsQuery();

  const [condition, setCondition] = useState<LoanCondiciones | null>(null);
  const [taxSystems, setTaxSystems] = useState<TaxSystem | null>(null);
  const [newConditions, setNewConditions] = useState<NewLoanCondition>({
    has_change: false,
    amount: 0,
    term: 0,
    monthlyPayment: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleOnClickUpdateConditionLoan = async () => {
    try {
      setIsLoading(true);
      await triggerPostCondition({
        loan_application: loanApplication.id,
        loan_amount: newConditions.amount,
        requested_term: newConditions.term,
      }).unwrap();

      dispatch(
        setNewConditionLoan({
          approved_amount: loanApplication.approved_amount,
          approved_term: loanApplication.approved_term,
          interest_rate: loanApplication.interest_rate,
          loanAmount: newConditions.amount,
          loanTerm: newConditions.term,
          amountMonthly: newConditions.monthlyPayment,
        }),
      );
      dispatch(setModalQuotationVisible(false));
    } catch (error) {
      console.log("Ha ocurrido un error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickClose = () => {
    dispatch(setModalQuotationVisible(false));
  };

  const handleOnUpdateValues = (amount: number, term: number, monthlyPayment: number) => {
    if (!condition) return;

    const has_change_amount = condition.loan_amount !== amount;
    const has_change_term = condition.requested_term !== term;

    setNewConditions({
      has_change: has_change_amount || has_change_term,
      amount: amount,
      term: term,
      monthlyPayment: monthlyPayment,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (modalState.visible === false) return;

        setIsLoading(true);
        setHasError(false);
        const [conditions, taxSystems] = await Promise.all([
          getConditions(loanApplication.id).unwrap(),
          getTaxSystems(loanApplication.type).unwrap(),
        ]);

        const activeCondition = conditions.find((item) => item.active);
        if (activeCondition) {
          setCondition(activeCondition);
          setTaxSystems(taxSystems);
        }
      } catch (error) {
        console.log("Ha ocurrido un error: ");
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [modalState.visible]);

  return (
    <Dialog open={modalState.visible} maxWidth={"xs"}>
      <DialogTitle>
        <Typography variant="body1" color={"#002652"} fontWeight={600} textAlign={"center"}>
          Modifica tu cotización
        </Typography>
      </DialogTitle>
      <DialogContent>
        <QuotationOferta condition={condition} taxSystems={taxSystems} onUpdateValues={handleOnUpdateValues} />

        <Grid container columnSpacing={1} my={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="blue_outlined"
              sx={{ padding: "0", height: "100%" }}
              onClick={handleOnClickClose}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="default"
              size="medium"
              onClick={handleOnClickUpdateConditionLoan}
              disabled={isLoading || hasError || !newConditions.has_change}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
