/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import imgError404 from "@assets/img/icon_modal_error.svg";

const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      width={"100%"}
      height={"100%"}
      sx={{
        backgroundColor: "#E3F2F8",
      }}
    >
      <Grid
        item
        md={12}
        sx={{
          mt: isMobileOrTablet ? 4 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography color={"#002652"} variant={isMobileOrTablet ? "h2" : "h1"} textAlign="center" padding={2}>
          ¡Ups!
        </Typography>

        <Typography color={"#002652"} variant={isMobileOrTablet ? "h5" : "h4"} textAlign="center" padding={2}>
          Parece que la página que buscas no existe o no está disponible
        </Typography>

        <Box mt={2} mb={3} sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <img src={imgError404} alt="Error" width={isMobileOrTablet ? 130 : 180} />
        </Box>

        <Typography color={"#002652"} variant={isMobileOrTablet ? "h5" : "h4"} textAlign="center" padding={2}>
          Verifica la dirección que buscas o regresa al inicio
        </Typography>

        <Box sx={{ paddingTop: "30px", textAlign: "center" }}>
          <Button variant="default" sx={{ width: "171px" }} onClick={handleClick}>
            Inicio
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFound;
