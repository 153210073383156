/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img from "@assets/img/img_tofu/img_tofu_10.svg";
import { useLazyGetContractualDocumentLoanApplicationQuery } from "@api/documents";
import { useGetFilesCatalogQuery } from "@api/catalogs";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import ModalFirma from "@components/ModalFirmaMifiel";
import { DocumentContract, DocumentSigner } from "@interfaces/document_contract";
import MifielScript from "@components/MifielScript";

export const FirmaDocumentos: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [isAllSigned, setIsAllSigned] = useState<boolean>(false);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [getDocumentByApplicationClient, { data: dataDocuments }] = useLazyGetContractualDocumentLoanApplicationQuery();
  const { data: fileCategoriesData, isError: isErrorCategories, error: errorCategories } = useGetFilesCatalogQuery();

  const [documents, setDocuments] = useState<DocumentContract[]>([]);
  const isErrorModalVisible = useSelector((state: RootState) => state.app.modal_error.visible);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const navigate = useNavigate();
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<DocumentContract | null>(null);

  const handleContinue = async () => {
    try {
      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSVCF",
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();

      navigate("/producto/creditosimple/bofu_completado");
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    }
  };

  const handleCategoriesError = () => {
    if (isErrorCategories) {
      handleError(errorCategories);
    }
  };

  useEffect(() => {
    handleCategoriesError();
  }, [isErrorCategories]);

  useEffect(() => {
    if (dataDocuments && fileCategoriesData) {
      const documentsWithCategoryNames = dataDocuments.map((document) => {
        const category = fileCategoriesData.find((cat) => cat.id === document.file_category_id);
        const document_item: DocumentContract = {
          id: document.id,
          name: category ? category.name : "Categoría desconocida",
          url: document.url || "",
          documentId: document.id,
          signedClient: document.sign_client,
          mifiel_id: document.mifiel_id,
          mifiel_signers: document.mifiel_signers,
          file_name: document.file_name,
        };
        return document_item;
      });

      const is_all_signed = documentsWithCategoryNames.every((doc) => doc.signedClient);

      setDocuments(documentsWithCategoryNames);
      setIsAllSigned(is_all_signed);
    }
  }, [dataDocuments, fileCategoriesData]);

  useEffect(() => {
    if (application.id && clientDetails.id) {
      try {
        getDocumentByApplicationClient({
          loanApplicationId: application.id,
          clientId: clientDetails.id,
        });
      } catch (error) {
        handleError(error);
      }
    }
  }, [application.id, clientDetails.id]);

  const handleError = (error: any) => {
    if (!isErrorModalVisible) {
      if (error?.status === 500 || error?.status === 400 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const ButtonSign = ({ document }: { document: DocumentContract }) => {
    const sxButton: SxProps = {
      width: isMobileOrTablet ? "90px" : "300px",
      height: isMobileOrTablet ? "23px" : "35px",
      fontSize: isMobileOrTablet ? 13 : 16,
    };

    if (document.signedClient) {
      return (
        <Button variant="blue_demon" sx={sxButton}>
          Firmado
        </Button>
      );
    } else {
      return (
        <Button
          variant="default"
          disabled={!document.mifiel_id}
          sx={sxButton}
          onClick={() => {
            setDocumentoSeleccionado(document);
          }}
        >
          Firmar
        </Button>
      );
    }
  };

  const handleSuccessSign = async (signer: DocumentSigner) => {
    try {
      if (!documentoSeleccionado) {
        return;
      }

      const copy = [...documents];
      copy.forEach((item) => {
        if (item.id === documentoSeleccionado.id) {
          (item.mifiel_signers || []).forEach((signer_item) => {
            if (signer_item.id === signer.id) {
              signer_item.signed = true;
            }
          });
        }
      });

      setDocuments(copy);
    } catch (error: any) {
      handleError(error);
    } finally {
      setDocumentoSeleccionado(null);
    }
  };

  useEffect(() => {
    if (application.id) {
      getDocumentByApplicationClient({ loanApplicationId: application.id, clientId: clientDetails.id });
    }
  }, [application]);

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <MifielScript />
        <NavBarOcularVisit currentStep={5} />
        <Box textAlign={"center"} mx={4}>
          <Typography color="#002652" mt={4} mb={3} variant={isMobileOrTablet ? "h5" : "h4"} fontWeight="bold">
            Tus documentos están listos para firmar
          </Typography>
          <Typography
            variant={isMobileOrTablet ? "h5" : "h4"}
            color="#528CD6"
            sx={{ fontSize: "14px", fontWeight: 300, mb: 2 }}
          >
            En caso de que tengas alguna duda no dudes en contactar a tu asesor
          </Typography>
          {documents.map((doc, index) => (
            <Box>
              <Box
                key={index}
                alignItems="center"
                marginBottom={2}
                pb={2}
                display="flex"
                flexDirection="row"
                justifyContent={"center"}
                gap={10}
              >
                <Box width={isMobileOrTablet ? "100%" : "300px"}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    fontSize={isMobileOrTablet ? "13px" : "16px"}
                    color="#002652"
                    textAlign="left"
                  >
                    {doc.name}
                  </Typography>

                  <Typography
                    fontWeight="normal"
                    variant="body1"
                    fontSize={isMobileOrTablet ? "11px" : "13px"}
                    color="#002652"
                    textAlign="left"
                  >
                    {doc.file_name}
                  </Typography>
                </Box>
                <ButtonSign document={doc} />
              </Box>
            </Box>
          ))}
          <Grid container columnSpacing={1} my={4} justifyContent="center">
            <Grid item xs={6} sm={4} md={3}>
              <Button
                variant="default"
                type="submit"
                disabled={!isAllSigned}
                sx={{ mt: 2, padding: "12px", width: "100%", textTransform: "none" }}
                onClick={handleContinue}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}

      {documentoSeleccionado && (
        <ModalFirma
          document_contract={documentoSeleccionado}
          handleClose={() => setDocumentoSeleccionado(null)}
          handleSuccess={handleSuccessSign}
        />
      )}
    </Grid>
  );
};
