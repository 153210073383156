import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export type buttonStatus = "validado" | "completo" | "editar" | "llenar";

export interface buttonProps {
  disabled: boolean;
  buttonLabel: string;
  backgroundColor: string;
  color: string;
}

export const getFormStatus = (form_status: boolean | null, document_status: string | any): buttonStatus => {
  if (form_status === true && document_status === true) {
    return "validado";
  }

  if (form_status === true) {
    return "completo";
  } else if (form_status === false) {
    return "editar";
  } else {
    return "llenar";
  }
};

export interface CustomButtonExpedienteProps {
  disabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  simulateDisabled?: boolean;
  formStatus?: boolean | null;
  documentStatus?: boolean | null;
}

const CustomButtonExpediente: React.FC<CustomButtonExpedienteProps> = ({
  formStatus,
  documentStatus,
  onClick,
  disabled,
}) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [btnParams, setBtnParams] = useState<buttonProps>({
    disabled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });

  useEffect(() => {
    if (formStatus === true && documentStatus === true) {
      setBtnParams({
        disabled: true,
        buttonLabel: "Validado",
        backgroundColor: "#A3D4E8",
        color: "#528CD6",
      });
    } else {
      if (formStatus === true) {
        setBtnParams({
          disabled: true,
          buttonLabel: "Completo",
          backgroundColor: "#002652",
          color: "#A3D4E8",
        });
      } else if (formStatus === false) {
        setBtnParams({
          disabled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        });
      } else {
        setBtnParams({
          disabled: false,
          buttonLabel: "Llenar",
          backgroundColor: "#528CD6",
          color: "#FFFFFF",
        });
      }
    }
  }, [formStatus, documentStatus]);

  return (
    <Button
      variant="expediente"
      size={isMobileOrTablet ? "small" : "medium"}
      onClick={onClick}
      disabled={btnParams.disabled || disabled}
      sx={{
        backgroundColor: btnParams.backgroundColor,
        color: btnParams.color,
        // opacity: simulateDisabled ? 0.5 : 1,
        "&:hover": {
          backgroundColor: btnParams.backgroundColor,
        },
        "&.Mui-disabled": {
          backgroundColor: btnParams.backgroundColor,
          color: btnParams.color,
          opacity: 1,
        },
      }}
    >
      {btnParams.buttonLabel}
    </Button>
  );
};

export default CustomButtonExpediente;
