export const NAME =
  "^(?=.{2,50}$)[A-ZÑÁÉÍÓÚÄËÏÖÜÇŒ][a-zñáéíóúäëïöüçœ']+(?: [A-ZÑÁÉÍÓÚÄËÏÖÜÇŒ'][a-zñáéíóúäëïöüçœ']+)*$";

export const LAST_NAMES =
  "^(?=.{2,30}$)[A-ZÑÁÉÍÓÚÄËÏÖÜÇŒ'][a-zñáéíóúäëïöüçœ']+(?: [A-ZÁÉÍÓÚÄËÏÖÜÇŒ'][a-zñáéíóúäëïöüçœ']+)*$";

export const EMAIL =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const CURP =
  /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
export const RFC_PFAE =
  /^([A-Z,Ñ,&]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3})$/;
export const RFC_PM =
  /^([A-Z,Ñ,&]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3})$/;
export const PHONE = "\\d{10}";

export const ALFANUMERICO = /^[^$%&|<>?¡¿/*@%+-]*$/;
export const CPOTRO = "\\d{15}";
export const CPMEXICO = "\\d{5}";

export const PASSWORD = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*¡!¿?,.:;+\-/]).{8,20}$/;
export const HAS_SPECIAL_CHARACTERS = /[!@#$%^&*¡!¿?,.:;+\-/]/;
export const HAS_NUMBERS = /[0-9]/;
export const HAS_UPPERCASE = /[A-Z]/;
export const RFC_PFAE_SIN_CLAVE = /^[A-Z&Ñ]{4}[0-9]{6}$/
export const PHONE_NUMBER = /^[0-9]{10}$/;
export const CODIGO_POSTAL = /^\d{5}$/;

export const WEP_PAGE = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;


