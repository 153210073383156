import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { RootState } from "@store/store";
import { setModalEndStepBofuVisible } from "@store/slices/appSlice";

export const ModalEndStepBofu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const app = useSelector((state: RootState) => state.app);
  const digitalFile = useSelector((state: RootState) => state.bofu.digital_file);
  const loanApplication = useSelector((state: RootState) => state.register.application);

  const showText = () => {
    if (loanApplication.type === import.meta.env.VITE_CODE_TAXS_CS_PM) {
      if (digitalFile.current_step == "company" && digitalFile.company.completed) {
        return (
          <Typography variant="body2" color={"#528CD6"} my={1}>
            Concluiste el llenado de formatos y cargaste los documentos relacionados a la empresa, el siguiente paso es
            cargar la información del Aval
          </Typography>
        );
      }

      if (digitalFile.current_step == "guarantee" && digitalFile.guarantee.completed) {
        return (
          <Typography variant="body2" color={"#528CD6"} my={1}>
            Concluiste el llenado de formatos y cargaste los documentos relacionados al aval el último paso es cargar la
            información del propietario real
          </Typography>
        );
      }

      if (digitalFile.current_step == "guarantee" && digitalFile.guarantee.completed) {
        return (
          <Typography variant="body2" color={"#528CD6"} my={1}>
            Concluiste el llenado de formatos y cargaste los documentos relacionados al aval el último paso es cargar la
            información del propietario real
          </Typography>
        );
      }
    } else {
      if (digitalFile.current_step == "company" && digitalFile.company.completed) {
        return (
          <Typography variant="body2" color={"#528CD6"} my={1}>
            Concluiste el llenado de formatos y cargaste los documentos relacionados a tu negocio
          </Typography>
        );
      }
    }
  };

  const handleOnClickNext = () => {
    if (digitalFile.current_step == "company" && digitalFile.company.completed) {
      navigate("/producto/creditosimple/validacion_representante_legal");
    }
    if (digitalFile.current_step == "guarantee" && digitalFile.guarantee.completed) {
      navigate("/producto/creditosimple/propietario_real_pm");
    }

    dispatch(setModalEndStepBofuVisible(false));
  };

  const handleOnClickToStart = () => {
    if (digitalFile.current_step == "company" && digitalFile.company.completed) {
      navigate("/producto/creditosimple/avance_expediente_digital");
    }
    dispatch(setModalEndStepBofuVisible(false));
  };

  return (
    <Dialog open={app.modal_end_step_bofu.visible} maxWidth={"xs"}>
      <DialogTitle>
        <Typography variant="body1" color={"#002652"} fontWeight={600} textAlign={"center"}>
          ¡Perfecto!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box textAlign={"center"}>
          <CheckCircleOutlineIcon sx={{ fontSize: "65px" }} />
          {showText()}

          <Typography variant="body2" fontWeight={600} my={3}>
            Estas más cerca de obtener el crédito que tu empresa necesita
          </Typography>
          <Box display={"flex"} columnGap={2} justifyContent={"center"}>
            <Button variant="blue_outlined" sx={{ py: 0, px: 1 }} onClick={handleOnClickToStart}>
              Regresar al inicio
            </Button>
            <Button variant="default" onClick={handleOnClickNext}>
              Continuar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
