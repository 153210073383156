import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchAuthSession } from "aws-amplify/auth";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_URL_API,
    prepareHeaders: async (headers: Headers, api) => {

      if (api.endpoint === "uploadFile") {
        return headers;
      }

      const session = await fetchAuthSession();
      headers.set("authorization", `Bearer ${session.tokens?.accessToken.toString()}`);
      return headers;
    },
  }),
  keepUnusedDataFor: 30,
  endpoints: () => ({}),
});
