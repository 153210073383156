import { useState, useEffect, useRef } from "react";

function useInterval(callback: () => void, delay: number, startOnInit?: boolean) {
  const myCallback = useRef<() => void>();
  const myInterval = useRef<NodeJS.Timeout | null>(null);
  const [isActive, setIsActive] = useState(startOnInit || false);

  useEffect(() => {
    myCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isActive) {
      myInterval.current = setInterval(() => {
        myCallback.current && myCallback.current();
      }, delay * 1000);
    } else {
      myInterval.current && clearInterval(myInterval.current);
    }

    return () => {
      myInterval.current && clearInterval(myInterval.current);
    };
  }, [delay, isActive]);

  return {
    isActiveInterval: isActive,
    setIsActiveInterval: setIsActive,
  };
}

export default useInterval;
