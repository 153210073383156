/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import img from "@assets/img/img_tofu/img_tofu_1.svg";
import imgPuerta from "@assets/img/img_tofu/img_tofu_21.svg";
import icon from "@assets/img/icon_rectangulo.svg";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { useGetAddressTypeQuery } from "@api/catalogs";
import { usePostAddressMutation, useLazyGetAddressQuery } from "@api/address";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePostDocumentMutation } from "@api/documents";
import { UUID } from "crypto";
import { useGetFilesCatalogQuery } from "@api/catalogs";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";

export const AgendaVisita: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [getAddressByPerson, { data: rawAddressData }] = useLazyGetAddressQuery();
  const { data: addressTypes } = useGetAddressTypeQuery();
  const [createAddress] = usePostAddressMutation();
  const [getByCp] = useLazyGetByCpQuery();
  const [fullAddress, setFullAddress] = useState<string>("");

  const userData = useSelector((state: RootState) => state.register.application.type);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const companySection = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostDocument] = usePostDocumentMutation();
  const { data: fileCategoriesData } = useGetFilesCatalogQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [getDocumentByAppId, { data: dataDocuments }] = useLazyGetDocumentAppIdQuery();

  interface Colonia {
    id: number;
    postal_code: string;
    name: string;
  }

  const capitalizeWords = (str: string) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    getDocumentByAppId({ clientDetailsId: applicationData.client_details_id, loanApplicationId: applicationData.id });
  }, [applicationData]);

  useEffect(() => {
    if (personalData.id) {
      getAddressByPerson({
        accountId: personalData.id,
      });
    }
  }, [getAddressByPerson, personalData.id]);

  useEffect(() => {
    if (rawAddressData && rawAddressData.length > 0 && addressTypes) {
      const confirmedOperationalAddressType = addressTypes.find((type) => type.code === "CDPA");
      const operationalAddressType = addressTypes.find((type) => type.code === "CDOP");

      const confirmedOperationalAddress = rawAddressData.find(
        (address) => address.address_type_id === confirmedOperationalAddressType?.id,
      );
      const operationalAddress = rawAddressData.find(
        (address) => address.address_type_id === operationalAddressType?.id,
      );

      const selectedAddress = confirmedOperationalAddress || operationalAddress;

      if (selectedAddress) {
        const { street, no_ext, no_int, cp, colonia_id } = selectedAddress;

        if (cp) {
          getByCp(cp)
            .unwrap()
            .then((data) => {
              if (data) {
                const coloniaName =
                  data.colonias.find((colonia: Colonia) => colonia.id === colonia_id)?.name || "Desconocida";

                const completeAddress = `${street} No. ${no_ext} ${
                  no_int ? `No Int. ${no_int}` : ""
                }, Col. ${coloniaName}, ${data.cities[0]?.name || ""}, ${cp}, ${data.states[0]?.name || ""}`;

                setFullAddress(capitalizeWords(completeAddress));
              }
            })
            .catch((error) => {
              console.error("Error fetching postal data:", error);
            });
        }
      }
    }
  }, [rawAddressData, addressTypes, getByCp]);

  const handleContinue = async () => {
    const solicitudId = applicationData.id;
    if (solicitudId) {
      const existingFile = companySection?.files.find((file) => file.file_code === "DCD");
      const newFileCategory = fileCategoriesData?.find((category) => category.code === "DCDC");

      if (existingFile && dataDocuments) {
        const documentExists = dataDocuments.some(
          (doc: any) => doc.file_category_id === newFileCategory?.id && doc.loan_application_id === applicationData.id,
        );
        if (!documentExists) {
          const newDocumentData = {
            loan_application_id: applicationData.id,
            file_category_id: newFileCategory?.id as UUID,
            client_details_id: applicationData.client_details_id as UUID,
            url: existingFile.url,
            file_name: existingFile.file_name,
          };

          try {
            await triggerPostDocument(newDocumentData).unwrap();
          } catch (error: any) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          }
        }
      }

      const confirmedOperationalAddressType = addressTypes?.find((type) => type.code === "CDOC");
      const confirmedOperationalAddress = rawAddressData?.find(
        (address) => address.address_type_id === confirmedOperationalAddressType?.id,
      );

      if (!confirmedOperationalAddress) {
        const operationalAddressType = addressTypes?.find((type) => type.code === "CDOP");
        const operationalAddress = rawAddressData?.find(
          (address) => address.address_type_id === operationalAddressType?.id,
        );

        if (operationalAddress) {
          const updatedValues = {
            street: operationalAddress.street,
            no_ext: operationalAddress.no_ext,
            no_int: operationalAddress.no_int,
            colonia_id: operationalAddress.colonia_id,
            state_id: operationalAddress.state_id,
            city_id: operationalAddress.city_id,
            cp: operationalAddress.cp,
            client_details_id: personalData.id,
            country_id: 1,
            address_type_id: confirmedOperationalAddressType?.id || "",
          };

          try {
            await createAddress(updatedValues).unwrap();
          } catch (error: any) {
            if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
              return;
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
              return;
            }
          }
        }
      }

      await triggerPostNextStage({
        applicationId: solicitudId,
        statusCode: "CSVYC",
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();
      navigate("/producto/creditosimple/confirmacion_domicilio");
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography mt={4} mb={3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            Agenda tu visita ocular
          </Typography>

          <NavBarOcularVisit currentStep={1} />
          <Box sx={{ paddingTop: "30px" }} />
          <Typography
            component="span"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={"#528CD6"}
            fontWeight={400}
          >
            Bienvenido a los últimos pasos para obtener tu crédito, a continuación realizaremos una visita ocular y
            cotejo de documentos
          </Typography>

          <Box mt={8} mb={7}>
            <img src={imgPuerta} width={"22%"} height={"30%"} />
          </Box>

          <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
            Tu ejecutivo se pondrá en contacto para agendar la visita a tu domicilio operativo
          </Typography>

          <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#528CD6"} fontWeight={600}>
            {fullAddress}
          </Typography>

          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box sx={{ textAlign: "center", paddingTop: "10px", width: "fit-content", margin: "auto" }}>
                <Button
                  variant="blue_outlined"
                  sx={{
                    padding: "6px 12px",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "1.2",
                    textTransform: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "120px",
                    height: "auto",
                  }}
                  size="medium"
                  onClick={() => {
                    if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
                      navigate("../agenda_visita_pfae");
                    } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
                      navigate("../agenda_visita_pm");
                    }
                  }}
                >
                  Modifica tu domicilio
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              backgroundColor: "#EAF3FF",
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
              marginTop: 3,
            }}
          >
            <Typography fontWeight={600} color={"#002652"} fontSize={"14px"} align="center">
              Recuerda que el día de la visita deberás presentar los siguientes documentos
            </Typography>
            <List>
              {(() => {
                if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
                  return [
                    "Caratula",
                    "Acta constitutiva",
                    "Actas adicionales",
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
                  return [
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                }
                return [];
              })().map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <img src={icon} alt="Document Icon" style={{ width: "15px", height: "15px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    sx={{
                      fontSize: "14px",
                      color: "#002652",
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Grid container columnSpacing={1} my={4} justifyContent="center">
            <Grid item xs={6} sm={4} md={3}>
              <Button variant="contained" onClick={handleContinue} fullWidth sx={{ mt: 2, padding: "12px" }}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
          <img
            src={img}
            alt=""
            style={{
              height: "calc(100vh - 70px)",
              margin: "auto",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
