/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  Link,
  Stack,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useBuroValidatorMutation, BuroValidatorRequest } from "@api/sat_apis_new/satApsi";
import img from "@assets/img/img_buro_right.svg";
import { ProgressBar, CustomSwitch, CustomDialog } from "@components/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { obtenerFechaHoy } from "@helpers/index";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";

const ConsultaBuro = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const shareholderData = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const addressData = useSelector((state: RootState) => state.register.address_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [buroValidator] = useBuroValidatorMutation();

  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [closeModalError, setCloseModalError] = useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      prestamo_hipotecario: false,
      credito_automotriz: false,
      tarjeta_credito: false,
      tarjeta: "",
      authorize: false,
    },
    validationSchema: yup.object().shape({
      credito_automotriz: yup.boolean(),
      prestamo_hipotecario: yup.boolean(),
      tarjeta_credito: yup.boolean(),
      authorize: yup.boolean().isTrue("Se requiere aceptar"),
      tarjeta: yup.string().when("tarjeta_credito", {
        is: true,
        then: (schema) => schema.required("Campo requerido").min(4, "Formato de tarjeta no válido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const payload_buro: BuroValidatorRequest = {
          person_Type: "pf",
          data: {
            rfc: shareholderData.rfc + shareholderData.rfc_homoclave,
            curp: shareholderData.curp,
            transaction_number: String(applicationData.id),
            application_code: applicationData.company_application_code,
            legal_name: shareholderData.name,
            first_name: shareholderData.name,
            second_name: "",
            father_last_name: shareholderData.first_lastname,
            mother_last_name: shareholderData.second_lastname === "" ? "" : shareholderData.second_lastname,
            reenter_NIP: true,
            authorization_label_response: true,
            loan_application_id: String(applicationData.id),
            person_id: String(shareholderData?.id),
            is_endorsement: true,
            pf: {
              mortgage_credit: values.prestamo_hipotecario,
              automotive_credit: values.credito_automotriz,
              credit_card: values.tarjeta_credito,
              last_four_digits: values.tarjeta,
            },
            address: {
              street: addressData.street,
              exteriorNumber: addressData.no_ext,
              interiorNumber: addressData.no_int,
              state: addressData.state_name,
              municipality: addressData.city_name,
              locality: addressData.city_name,
              postalCode: addressData.postal_code,
            },
          },
        };

        await buroValidator(payload_buro).unwrap();

        const next_status = STATUS_CATALOG.waiting_room.code;
        const next_url = STATUS_CATALOG.waiting_room.url;

        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `TOFU - ${accountUser.email}`,
        }).unwrap();

        dispatch(setLoanStatus(next_status));

        navigate(next_url);
      } catch (error: any) {
        if (error?.status === 500 || error?.status === 409) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
        } else {
          setCloseModalError(false);
        }
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  const handleOnCloseModalError = () => {
    setCloseModalError(true);
  };

  const getShareholderFullName = () => {
    return [shareholderData.name, shareholderData.first_lastname, shareholderData.second_lastname].join(" ").trim();
  };

  useEffect(() => {
    if (formik.values.authorize) {
      setCloseModal(false);
    } else {
      setCloseModal(true);
    }
  }, [formik.values.authorize]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
              Consulta Buró
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={10} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                Haremos una consulta al Buró de Crédito del accionista mayoritario
              </Typography>
            </Box>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container rowSpacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="one" name="prestamo_hipotecario" onChange={formik.handleChange} />}
                    label="¿Tiene un préstamo hipotecario?"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="two" name="credito_automotriz" onChange={formik.handleChange} />}
                    label="¿Ha tenido un crédito automotriz?"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox id="three" name="tarjeta_credito" onChange={formik.handleChange} />}
                    label="¿Es titular de una tarjeta de crédito?"
                  />
                </Grid>
                {formik.values.tarjeta_credito && (
                  <Grid item xs={12}>
                    <TextField
                      id="tarjeta"
                      name="tarjeta"
                      label="Últimos 4 dígitos tarjeta de crédito"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      inputProps={{ maxLength: 4, inputMode: "numeric" }}
                      disabled={!formik.values.tarjeta_credito}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.match(/[^0-9]/)) {
                          event.preventDefault();
                          return;
                        }
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.tarjeta}
                      error={formik.touched.tarjeta && Boolean(formik.errors.tarjeta)}
                      helperText={formik.touched.tarjeta && formik.errors.tarjeta}
                    />
                  </Grid>
                )}
                <Grid item xs={12} py={2} mx={1}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomSwitch id="authorize" name="authorize" onChange={formik.handleChange} />
                    <Typography variant={isMobileOrTablet ? "body2" : "body1"}>
                      Autorizo a Lendit Group a consultar mi información ante Buró de Crédito.
                      {" Consulta "}
                      <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                        términos y condiciones
                      </Link>{" "}
                      y{" "}
                      <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                        aviso de privacidad
                      </Link>{" "}
                    </Typography>
                  </Stack>
                  {Boolean(formik.touched.authorize) && Boolean(formik.errors.authorize) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.authorize}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomDialog
        colorClose="#A3D4E8"
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600}>
            Consulta a Buró de Crédito
          </Typography>
        }
        itemContent={
          <Typography textAlign={"justify"} variant="body2" color={"#002652"}>
            {obtenerFechaHoy()}, yo {getShareholderFullName()}, en representación de la Persona Moral suscrita, autorizo
            a Lendit Group SAPI de CV y/o cualquier de sus afiliadas, subsidiarias o personas relacionadas, para
            consultar mi comportamiento crediticio en cualquier sociedad de información crediticia. Conozco la
            información que se solicita, el uso que se le dará y autorizo posibles consultas periódicas con una vigencia
            de 2 (dos) años o más, mientras se mantenga la relación jurídica.
          </Typography>
        }
      />

      <CustomDialog
        colorClose="#F2704F"
        open={!closeModalError}
        onClose={handleOnCloseModalError}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600} color={"#F2704F"}>
            Datos Incorrectos
          </Typography>
        }
        itemContent={
          <Typography textAlign={"center"} variant="body2" color={"#002652"}>
            <span>
              Los datos ingresados no coinciden con la <br /> información registrada en Buró de Crédito. <br /> Por
              favor, revisa la información <br /> proporcionada y vuelve a intentarlo.
            </span>
          </Typography>
        }
      />
    </>
  );
};

export default ConsultaBuro;
