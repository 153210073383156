/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { useGetBusinessActivitiesQuery } from "@api/catalogs";
import { setCompanyLegalAgentData } from "@store/slices/bofuSlice";
import { useLazyUpdateLegalRepresentativeDataQuery } from "@api/account";
import { usePatchAddressMutation, usePostAddressMutation } from "@api/address";
import CustomCountryCode from "@components/CustomContryCode/CustomContryCode";

import img from "@assets/img/img_tofu/img_tofu_3.svg";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

const validationSchema = yup.object({
  telefono: yup
    .string()
    .required("Requerido")
    .matches(/^[0-9]{10}$/, "Ingresa 10 dígitos"),
  email: yup.string().email("Asegúrate de incluir el @ y los puntos necesarios (.com .mx)").required("Requerido"),
  giro: yup.string().required("Requerido"),
  descripcion: yup.string().required("Requerido"),
  codigoPostal: yup
    .string()
    .required("Requerido")
    .matches(/^[0-9]{5}$/, "Debe ser un código postal válido"),
  calle: yup.string().required("Requerido"),
  noInterior: yup.string().notRequired(),
  noExterior: yup.string().required(),
  colonia: yup.string().required("Requerido"),
  ciudad: yup.string().required("Requerido"),
  municipio: yup.string().required("Requerido"),
  entidadFederativa: yup.string().required("Requerido"),
});

export const SolicitudCreditoPart1PFAE: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [getByCp] = useLazyGetByCpQuery();
  const [postAddress] = usePostAddressMutation();
  const [patchAddress] = usePatchAddressMutation();
  const businessActivities = useGetBusinessActivitiesQuery();
  const [updateLegalRepresentativeData] = useLazyUpdateLegalRepresentativeDataQuery(); // TODO: cambiar por personasApi.patchPerson

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const legalAgent = useSelector((state: RootState) => state.bofu.digital_file.company.loan_application.legal_agent);

  const [countryCode, setCountryCode] = useState("+52");
  const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState({
    ciudadId: 0,
    municipioId: 0,
    estadoId: 0,
    ciudad: "",
    municipio: "",
    estado: "",
  });
  const [isPreFilled, setIsPreFilled] = useState(false);

  const formik = useFormik({
    initialValues: {
      telefono: legalAgent.phone_number,
      email: legalAgent.email || "",
      giro: legalAgent.company.activity_id || "00000000-0000-0000-0000-000000000000",
      descripcion: legalAgent.company.description || "",
      codigoPostal: legalAgent.operative_address.postal_code || "",
      calle: legalAgent.operative_address.street || "",
      noInterior: legalAgent.operative_address.no_int || "",
      noExterior: legalAgent.operative_address.no_ext || "",
      colonia: "",
      ciudad: "",
      municipio: "",
      entidadFederativa: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const coloniaSelected = colonias.find((colonia) => colonia.name == values.colonia);

      try {
        setIsLoading(true);
        await updateLegalRepresentativeData({
          id: personalData?.id,

          emailLegalRepresentative: values.email,
          countryCodeLegalRepresentative: countryCode,
          phoneLegalRepresentative: values.telefono,
          businessActivityId: values.giro,
          businessDescription: values.descripcion,
        });

        const addressData = {
          street: values.calle,
          no_ext: values.noExterior,
          no_int: values.noInterior,
          colonia_id: coloniaSelected?.id,
          state_id: locationData.estadoId,
          city_id: locationData.ciudadId,
          country_id: 1,
          cp: values.codigoPostal,
          client_details_id: personalData?.id,
          address_type_id: "fc1ac074-d08e-4894-8647-eb1a1dd73450",
        };

        let response = undefined;

        if (legalAgent.operative_address.id !== undefined) {
          response = await patchAddress({ addressId: legalAgent.operative_address.id, data: addressData });
        } else {
          response = await postAddress(addressData);
        }

        dispatch(
          setCompanyLegalAgentData({
            code_phone: countryCode,
            phone_number: values.telefono,
            email: values.email,
            company: {
              activity_id: values.giro,
              description: values.descripcion,
            },
            operative_address: {
              id: response.data?.data.id,
              postal_code: values.codigoPostal,
              street: values.calle,
              no_ext: values.noExterior,
              no_int: values.noInterior,
              colonia_id: coloniaSelected?.id,
              state_id: locationData.estadoId,
              city_id: locationData.ciudadId,
              country_id: 0,
            },
          }),
        );

        navigate("/producto/creditosimple/solicitud_credito_2PFAE");
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!formik.values.colonia) {
      formik.setFieldValue("colonia", "00000000-0000-0000-0000-000000000000");
    }
    if (formik.values.codigoPostal.length === 5) {
      getByCp(formik.values.codigoPostal)
        .unwrap()
        .then((value) => {
          const { colonias, cities, states } = value;

          setColonias(colonias);
          setLocationData({
            ciudadId: cities[0]?.id || "",
            municipioId: cities[0]?.id || "",
            estadoId: states[0]?.id || "",
            ciudad: cities[0]?.name || "",
            municipio: cities[0]?.name || "",
            estado: states[0]?.name || "",
          });

          formik.setFieldValue("colonia", colonias[0].name);
          formik.setFieldValue("ciudad", cities[0].name);
          formik.setFieldValue("municipio", cities[0].name);
          formik.setFieldValue("entidadFederativa", states[0].name);

          setIsPreFilled(true);
        });
    } else {
      formik.setFieldValue("colonia", "00000000-0000-0000-0000-000000000000");
    }
  }, [formik.values.codigoPostal]);

  useEffect(() => {
    if (!formik.values.giro) {
      formik.setFieldValue("giro", "00000000-0000-0000-0000-000000000000");
    }
    if (businessActivities.isSuccess && businessActivities.currentData) {
      if (legalAgent.company.activity_id) {
        const businessActivity = businessActivities.data.find((item) => item.id == legalAgent.company.activity_id);

        formik.setFieldValue("giro", businessActivity ? businessActivity.id : "", true);
      }
    } else {
      formik.setFieldValue("giro", "00000000-0000-0000-0000-000000000000");
    }
  }, [businessActivities]);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} maxWidth="sm" mx="auto">
          <Typography mt={4} mb={3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            Solicitud de crédito
          </Typography>
          <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={800}>
            1{" "}
            <Typography component="span" variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400}>
              de 3
            </Typography>
          </Typography>
          <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
            Datos del dueño del negocio
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} sm={4} md={4}>
                <CustomCountryCode
                  label="Código"
                  name="countryCode"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <TextField
                  type="text"
                  id="telefono"
                  name="telefono"
                  label="Teléfono (10 dígitos)"
                  inputProps={{ maxLength: 10, inputMode: "numeric" }}
                  fullWidth
                  required
                  value={formik.values.telefono}
                  onChange={(event) => {
                    formik.setFieldValue("telefono", event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              id="email"
              name="email"
              label="Correo electrónico"
              required
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
              margin="normal"
            />

            <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
              Datos del negocio
            </Typography>
            <Box mt={4}>
              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  ...customSelect,
                  mt: 1,
                }}
                error={formik.touched.colonia && Boolean(formik.errors.colonia)}
              >
                <InputLabel id={"giro"}>Giro</InputLabel>
                <Select
                  labelId="giro"
                  name="giro"
                  id="giro"
                  fullWidth
                  required
                  label="Giro"
                  value={formik.values.giro}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={businessActivities.isLoading}
                  error={formik.touched.giro && Boolean(formik.errors.giro)}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value="">Selecciona</MenuItem>
                  {businessActivities.isLoading ? (
                    <MenuItem value="">Cargando...</MenuItem>
                  ) : (
                    businessActivities.currentData?.map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.description}
                      </MenuItem>
                    ))
                  )}
                </Select>

                {formik.touched.giro && formik.errors.giro && <FormHelperText>{formik.errors.giro}</FormHelperText>}
              </FormControl>
              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Describe brevemente a qué se dedica tu empresa"
                required
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                helperText={formik.touched.descripcion ? formik.errors.descripcion : ""}
                margin="normal"
                multiline
                rows={3}
              />
            </Box>
            <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
              Domicilio operativo
            </Typography>
            <Box mt={4}>
              <TextField
                fullWidth
                id="codigoPostal"
                name="codigoPostal"
                label="Código Postal"
                required
                value={formik.values.codigoPostal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                helperText={formik.touched.codigoPostal ? formik.errors.codigoPostal : ""}
                margin="normal"
              />
              <TextField
                fullWidth
                id="calle"
                name="calle"
                label="Calle o avenida"
                required
                value={formik.values.calle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.calle && Boolean(formik.errors.calle)}
                helperText={formik.touched.calle ? formik.errors.calle : ""}
                margin="normal"
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="noExterior"
                    name="noExterior"
                    label="No. Exterior"
                    required
                    value={formik.values.noExterior}
                    onChange={formik.handleChange}
                    error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                    helperText={formik.touched.noExterior ? formik.errors.noExterior : ""}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="noInterior"
                    name="noInterior"
                    label="No. Interior"
                    value={formik.values.noInterior}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.noInterior && Boolean(formik.errors.noInterior)}
                    helperText={formik.touched.noInterior ? formik.errors.noInterior : ""}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <InputLabel id="colonia-label">Colonia</InputLabel>
                <Select
                  labelId="colonia-label"
                  name="colonia"
                  id="colonia"
                  fullWidth
                  required
                  label="Colonia"
                  value={colonias ? formik.values.colonia : ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.colonia && formik.errors.colonia)}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value="">
                    Colonia
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </MenuItem>
                  {colonias.map((colonia) => (
                    <MenuItem key={colonia.id} value={colonia.name}>
                      {colonia.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                id="ciudad"
                name="ciudad"
                label="Ciudad"
                required
                value={formik.values.ciudad}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
                helperText={formik.touched.ciudad ? formik.errors.ciudad : ""}
                margin="normal"
                disabled={isPreFilled}
              />
              <TextField
                fullWidth
                id="municipio"
                name="municipio"
                label="Municipio"
                value={formik.values.municipio}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.municipio && Boolean(formik.errors.municipio)}
                helperText={formik.touched.municipio ? formik.errors.municipio : ""}
                margin="normal"
                disabled={isPreFilled}
              />
              <TextField
                fullWidth
                id="entidadFederativa"
                name="entidadFederativa"
                label="Entidad federativa"
                value={formik.values.entidadFederativa}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.entidadFederativa && Boolean(formik.errors.entidadFederativa)}
                helperText={formik.touched.entidadFederativa ? formik.errors.entidadFederativa : ""}
                margin="normal"
                disabled={isPreFilled}
              />
            </Box>
            <Grid container columnSpacing={1} my={4}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  fullWidth
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  onClick={() => navigate("../avance_expediente_digitalPFAE")}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="default"
                  fullWidth
                  color="primary"
                  disabled={!formik.isValid || !formik.dirty || isLoading}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
