/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { useLazyGetAddressTypeQuery, useGetBusinessActivitiesQuery } from "@api/catalogs";
// import { setCompanyLegalAgentData } from "@store/slices/bofuSlice";
import { useLazyGetAddressQuery, usePatchAddressMutation, usePostAddressMutation } from "@api/address";
import CustomCountryCode from "@components/CustomContryCode/CustomContryCode";
import img from "@assets/img/img_tofu/img_tofu_3.svg";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { Address, AddressData, PayloadAccountPatch, PayloadPersonPatch, PersonaStructure } from "@interfaces/index";
import { InactivityHandler } from "@components/InactivityHandler";
import { PERSON_CODES_LEGAL_REPRESENTATIVE } from "@helpers/constantes";
import { useLazyGetPersonsQuery, usePatchPersonMutation } from "@api/personasApi";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { useLazyGetCuentasByIdQuery, usePatchCuentaMutation } from "@api/account";
import { UUID } from "crypto";

export const SolicitudCreditoPart1: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [getByCp] = useLazyGetByCpQuery();
  const [postAddress] = usePostAddressMutation();
  const [patchAddress] = usePatchAddressMutation();
  const businessActivities = useGetBusinessActivitiesQuery();

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const legalAgent = useSelector((state: RootState) => state.bofu.digital_file.company.loan_application.legal_agent);

  const [addresType, setAddresType] = useState<Address | undefined>(undefined);
  const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
  const [legalRepresentative, setLegalRepresentative] = useState<PersonaStructure | undefined>(undefined);
  const [address, setAddress] = useState<AddressData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getPersons] = useLazyGetPersonsQuery();
  const [patchPerson] = usePatchPersonMutation();
  const [getAddress] = useLazyGetAddressQuery();
  const [patchAccount] = usePatchCuentaMutation();
  const [getAddresType] = useLazyGetAddressTypeQuery();
  const [getApplicationById] = useLazyGetApplicationByApplicationIdQuery();
  const [getCuentaById] = useLazyGetCuentasByIdQuery();

  const handleOnClickRestart = () => {
    navigate("/producto/creditosimple/avance_expediente_digital");
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      countryCode: "+52",
      telefono: "",
      email: "",
      giro: "",
      descripcion: "",
      codigoPostal: legalAgent.operative_address.postal_code || "",
      calle: legalAgent.operative_address.street || "",
      noInterior: legalAgent.operative_address.no_int || "",
      noExterior: legalAgent.operative_address.no_ext || "",
      estadoId: "",
      ciudadId: "",
      municipioId: "",
      coloniaId: "",
      estado: "",
      ciudad: "",
      municipio: "",
    },
    validationSchema: yup.object({
      nombre: yup.string(),
      apellidoPaterno: yup.string(),
      apellidoMaterno: yup.string(),
      telefono: yup
        .string()
        .required("Requerido")
        .matches(/^[0-9]{10}$/, "Ingresa 10 dígitos"),
      email: yup.string().email("Asegúrate de incluir el @ y los puntos necesarios (.com .mx)").required("Requerido"),
      giro: yup.string().required("Requerido"),
      descripcion: yup.string().required("Requerido"),
      codigoPostal: yup
        .string()
        .required("Requerido")
        .matches(/^[0-9]{5}$/, "Debe ser un código postal válido"),
      calle: yup.string().required("Requerido"),
      noInterior: yup.string().notRequired(),
      noExterior: yup.string().required(),
      estadoId: yup.string().required("Requerido"),
      ciudadId: yup.string().required("Requerido"),
      municipioId: yup.string().required("Requerido"),
      coloniaId: yup.string().required("Requerido"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!legalRepresentative) {
          console.log("No se encontró el representante legal");
          return;
        }

        const payload_person: PayloadPersonPatch = {
          code_phone_number: values.countryCode,
          phone_number: values.telefono,
          email: values.email,
        };

        await patchPerson({
          personId: legalRepresentative.id,
          body: payload_person,
        }).unwrap();

        const payload_account: PayloadAccountPatch = {
          business_activity_id: values.giro,
          business_description: values.descripcion,
        };

        await patchAccount({
          accountId: application.client_details_id,
          body: payload_account,
        }).unwrap();

        const payload_address = {
          client_details_id: clientDetails.id,
          address_type_id: addresType?.id,
          cp: values.codigoPostal,
          street: values.calle,
          no_ext: values.noExterior,
          no_int: values.noInterior,
          country_id: 1,
          state_id: Number(values.estadoId),
          city_id: Number(values.ciudadId),
          colonia_id: Number(values.coloniaId),
        };

        if (address?.id) {
          await patchAddress({
            addressId: address.id as UUID,
            data: payload_address,
          }).unwrap();
        } else {
          await postAddress(payload_address).unwrap();
        }

        // dispatch(
        //   setCompanyLegalAgentData({
        //     code_phone: countryCode,
        //     phone_number: values.telefono,
        //     email: values.email,
        //     company: {
        //       activity_id: values.giro,
        //       description: values.descripcion,
        //     },
        //     operative_address: {
        //       id: response.data?.data.id,
        //       postal_code: values.codigoPostal,
        //       street: values.calle,
        //       no_ext: values.noExterior,
        //       no_int: values.noInterior,
        //       colonia_id: coloniaSelected?.id,
        //       state_id: locationData.estadoId,
        //       city_id: locationData.ciudadId,
        //       country_id: 0,
        //     },
        //   }),
        // );

        navigate("/producto/creditosimple/solicitud_credito_2");
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchLocationData = async (codigoPostal: string, coloniaId?: number) => {
    try {
      console.log("formik.values.codigoPostal", codigoPostal);

      if (codigoPostal.length === 5) {
        const value = await getByCp(codigoPostal).unwrap();
        const { colonias, cities, states } = value;
        setColonias(colonias);

        formik.setFieldValue("estadoId", states[0]?.id || "");
        formik.setFieldValue("estado", states[0]?.name || "");
        formik.setFieldValue("ciudadId", cities[0]?.id || "");
        formik.setFieldValue("ciudad", cities[0]?.name || "");
        formik.setFieldValue("municipioId", cities[0]?.id || "");
        formik.setFieldValue("municipio", cities[0]?.name || "");

        if (coloniaId) {
          const coloniaSelected = colonias.find((item: any) => item.id == coloniaId);
          formik.setFieldValue("coloniaId", coloniaSelected?.id || "");
        } else {
          formik.setFieldValue("coloniaId", colonias[0]?.id || "");
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } else {
        formik.setFieldValue("estadoId", "");
        formik.setFieldValue("estado", "");
        formik.setFieldValue("ciudadId", "");
        formik.setFieldValue("ciudad", "");
        formik.setFieldValue("municipioId", "");
        formik.setFieldValue("municipio", "");
        formik.setFieldValue("coloniaId", "");
        formik.setFieldValue("colonia", "");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [res_loan_application, persons, res_address_type, res_account, res_address] = await Promise.all([
          getApplicationById(application.id).unwrap(),
          getPersons({
            loanApplicationId: application.id,
            personTypeCode: [...PERSON_CODES_LEGAL_REPRESENTATIVE],
          }).unwrap(),
          getAddresType().unwrap(),
          getCuentaById({ id: application.client_details_id }).unwrap(),
          getAddress({
            accountId: application.client_details_id,
          }).unwrap(),
        ]);

        const loan_application = res_loan_application.data[0];
        const legal_representative = persons[0];
        const address_type = res_address_type.find((item: Address) => item.code === "CDOP");

        const address = res_address.find((item: AddressData) => item.address_type_id === address_type?.id);
        const account = res_account.data;

        console.log("parametros: ", {
          loan_application,
          legal_representative,
          address_type,
          address,
          account,
        });

        if (!loan_application || !legal_representative) {
          console.log("No se encontró el representante legal");
          navigate("/producto/creditosimple/avance_expediente_digital");
          return;
        }

        setAddresType(address_type);
        setAddress(address);
        setLegalRepresentative(legal_representative);

        formik.setFieldValue("nombre", legal_representative.name);
        formik.setFieldValue("apellidoPaterno", legal_representative.last_name);
        formik.setFieldValue("apellidoMaterno", legal_representative.last_name_2);
        formik.setFieldValue("phoneCode", legal_representative.code_phone_number || "");
        formik.setFieldValue("telefono", legal_representative.phone_number || "");
        formik.setFieldValue("email", legal_representative.email || "");
        formik.setFieldValue("giro", account.business_activity_id || "");
        formik.setFieldValue("descripcion", account.business_description || "");

        if (address) {
          formik.setFieldValue("codigoPostal", address.cp || "");
          formik.setFieldValue("calle", address.street || "");
          formik.setFieldValue("noInterior", address.no_int || "");
          formik.setFieldValue("noExterior", address.no_ext || "");

          fetchLocationData(address.cp, address.colonia_id);
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box textAlign={"center"} maxWidth="sm" mx="auto">
          <Typography mt={4} mb={3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            Solicitud de crédito
          </Typography>
          <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={800}>
            1{" "}
            <Typography component="span" variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400}>
              de 3
            </Typography>
          </Typography>
          <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
            Datos del representante legal
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="nombre"
                name="nombre"
                label="Nombre(s)"
                required
                value={formik.values.nombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre ? formik.errors.nombre : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="apellidoPaterno"
                name="apellidoPaterno"
                label="Apellido Paterno"
                required
                value={formik.values.apellidoPaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)}
                helperText={formik.touched.apellidoPaterno ? formik.errors.apellidoPaterno : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="apellidoMaterno"
                name="apellidoMaterno"
                label="Apellido Materno"
                required
                value={formik.values.apellidoMaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)}
                helperText={formik.touched.apellidoMaterno ? formik.errors.apellidoMaterno : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4} md={4}>
                  <CustomCountryCode
                    label="Código"
                    name="countryCode"
                    value={formik.values.countryCode}
                    onChange={(e) => formik.setFieldValue("countryCode", e.target.value)}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <TextField
                    type="text"
                    id="telefono"
                    name="telefono"
                    label="Teléfono (10 dígitos)"
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                    }}
                    fullWidth
                    required
                    value={formik.values.telefono}
                    onChange={(event) => {
                      formik.setFieldValue("telefono", event.target.value.replace(/[^0-9]/g, ""));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Correo electrónico"
                required
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
                margin="normal"
                disabled={isLoading || formik.isSubmitting}
              />
            </FormControl>

            <Box mt={4} mb={4}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
                Datos de la empresa:
              </Typography>
            </Box>

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                ...customSelect,
                mt: 1,
              }}
              error={formik.touched.giro && Boolean(formik.errors.giro)}
            >
              <InputLabel id={"giro"}>Giro</InputLabel>
              <Select
                labelId="giro"
                name="giro"
                id="giro"
                fullWidth
                required
                label="Giro"
                value={formik.values.giro}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.giro && Boolean(formik.errors.giro)}
                sx={{ textAlign: "left" }}
                disabled={businessActivities.isLoading || isLoading || formik.isSubmitting}
              >
                <MenuItem value="">Selecciona</MenuItem>
                {businessActivities.isLoading ? (
                  <MenuItem value="">Cargando...</MenuItem>
                ) : (
                  businessActivities.currentData
                    ?.slice()
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.description}
                      </MenuItem>
                    ))
                )}
              </Select>
              {formik.touched.giro && formik.errors.giro && <FormHelperText>{formik.errors.giro}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Describe brevemente a qué se dedica tu empresa"
                required
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                helperText={formik.touched.descripcion ? formik.errors.descripcion : ""}
                margin="normal"
                multiline
                rows={3}
                disabled={isLoading || formik.isSubmitting}
              />
            </FormControl>

            <Box mt={4} mb={4}>
              <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
                Domicilio operativo:
              </Typography>
            </Box>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="codigoPostal"
                name="codigoPostal"
                label="Código Postal"
                required
                value={formik.values.codigoPostal}
                onChange={(e) => {
                  formik.handleChange(e);
                  fetchLocationData(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                helperText={formik.touched.codigoPostal ? formik.errors.codigoPostal : ""}
                margin="normal"
                disabled={isLoading || formik.isSubmitting}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="calle"
                name="calle"
                label="Calle o avenida"
                required
                value={formik.values.calle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.calle && Boolean(formik.errors.calle)}
                helperText={formik.touched.calle ? formik.errors.calle : ""}
                margin="normal"
                disabled={isLoading || formik.isSubmitting}
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="noExterior"
                    name="noExterior"
                    label="No. Exterior"
                    required
                    value={formik.values.noExterior}
                    onChange={formik.handleChange}
                    error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                    helperText={formik.touched.noExterior ? formik.errors.noExterior : ""}
                    margin="normal"
                    disabled={isLoading || formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="noInterior"
                    name="noInterior"
                    label="No. Interior"
                    value={formik.values.noInterior}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.noInterior && Boolean(formik.errors.noInterior)}
                    helperText={formik.touched.noInterior ? formik.errors.noInterior : ""}
                    margin="normal"
                    disabled={isLoading || formik.isSubmitting}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                ...customSelect,
                mt: 1,
              }}
              error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
            >
              <InputLabel id={"coloniaId"}>Colonia</InputLabel>
              <Select
                labelId="coloniaId"
                name="coloniaId"
                id="coloniaId"
                fullWidth
                required
                label="Colonia"
                value={formik.values.coloniaId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
                sx={{ textAlign: "left" }}
                disabled={isLoading || formik.isSubmitting}
              >
                <MenuItem value="">Selecciona</MenuItem>
                {colonias.map((colonia) => (
                  <MenuItem key={colonia.id} value={colonia.id}>
                    {colonia.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.giro && formik.errors.giro && <FormHelperText>{formik.errors.giro}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="ciudad"
                name="ciudad"
                label="Ciudad"
                required
                value={formik.values.ciudad}
                error={formik.touched.ciudadId && Boolean(formik.errors.ciudadId)}
                helperText={formik.touched.ciudadId ? formik.errors.ciudadId : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="municipio"
                name="municipio"
                label="Municipio"
                value={formik.values.municipio}
                error={formik.touched.municipioId && Boolean(formik.errors.municipioId)}
                helperText={formik.touched.municipioId ? formik.errors.municipioId : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="estado"
                name="estado"
                label="Entidad federativa"
                value={formik.values.estado}
                error={formik.touched.estadoId && Boolean(formik.errors.estadoId)}
                helperText={formik.touched.estadoId ? formik.errors.estadoId : ""}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <Grid container columnSpacing={1} my={4}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  onClick={handleOnClickRestart}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="default"
                  fullWidth
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
