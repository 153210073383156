import { PayloadRequestReferencias } from "@interfaces/referencias";
import { baseApi } from "./baseApi";
import { ReferenceResponse } from "@interfaces/response";
import { UUID } from "crypto";

const referenciasApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postReferences: build.mutation<ReferenceResponse, PayloadRequestReferencias>({
      query: (data: PayloadRequestReferencias) => ({
        url: `/referencias`,
        method: "POST",
        body: data,
      }),
    }),
    patchReferences: build.mutation<
      ReferenceResponse,
      { referenceId: UUID | undefined; body: PayloadRequestReferencias }
    >({
      query: ({ referenceId, body }) => ({
        url: `/referencias/${referenceId}`,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const { usePostReferencesMutation, usePatchReferencesMutation } = referenciasApi;
