/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RootState } from "@store/store";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import img from "@assets/img/img_tofu/img_tofu_2.svg";
import { usePostValidateNextStageMutation, useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { DOCUMENTS_CATALOG, TAX_SYSTEMS, STATUS_CATALOG } from "@helpers/constantes";
import { DocumentResponse } from "@interfaces/response";
import { ModalExpediente } from "@components/CustomModalExpediente";

export const DocsEmpresa = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getApplicationById] = useLazyGetApplicationByApplicationIdQuery();
  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [documentIdentificacion, setDocumentIdentificacion] = useState<DocumentResponse | undefined>(undefined);
  const [documentActa, setDocumentActa] = useState<DocumentResponse | undefined>(undefined);
  const [documentActasAdicionales, setDocumentActasAdicionales] = useState<(DocumentResponse | undefined)[]>([]);
  const [dictamenValidado, setDictamenValidado] = useState(false);
  const [tabKey, setTabKey] = useState(0);

  const handleOnClickAddExtra = () => {
    if (dictamenValidado) return;

    const copy = [...documentActasAdicionales];
    copy.push(undefined);
    setDocumentActasAdicionales(copy);
  };

  const [showModalCompanyNotAvailable, setShowModalCompanyNotAvailable] = useState(false);

  const handleClickContinuar = async () => {
    try {
      if (!documentActa || !documentIdentificacion) {
        setShowModalCompanyNotAvailable(true);
        return;
      }

      await triggerPostNextStage({
        applicationId: applicationData.id,
        statusCode: STATUS_CATALOG.avance_expediente_digital.code,
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();

      navigate("/producto/creditosimple/avance_expediente_digital");
    } catch (error) {}
  };

  const filterDocuments = (documents: DocumentResponse[], file_code: string): DocumentResponse[] => {
    const documents_result = documents
      .filter((item) => item.file_code === file_code && item.status)
      .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    return documents_result;
  };

  useEffect(() => {
    (async () => {
      try {
        const [res_document_list, res_application] = await Promise.all([
          getDocumentsByAppId({
            clientDetailsId: personalData.id,
            loanApplicationId: applicationData.id,
          }).unwrap(),
          getApplicationById(applicationData.id).unwrap(),
        ]);

        const application = res_application.data[0];
        const document_identificacion = filterDocuments(
          res_document_list,
          DOCUMENTS_CATALOG.identificacion_representante_legal.code,
        );
        const document_acta = filterDocuments(res_document_list, DOCUMENTS_CATALOG.acta_constitutiva.code);
        const document_actas_adicionales: (DocumentResponse | undefined)[] = filterDocuments(
          res_document_list,
          DOCUMENTS_CATALOG.acta_adicional.code,
        );

        if (!document_actas_adicionales.length) {
          document_actas_adicionales.push(undefined);
        }

        setDocumentIdentificacion(document_identificacion[0]);
        setDocumentActa(document_acta[0]);
        setDocumentActasAdicionales(document_actas_adicionales);
        setDictamenValidado(application.decision_validated);
      } catch (error) {
        console.log("Ha ocurrido un error al obtener los documentos: ", error);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
            Expediente digital
          </Typography>

          <TabStepBofuPm key={tabKey} />

          <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400} color={"#528CD6"} mt={4} mb={4}>
            Comenzaremos con el dictamen de tu empresa, para lo que necesitaremos los siguientes documentos
          </Typography>

          <CustomUploadFileV2
            py={2}
            inputName="acta_constitutiva"
            optionalDescription="Deberá contar con su registro público de la propiedad"
            uploadedFile={documentActa}
            setUploadedFile={(newValue) => {
              setDocumentActa(newValue);
              setTabKey((prev) => prev + 1);
            }}
            metadata={{
              fileCode: DOCUMENTS_CATALOG.acta_constitutiva.code,
              taxCode: TAX_SYSTEMS.PM.code,
              uploadType: "client",
              loanApplicationId: applicationData.id,
              regimen: applicationData.type,
              clientDetailsId: personalData.id,
            }}
          />

          {documentActasAdicionales.map((actaAdicional, index) => (
            <CustomUploadFileV2
              py={2}
              key={index}
              inputName={`acta_adicional_${index}`}
              uploadedFile={actaAdicional}
              setUploadedFile={(newValue) => {
                const copy = [...documentActasAdicionales];
                if (newValue) {
                  copy[index] = newValue;
                } else {
                  copy.splice(index, 1);
                }
                setDocumentActasAdicionales(copy);
              }}
              metadata={{
                fileCode: DOCUMENTS_CATALOG.acta_adicional.code,
                taxCode: TAX_SYSTEMS.PM.code,
                uploadType: "client",
                loanApplicationId: applicationData.id,
                regimen: applicationData.type,
                clientDetailsId: personalData.id,
              }}
            />
          ))}

          <Box textAlign={"right"}>
            <Link onClick={handleOnClickAddExtra} sx={{ cursor: dictamenValidado ? "not-allowed" : "pointer" }}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#528CD6"}>
                + Agregar acta adicional
              </Typography>
            </Link>
          </Box>

          <CustomUploadFileV2
            py={2}
            inputName="identificacion_representante"
            optionalDescription="Deberá contar con su registro público de la propiedad"
            uploadedFile={documentIdentificacion}
            setUploadedFile={(newValue) => {
              setDocumentIdentificacion(newValue);
              setTabKey((prev) => prev + 1);
            }}
            metadata={{
              fileCode: DOCUMENTS_CATALOG.identificacion_representante_legal.code,
              taxCode: TAX_SYSTEMS.PM.code,
              uploadType: "client",
              loanApplicationId: applicationData.id,
              regimen: applicationData.type,
              clientDetailsId: personalData.id,
            }}
          />

          <Box mt={2}>
            <Button
              onClick={handleClickContinuar}
              variant={documentActa && documentIdentificacion ? "default" : "default_disabled"}
              type="button"
            >
              Continuar
            </Button>
          </Box>

          <ModalExpediente
            type="company_not_available"
            openModal={showModalCompanyNotAvailable}
            closeModal={() => setShowModalCompanyNotAvailable(false)}
          />
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
