/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import img from "@assets/img/img_tofu/img_tofu_3.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { setCompanyCompleted } from "@store/slices/bofuSlice";
import { usePutApplicationCompletedMutation, useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { usePostDocumentGenarateMutation, useLazyGetDocumentAppIdQuery } from "@api/documents";
import { TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomButtonExpediente from "@components/CustomButtonExpediente";
import { useLazyGetPersonsQuery } from "@api/personasApi";
import { DOCUMENTS_CATALOG, TAX_SYSTEMS, PERSON_CODES_LEGAL_REPRESENTATIVE } from "@helpers/constantes";
import { DocumentResponse, PersonaStructure } from "@interfaces/response";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { ModalExpediente } from "@components/CustomModalExpediente";

export const AvanceExpedienteDigital: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const companySection = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [dictamenCompleted, setDictamenCompleted] = useState(null);
  const [openModalValidacionIndentidad, setOpenModalValidacionIdentidad] = useState(false);
  const [openModalSolicitudCredito, setOpenModalSolicitudCredito] = useState(false);
  const [openModalEmpresa, setOpenModalEmpresa] = useState(false);

  const [tabKey, setTabKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loanApplication, setLoanApplication] = useState<any>(null);
  const [legalRepresentative, setLegalRepresentative] = useState<PersonaStructure | undefined>(undefined);
  const [docComprobanteDomicilio, setDocComprobanteDomicilio] = useState<DocumentResponse | undefined>(undefined);
  const [docSolicitudCredito, setDocSolicitudCredito] = useState<DocumentResponse | undefined>(undefined);

  const [getApplicationById] = useLazyGetApplicationByApplicationIdQuery();
  const [getDocumentByApplicationClient] = useLazyGetDocumentAppIdQuery();
  const [createDocument] = usePostDocumentGenarateMutation();
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const [getPersons] = useLazyGetPersonsQuery();

  const generateSolicitudDocument = async () => {
    try {
      const data = {
        loan_application_id: application.id,
        document_type: "solicitud_credito",
      };

      await createDocument(data);
    } catch (error: any) {
      console.log("Error al generar el documento de solicitud de crédito", error);
    }
  };

  const updateStatusComplete = async (id: string) => {
    try {
      const data = {
        completed: true,
      };

      await putAapplicationCompleted({ id_sol: id, body: data });
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const handleClickSolicitudCredito = () => {
    if (dictamenCompleted && legalRepresentative) {
      navigate("/producto/creditosimple/solicitud_credito_1");
    } else {
      setOpenModalSolicitudCredito(true);
    }
  };

  const handleClickValidacionIdentidad = () => {
    if (dictamenCompleted && legalRepresentative) {
      navigate("/producto/creditosimple/validacion_representante_legal");
    } else {
      setOpenModalValidacionIdentidad(true);
    }
  };

  const handleClickContinue = () => {
    if (!dictamenCompleted) {
      setOpenModalEmpresa(true);
      return;
    }
    navigate("/producto/creditosimple/docs_aval");
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const [res_loan_application, res_documents, persons] = await Promise.all([
          getApplicationById(application.id).unwrap(),
          getDocumentByApplicationClient({
            loanApplicationId: application.id,
            clientDetailsId: clientDetails.id,
          }).unwrap(),
          getPersons({
            loanApplicationId: application.id,
            personTypeCode: [...PERSON_CODES_LEGAL_REPRESENTATIVE],
          }).unwrap(),
        ]);

        const loan_application = res_loan_application.data[0];
        if (!loan_application) {
          console.log("No se encontró la solicitud de crédito");
          return;
        }

        const {
          loan_application_completed: application_completed,
          references_completed,
          bank_information_completed: bank_completed,
          identity_validation_completed: identity_completed,
          completed: completed,
          decision_validated: dictamen_validated,
        } = loan_application;
        const { solicitud_credito, comprobante_domicilio } = DOCUMENTS_CATALOG;
        const representante_legal = persons[0];
        const doc_comprobante_domicilio = res_documents.find((file) => file.file_code === comprobante_domicilio.code);
        const doc_solicitud_credito = res_documents.find((file) => file.file_code === solicitud_credito.code);

        if (identity_completed && application_completed && references_completed && bank_completed) {
          dispatch(setCompanyCompleted());
        }

        const all_forms_completed =
          application_completed && references_completed && bank_completed && identity_completed;

        if (all_forms_completed) {
          if (!doc_solicitud_credito) {
            await generateSolicitudDocument();
          }
        }

        if (completed === false) {
          if (application_completed && references_completed && bank_completed) {
            generateSolicitudDocument();
            updateStatusComplete(application.id);
            setTabKey((prevKey) => prevKey + 1);
          }
        }

        setLoanApplication(loan_application);
        setDictamenCompleted(dictamen_validated);
        setLegalRepresentative(representante_legal);
        setDocComprobanteDomicilio(doc_comprobante_domicilio);
        setDocSolicitudCredito(doc_solicitud_credito);
      } catch (error) {
        console.log("Ha ocurrido un error al consultar la solicitud", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={2}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
              Expediente digital
            </Typography>

            <TabStepBofuPm key={tabKey} />

            <Typography mt={4} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={700}>
              Muy bien, ahora llena los formatos relacionados a la empresa
            </Typography>

            {isLoading ? (
              <Box mt={3}>
                <Skeleton variant="rectangular" height={100} />
              </Box>
            ) : (
              <Box>
                <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} color={"#528CD6"}>
                  {companySection.legal_name ?? "Nombre del Cliente"}
                </Typography>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Solicitud de crédito
                  </Typography>

                  <CustomButtonExpediente
                    onClick={handleClickSolicitudCredito}
                    simulateDisabled={!dictamenCompleted || !legalRepresentative}
                    formStatus={loanApplication?.loan_application_completed}
                    documentStatus={docSolicitudCredito?.validated}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Referencias
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/referencias_clientes")}
                    formStatus={loanApplication?.references_completed}
                    documentStatus={docSolicitudCredito?.validated}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Información bancaria
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/info_bancaria_empresa")}
                    formStatus={loanApplication?.bank_information_completed}
                    documentStatus={docSolicitudCredito?.validated}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                    Validación de identidad
                  </Typography>

                  <CustomButtonExpediente
                    onClick={handleClickValidacionIdentidad}
                    formStatus={loanApplication?.identity_validation_completed}
                    documentStatus={docSolicitudCredito?.validated}
                    simulateDisabled={!dictamenCompleted || !legalRepresentative}
                  />
                </Box>

                <Typography mt={4} variant={isMobileOrTablet ? "body2" : "body1"}>
                  Necesitarás tener la identificación a la mano y tomar un par de selfies
                </Typography>

                <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
                  Ahora carga los documentos relacionados a la empresa
                </Typography>

                <Box mt={2} textAlign="left">
                  <CustomUploadFileV2
                    mt={2}
                    inputName={"comprobante_domicilio"}
                    uploadedFile={docComprobanteDomicilio}
                    setUploadedFile={(newUpload: DocumentResponse | undefined) => {
                      setDocComprobanteDomicilio(newUpload);
                      setTabKey((prevKey) => prevKey + 1);
                    }}
                    optionalDescription="No deberá ser mayor a 3 meses"
                    metadata={{
                      taxCode: TAX_SYSTEMS.PM.code,
                      fileCode: DOCUMENTS_CATALOG.comprobante_domicilio.code,
                      uploadType: "client",
                      loanApplicationId: application.id,
                      regimen: application.type,
                      clientDetailsId: clientDetails.id,
                    }}
                  />

                  <Box mt={3} mb={3} textAlign={"center"}>
                    <Button variant={dictamenCompleted ? "default" : "default_disabled"} onClick={handleClickContinue}>
                      Continuar
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <ModalExpediente
        type="solicitud_credito"
        openModal={openModalSolicitudCredito}
        closeModal={() => setOpenModalSolicitudCredito(false)}
      />

      <ModalExpediente
        type="identity_validation_aval"
        openModal={openModalValidacionIndentidad}
        closeModal={() => setOpenModalValidacionIdentidad(false)}
      />

      <ModalExpediente
        type="dictamen_not_available"
        openModal={openModalEmpresa}
        closeModal={() => setOpenModalEmpresa(false)}
      />
    </>
  );
};
