import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import img from "@assets/img/img_tofu/img_tofu_6.svg";
import icon from "@assets/img/icon_rectangulo.svg";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetScheduleByApplicationIdQuery } from "@api/applications";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { usePostValidateNextStageMutation } from "@api/applications";

const SECONDS_TO_FETCH = 60 * 1000;

const ConfirmacionDomicilio = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const applicationData = useSelector((state: RootState) => state.register.application);
  const userData = useSelector((state: RootState) => state.register.application.type);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getSchedule] = useLazyGetScheduleByApplicationIdQuery();
  const [getLoanApplication] = useLazyGetApplicationByApplicationIdQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const timerAgendarVisita = useRef<NodeJS.Timeout | null>(null);

  const validateForStatusChange = async () => {
    try {
      const [loan_response, schedule_response] = await Promise.all([
        getLoanApplication(applicationData.id).unwrap(),
        getSchedule(applicationData.id).unwrap(),
      ]);

      const loan_data = loan_response?.data[0];
      const schedule_data = schedule_response?.data.data[0];

      if (loan_data?.ocular_visit && schedule_data) {
        if (loan_data?.status_catalog?.code === "CSVYC") {
          await triggerPostNextStage({
            applicationId: applicationData.id,
            statusCode: "CSVVC",
            username: `BOFU - ${accountUser.email}`,
          }).unwrap();
        }
        navigate("/producto/creditosimple/confirmacion_visita_agendada");
        return true;
      }

      return false;
    } catch (error) {
      console.error("Ha ocurrido un error al validar el status");
      return false;
    }
  };

  useEffect(() => {
    const startValidations = async () => {
      const result = await validateForStatusChange();
      if (!result) {
        timerAgendarVisita.current = setInterval(() => {
          validateForStatusChange();
        }, SECONDS_TO_FETCH);
      }
    };

    startValidations();

    return () => {
      timerAgendarVisita.current && clearInterval(timerAgendarVisita.current);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />

        <Typography
          mt={5}
          px={2}
          textAlign={"center"}
          variant={isMobileOrTablet ? "body2" : "body1"}
          color={"#002652"}
          fontWeight={800}
        >
          Agendando tu visita ocular
        </Typography>

        <NavBarOcularVisit currentStep={1} />

        <Typography
          mt={5}
          px={5}
          textAlign={"center"}
          variant={isMobileOrTablet ? "body2" : "body1"}
          color="#528CD6"
          fontWeight={600}
        >
          Gracias por confirmar tu domicilio, tu visita está en proceso de ser agendada
        </Typography>
        <Box textAlign={"center"} mx={4}>
          <Box
            sx={{
              backgroundColor: "#EAF3FF",
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
            }}
            mt={3}
          >
            <Typography fontWeight={600} color={"#002652"} fontSize={"14px"} align="center">
              Recuerda que el día de la visita deberás presentar los siguientes documentos
            </Typography>
            <List>
              {(() => {
                if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
                  return [
                    "Caratula",
                    "Acta constitutiva",
                    "Actas adicionales",
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
                  return [
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                }
                return [];
              })().map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <img src={icon} alt="Document Icon" style={{ width: "15px", height: "15x" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    sx={{
                      fontSize: "14px",
                      color: "#002652",
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Typography
            mt={4}
            px={2}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={300}
            color="#002652"
          >
            ¡Despreocúpate!
          </Typography>

          <Typography
            px={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={300}
            color="#002652"
          >
            Puedes salir de esta pantalla, tan pronto terminemos, te contactaremos via WhatsApp y puedes volver a
            iniciar sesión después
          </Typography>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmacionDomicilio;
