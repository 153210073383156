import { JSXElementConstructor, ReactElement } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

interface ButtonTabProps {
  isActive?: boolean | undefined;
  disabled?: boolean;
  onClick?: () => void;
  onClickDisabled?: () => void;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const ButtonTab = (props: ButtonTabProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const RenderButton = () => {
    return (
      <Button
        onClick={props.onClick}
        disabled={props.disabled}
        sx={{
          backgroundColor: props.isActive ? "#003865" : "transparent",
          color: props.isActive ? "#FFF" : "#003865",
          borderRadius: "8px",
          textTransform: "none",
          display: "flex",
          flexDirection: isMobileOrTablet ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: {
            xs: "100%",
            sm: "100%",
          },
          fontSize: "14px",
          fontWeight: 500,
          "&:hover": {
            backgroundColor: "#002A5D",
            color: "#FFF",
            "& .icon": {
              filter: "brightness(0) invert(1)",
            },
          },
        }}
      >
        {props.children}
      </Button>
    );
  };

  if (props.disabled && props.onClickDisabled) {
    return (
      <Box onClick={props.onClickDisabled} sx={{ cursor: "pointer", display: "flex", height: "100%", width: "100%" }}>
        <RenderButton />
      </Box>
    );
  }

  return <RenderButton />;
};

export default ButtonTab;
