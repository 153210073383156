import { baseApi } from "./baseApi";
import { SocialMediaPostResponse, SocialMediaStructure, SocialMediaPayload } from "@interfaces/response";

const socialMediaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postSocialMedia: build.mutation<SocialMediaStructure, { client_details_id: string; data: SocialMediaPayload }>({
      query: ({ client_details_id, data }) => ({
        url: `/cuentas/${client_details_id}/social_media`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response: SocialMediaPostResponse) => {
        return response.data;
      },
    }),
    patchSocialMediaData: build.mutation<
      SocialMediaStructure,
      { client_details_id: string; data: SocialMediaPayload; social_media_id: string }
    >({
      query: ({ client_details_id, data, social_media_id }) => ({
        url: `/cuentas/${client_details_id}/social_media/${social_media_id}`,
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response: SocialMediaPostResponse) => {
        return response.data;
      },
    }),
  }),
});

export const { usePostSocialMediaMutation, usePatchSocialMediaDataMutation } = socialMediaApi;
