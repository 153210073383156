import { Container, Grid, Fade, Box, Typography, IconButton, useTheme, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "@store/store";
import { setUploadModalErrorVisible } from "@store/slices/appSlice";

export const CustomUploadModalError = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const app = useSelector((state: RootState) => state.app);

  const handleOnClickClose = () => {
    dispatch(setUploadModalErrorVisible({ file_name: "", visible: false }));
  };

  return (
    <Box
        visibility={!app.upload_modal_error.visible ? "hidden" : "visible"}
        marginTop={"70px"}
        position={'fixed'}
        zIndex={999}
        minWidth={"100vw"}
        display={'flex'}
        flexDirection={'column'}
        alignContent={'center'}
    >
      <Fade in={app.upload_modal_error.visible}>
        <Container>
          <Grid container flexDirection={"row"} borderRadius={"0 0 8px 8px"} p={2} sx={{ backgroundColor: "#CD0202" }}>
            <Grid item xs={9}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#FFF"} textAlign={"center"}>
                Sucedió algo inesperado y no pudimos cargar el archivo:{" "}
                <Typography component={"span"} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={700}>
                  {app.upload_modal_error.file_name}{" "}
                </Typography>
                inténtalo nuevamente.
              </Typography>
            </Grid>
            <Grid item xs={3} textAlign={"right"}>
              <IconButton onClick={handleOnClickClose}>
                <CloseIcon sx={{ color: "#FFF", width: "30px", height: "30px" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </Box>
  );
};
