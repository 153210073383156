/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { setModalErrosVisible } from "@store/slices/appSlice";
import CloseIcon from "@mui/icons-material/Close";
import img from "@assets/img/img_tofu/img_tofu_9.svg";
import tol from "@assets/img/tooltip.svg";
import { UUID } from "crypto";
import { useLazyGetPersonsQuery, usePatchPersonMutation } from "@api/personasApi";
import { usePostDocumentGenarateMutation, useLazyGetDocumentAppIdQuery } from "@api/documents";
import { TabStepBofuPm } from "@components/index";
import { DocumentResponse, PersonaStructure } from "@interfaces/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomButtonExpediente from "@components/CustomButtonExpediente";
import {
  DOCUMENTS_CATALOG,
  STATUS_CATALOG,
  TAX_SYSTEMS,
  PERSON_CODES_AVAL,
  PERSON_CODES_PROPIETARIO_REAL,
  PERSON_CODES_LEGAL_REPRESENTATIVE,
} from "@helpers/constantes";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";

export const DocsAval = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const shareholder = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [getPersons] = useLazyGetPersonsQuery();
  const [createDocument] = usePostDocumentGenarateMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [patchPerson] = usePatchPersonMutation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listPropietarios, setListPropietarios] = useState<any[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [documentConstancia, setDocumentConstancia] = useState<DocumentResponse | undefined>(undefined);
  const [documentComprobante, setDocumentComprobante] = useState<DocumentResponse | undefined>(undefined);
  const [documentAnexo1, setDocumentAnexo1] = useState<DocumentResponse | undefined>(undefined);
  const [showIdentityValidation, setShowIdentityValidation] = useState<boolean>(false);
  const [avalselected, setAvalSelected] = useState<PersonaStructure | null>(null);
  const [tabKey, setTabKey] = useState<number>(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      constancia_situacion_fiscal: "",
      comprobante_domicilio: "",
    },
    validationSchema: yup.object().shape({
      constancia_situacion_fiscal: yup.string(),
      comprobante_domicilio: yup.string(),
    }),
    onSubmit: async (_values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        if (listPropietarios.length) {
          navigate("/producto/creditosimple/propietario_real_pm");
        } else {
          try {
            const { code: next_status, url: next_url } = STATUS_CATALOG.validando_expediente_pm;

            await triggerPostNextStage({
              applicationId: applicationData.id,
              statusCode: next_status,
              username: `BOFU - ${accountUser.email}`,
            }).unwrap();

            navigate(next_url);
          } catch (error: any) {
            if (error?.status === 500 || error?.status === 409) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
              return;
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
              return;
            }
          }
        }
      } catch (error) {
        console.log("Ha ocurrido un error al enviar los documentos", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const generateDocuments = async (person_data: any, generate: boolean) => {
    try {
      if (person_data.completed === false && generate) {
        if (
          person_data.information_person_completed &&
          person_data.bank_references_completed &&
          person_data.identity_validation_completed
        ) {
          const payload_anexo_1 = {
            loan_application_id: String(applicationData.id),
            document_type: "anexo_1",
            person_id: String(shareholder.id),
          };

          await createDocument(payload_anexo_1);

          const payload_person = {
            // TODO: validar si se debe enviar null o true
            completed: null,
          };

          await patchPerson({
            personId: shareholder.id,
            body: payload_person,
          }).unwrap();
        }
      }
    } catch (error) {
      console.log("Ha ocurrido un error al generar el anexo 1", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setShowButtons(false);

        const res_persons = await getPersons({
          loanApplicationId: applicationData.id,
          personTypeCode: [
            ...PERSON_CODES_LEGAL_REPRESENTATIVE,
            ...PERSON_CODES_AVAL,
            ...PERSON_CODES_PROPIETARIO_REAL,
          ],
        }).unwrap();

        const representantes = res_persons.filter((item) =>
          PERSON_CODES_LEGAL_REPRESENTATIVE.includes(item.person_type?.code || ""),
        );
        const avales = res_persons.filter((item) => PERSON_CODES_AVAL.includes(item?.person_type?.code || ""));
        const propietarios = res_persons.filter((item) =>
          PERSON_CODES_PROPIETARIO_REAL.includes(item.person_type?.code || ""),
        );

        const representate_selected = representantes[0];
        const aval_selected = avales[0];

        if (!aval_selected || !representate_selected) {
          navigate("/producto/creditosimple/docs_empresa");
          return;
        }

        const documents = await getDocumentsByAppId({
          loanApplicationId: applicationData.id,
          personId: aval_selected.id,
          status: true,
        }).unwrap();

        const document_constancia = documents.find(
          (item) => item.file_code === DOCUMENTS_CATALOG.constancia_situacion_fiscal.code,
        );
        const document_comprobante = documents.find(
          (item) => item.file_code === DOCUMENTS_CATALOG.comprobante_domicilio.code,
        );
        const document_anexo_1 = documents.find((item) => item.file_name.includes("anexo_1"));
        const is_same_person = aval_selected.id === representate_selected.id;

        if (document_constancia?.validated !== false) {
          formik.setFieldValue("constancia_situacion_fiscal", document_constancia?.id);
        }

        if (document_comprobante?.validated !== false) {
          formik.setFieldValue("comprobante_domicilio", document_comprobante?.id);
        }

        setShowIdentityValidation(is_same_person ? false : true);
        setListPropietarios(propietarios);
        setAvalSelected(aval_selected);
        setDocumentConstancia(document_constancia);
        setDocumentComprobante(document_comprobante);
        setDocumentAnexo1(document_anexo_1);

        setTimeout(() => {
          formik.validateForm();
        }, 200);

        await generateDocuments(aval_selected, false);
      } catch (error) {
        console.log("Ha ocurrido un error al obtener los documentos", error);
      } finally {
        setIsLoading(false);
        setShowButtons(true);
      }
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
              Expediente digital
            </Typography>

            <TabStepBofuPm key={tabKey} />

            <Typography mt={4} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={700}>
              Ahora es momento de llenar los formatos relacionados al aval <img src={tol} alt="" onClick={handleOpen} />
            </Typography>

            <Container>
              {!showButtons ? (
                <Box mt={3}>
                  <Skeleton variant="rectangular" height={100} />
                </Box>
              ) : (
                <Box>
                  <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} color={"#528CD6"}>
                    {`${avalselected?.name || ""} ${avalselected?.last_name || ""} ${avalselected?.last_name_2 || ""}`}
                  </Typography>

                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant={isMobileOrTablet ? "body2" : "body1"} sx={{ flex: 1, textAlign: "left" }}>
                      Información del aval
                    </Typography>

                    <CustomButtonExpediente
                      onClick={() => navigate("/producto/creditosimple/informacion_datos_aval")}
                      formStatus={avalselected?.information_person_completed}
                      documentStatus={documentAnexo1?.validated}
                    />
                  </Box>

                  {showIdentityValidation && (
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant={isMobileOrTablet ? "body2" : "body1"} sx={{ flex: 1, textAlign: "left" }}>
                        Validación de identidad
                      </Typography>

                      <CustomButtonExpediente
                        onClick={() => navigate("/producto/creditosimple/validacion_identidad_aval")}
                        formStatus={avalselected?.identity_validation_completed}
                        documentStatus={documentAnexo1?.validated}
                      />
                    </Box>
                  )}

                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant={isMobileOrTablet ? "body2" : "body1"} sx={{ flex: 1, textAlign: "left" }}>
                      Información bancaria
                    </Typography>

                    <CustomButtonExpediente
                      onClick={() => navigate("/producto/creditosimple/informacion_datos_aval")}
                      formStatus={avalselected?.bank_references_completed}
                      documentStatus={documentAnexo1?.validated}
                    />
                  </Box>

                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    fontWeight={600}
                    color={"#002652"}
                    align="center"
                    mt={5}
                    mb={4}
                  >
                    Ahora carga los documentos relacionados al aval
                  </Typography>

                  <form onSubmit={formik.handleSubmit} noValidate>
                    <CustomUploadFileV2
                      mt={2}
                      inputName="constancia_situacion_fiscal"
                      uploadedFile={documentConstancia}
                      setUploadedFile={(newUpload) => {
                        setDocumentConstancia(newUpload);
                        formik.setFieldValue("constancia_situacion_fiscal", newUpload?.id);
                        setTabKey((prev) => prev + 1);
                      }}
                      metadata={{
                        taxCode: TAX_SYSTEMS.PM.code,
                        personCode: "PTACAV",
                        fileCode: DOCUMENTS_CATALOG.constancia_situacion_fiscal.code,
                        uploadType: "person",
                        loanApplicationId: applicationData.id,
                        regimen: applicationData.type,
                        personId: avalselected?.id as UUID,
                        clientDetailsId: clientDetails.id,
                        personType: "accionista_mayoritario",
                      }}
                      disabled={isLoading || formik.isSubmitting}
                    />

                    <CustomUploadFileV2
                      mt={2}
                      inputName="comprobante_domicilio"
                      uploadedFile={documentComprobante}
                      setUploadedFile={(newUpload) => {
                        setDocumentComprobante(newUpload);
                        formik.setFieldValue("comprobante_domicilio", newUpload?.id);
                      }}
                      optionalDescription="No deberá ser mayor a 3 meses"
                      metadata={{
                        taxCode: TAX_SYSTEMS.PM.code,
                        personCode: "PTACAV",
                        fileCode: DOCUMENTS_CATALOG.comprobante_domicilio.code,
                        uploadType: "person",
                        loanApplicationId: applicationData.id,
                        regimen: applicationData.type,
                        personId: avalselected?.id as UUID,
                        clientDetailsId: clientDetails.id,
                        personType: "accionista_mayoritario",
                      }}
                      disabled={isLoading || formik.isSubmitting}
                    />

                    <Box display="flex" justifyContent="center" mt={2} mb={4}>
                      <Button
                        sx={{ width: "130px" }}
                        type="submit"
                        variant="default"
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
            </Container>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "250px", sm: "300px", md: "60vw", lg: "40vw" },
            maxWidth: 500,
            bgcolor: "background.paper",
            border: "2px solid #002652",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#76A1FF",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography id="modal-title" variant="h6" component="h2" fontWeight={600} color="#002652">
            Aval
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2, color: "#002652" }}>
            Es el accionista mayoritario de la empresa, que a su vez, será la persona encargada de asegurar que se
            saldará la deuda.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
