import { SyntheticEvent, useRef, useState } from "react";
import { Box, Button, Modal, SxProps, Typography, IconButton } from "@mui/material";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import IconRemove from "@mui/icons-material/Remove";
import IconAdd from "@mui/icons-material/Add";
import canvasResult from "@components/NavBar/canvasResult";

import icon_navbar_camera from "@assets/img/icon_navbar_camera.svg";
import image_profile_1 from "@assets/img/image_profile_1.svg";
import image_profile_2 from "@assets/img/image_profile_2.svg";
import image_profile_3 from "@assets/img/image_profile_3.svg";
import image_profile_4 from "@assets/img/image_profile_4.svg";
import image_profile_5 from "@assets/img/image_profile_5.svg";
import image_profile_6 from "@assets/img/image_profile_6.svg";

const styleModalImageProfile: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 574,
  height: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "8px",
};

const styleModalImageCrop: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 574,
  height: 561,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  outline: "none",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  py: 2,
  alignItems: "center",
};

const styleModalButton: SxProps = {
  fontFamily: "Outfit",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20.16px",
  textAlign: "center",
};

interface ImageDefaultProps {
  onClick: () => void;
  imageSrc: string;
  isSelected: boolean;
}

const ImageDefault = (props: ImageDefaultProps) => {
  return (
    <Box
      onClick={() => props.onClick()}
      sx={{
        width: "139px",
        height: "150px",
        cursor: "pointer",
        boxShadow: "8px 4px 20px 0px rgba(88, 88, 88, 0.25)",
        borderBottomRightRadius: "50px",
        borderColor: props.isSelected ? "#528CD6" : "",
        borderWidth: props.isSelected ? "2px" : "0px",
        borderStyle: props.isSelected ? "solid" : "none",
      }}
    >
      <img src={props.imageSrc} alt="camera" width={139} height={150} />
    </Box>
  );
};

const ImageArea = () => {
  const refInputImage = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");

  const imgRef = useRef<HTMLImageElement>(null);
  const ratio = 283 / 308;
  const maxScale = 4;
  const minScale = 1;
  const [scale, setScale] = useState(1);
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const openInputImage = () => {
    if (refInputImage.current) {
      refInputImage.current.click();
    }
  };

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const closeModal = () => {
    setSelectedImage("");
  };

  const submitImage = () => {
    if(!imgRef.current) {
      return;
    }

    const base_64 = canvasResult(
      imgRef.current,
      crop,
      scale,
      0
    );
    if (!base_64) {
      return;
    }

    setSelectedImage("");
  };

  const onImageLoaded = (evnt: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = evnt.currentTarget;
    const max_w = Math.min(width, height * ratio);
    const max_h = Math.min(height, width / ratio);

    setCrop({
      ...crop,
      width: max_w,
      height: max_h,
    });
  };

  const changeScale = (value: number) => {
    if (value > 0) {
      if (scale < maxScale) {
        setScale(scale + 0.1);
      }
    } else {
      if (scale > minScale) {
        setScale(scale - 0.1);
      }
    }
  };

  return (
    <Box
      width="100%"
      height="147px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="2px #A3D4E8 dashed"
      borderRadius="0px 0px 33px 33px"
      mt={2}
    >
      <Typography
        fontFamily="Montserrat"
        fontWeight={400}
        fontSize="16px"
        lineHeight="19.5px"
        textAlign="center"
        color="#000000"
      >
        Arrastra aquí o&nbsp;
        <Typography
          component="span"
          fontFamily="Montserrat"
          fontWeight={500}
          color="#528CD6"
          fontSize="16px"
          lineHeight="19.5px"
          onClick={() => openInputImage()}
          sx={{ cursor: "pointer" }}
        >
          selecciona
        </Typography>
        &nbsp;tu foto desde tu computadora
      </Typography>

      <input ref={refInputImage} type="file" accept="image/*" style={{ display: "none" }} onChange={selectImage} />

      <Modal
        open={selectedImage !== ""}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalImageCrop} aria-labelledby="modal-body">
          <Typography
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize="20px"
            lineHeight="24.38px"
            color="#002652"
            textAlign="center"
          >
            Ajusta tu foto
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="479px"
            height="329px"
            mt={1}
          >
            <ReactCrop
              className=""
              style={{
                maxWidth: "479px",
                maxHeight: "329px",
              }}
              crop={crop}
              ruleOfThirds
              aspect={ratio}
              onChange={setCrop}
              keepSelection={true}
            >
              <img
                ref={imgRef}
                src={selectedImage}
                alt="Imagen para cortar"
                onLoad={onImageLoaded}
                style={{
                  transform: `scale(${scale})`,
                }}
              />
            </ReactCrop>
          </Box>

          <Typography
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize="16px"
            lineHeight="19.5px"
            color="#002652"
            textAlign="center"
            mt={1}
          >
            Zoom
          </Typography>

          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="479px">
            <IconButton onClick={() => changeScale(-0.1)}>
              <IconRemove htmlColor="#002652" sx={{ fontSize: "25px" }} />
            </IconButton>

            <IconButton onClick={() => changeScale(0.1)}>
              <IconAdd htmlColor="#002652" sx={{ fontSize: "25px" }} />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-around"  mt={2} width="479px">
            <Button variant="blue_outlined" sx={styleModalButton} onClick={closeModal}>
              Regresar
            </Button>
            <Button variant="contained" sx={styleModalButton} onClick={submitImage}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const ImageProfile = () => {
  const [open, setOpen] = useState(false);
  const [imageSelected, setImageSelected] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#FFFFFF",
          position: "absolute",
          backgroundColor: "#E3F2F8",
          width: "139px",
          height: "150px",
          zIndex: 1,
          top: 20,
          left: 60,
          boxShadow: "8px 4px 20px 0px rgba(88, 88, 88, 0.25)",
          borderBottomRightRadius: "50px",
          cursor: "pointer",
        }}
        onClick={() => handleOpen()}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "15.85px",
              color: "#528CD6",
              textAlign: "center",
            }}
          >
            Selecciona una imágen
          </Typography>
          <img src={icon_navbar_camera} alt="camera" width={20} height={17.05} />
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalImageProfile} aria-labelledby="modal-body">
          <Typography
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize="20px"
            lineHeight="24.38px"
            color="#002652"
            textAlign="center"
          >
            Selecciona una imágen de tu computadora
          </Typography>

          <ImageArea />

          <Typography
            fontFamily="Montserrat"
            fontWeight={600}
            fontSize="20px"
            lineHeight="24.38px"
            color="#002652"
            textAlign="center"
            mt={3}
          >
            O selecciona una de nuestras imágenes
          </Typography>

          <Box display="flex" flexDirection="column" gap={4} mt={3}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <ImageDefault
                onClick={() => setImageSelected("image_1")}
                imageSrc={image_profile_1}
                isSelected={imageSelected === "image_1"}
              />
              <ImageDefault
                onClick={() => setImageSelected("image_2")}
                imageSrc={image_profile_2}
                isSelected={imageSelected === "image_2"}
              />
              <ImageDefault
                onClick={() => setImageSelected("image_3")}
                imageSrc={image_profile_3}
                isSelected={imageSelected === "image_3"}
              />
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <ImageDefault
                onClick={() => setImageSelected("image_4")}
                imageSrc={image_profile_4}
                isSelected={imageSelected === "image_4"}
              />
              <ImageDefault
                onClick={() => setImageSelected("image_5")}
                imageSrc={image_profile_5}
                isSelected={imageSelected === "image_5"}
              />
              <ImageDefault
                onClick={() => setImageSelected("image_6")}
                imageSrc={image_profile_6}
                isSelected={imageSelected === "image_6"}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-around" mt={7}>
            <Button variant="blue_outlined" sx={styleModalButton} onClick={handleClose}>
              Regresar
            </Button>
            <Button variant="contained" sx={styleModalButton} onClick={handleClose}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* <ModalCropImage /> */}
    </>
  );
};

export default ImageProfile;
