/* eslint-disable @typescript-eslint/no-explicit-any */
import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { PayloadAccountPatch, PayloadCompanyData, Response } from "src/types";

const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // TODO: cambiar mas adelante por patchCuenta
    addCompanyData: build.query<Response, any>({
      query: (data: { id: UUID; body: PayloadCompanyData }) => ({
        url: `cuentas/${data.id}`,
        method: "PATCH",
        body: {
          company_rfc: data.body.rfc,
          company_name: data.body.name,
          company_legal_name: data.body.legal_name,
          is_pfae: data.body.is_pfae,
          pfae_name: data.body.pfae_name,
          pfae_last_name: data.body.pfae_last_name,
          pfae_last_name_2: data.body.pfae_last_name_2,
        },
      }),
    }),
    getCuentasById: build.query<Response, { id: string }>({
      query: ({ id }) => ({
        url: `cuentas/${id}`,
        method: "GET",
      }),
    }),
    patchCuenta: build.mutation<Response, { accountId: string; body: PayloadAccountPatch }>({
      query: ({ accountId, body }) => ({
        url: `cuentas/${accountId}`,
        method: "PATCH",
        body,
      }),
    }),
    // TODO: cambiar mas adelante por patchCuenta
    updateLegalRepresentativeData: build.query<Response, any>({
      query: (data: {
        id: UUID;
        name: string;
        last_name: string;
        last_name_2: string;
        emailLegalRepresentative: string;
        countryCodeLegalRepresentative: string;
        phoneLegalRepresentative: string;
        businessActivityId: string;
        businessDescription: string;
      }) => ({
        url: `cuentas/${data.id}`,
        method: "PATCH",
        body: {
          name: data.name,
          last_name: data.last_name,
          last_name_2: data.last_name_2,
          email_legal_representative: data.emailLegalRepresentative,
          country_code_legal_representative: data.countryCodeLegalRepresentative,
          phone_legal_representative: data.phoneLegalRepresentative,
          business_activity_id: data.businessActivityId,
          business_description: data.businessDescription,
        },
      }),
    }),
  }),
});

export const {
  useLazyAddCompanyDataQuery,
  useLazyUpdateLegalRepresentativeDataQuery,
  useLazyGetCuentasByIdQuery,
  usePatchCuentaMutation,
} = accountApi;
