/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import img from "@assets/img/img_digital_right.svg";
import { ProgressBar } from "@components/index";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { usePostValidateNextStageMutation } from "@api/applications";
import { usePatchSocialMediaDataMutation, usePostSocialMediaMutation } from "@api/socialMediaApi";
import { WEP_PAGE } from "@utils/expreciones";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus, setDigitalPresence } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";

const PresenciaDigitalPm = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [postSocialMedia] = usePostSocialMediaMutation();
  const [patchSocialMedia] = usePatchSocialMediaDataMutation();

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const digitalPresence = useSelector((state: RootState) => state.register.digital_presence);
  const formik = useFormik({
    initialValues: {
      has_web_page: digitalPresence.has_web_page,
      web_page: digitalPresence.web_page || "",
      has_social_media: digitalPresence.has_social_media,
      linkedin: digitalPresence.linkedin || "",
      facebook: digitalPresence.facebook || "",
      instagram: digitalPresence.instagram || "",
      twitter: digitalPresence.twitter || "",
    },
    validationSchema: yup.object().shape({
      has_web_page: yup.boolean().required("Campo requerido"),
      web_page: yup.string().when("has_web_page", {
        is: true,
        then: (schema) => schema.required("Campo requerido").matches(WEP_PAGE, "URL no válida"),
      }),
      has_social_media: yup.boolean().required("Campo requerido"),
      linkedin: yup.string().when("has_social_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "linkedin",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkedin, facebook, instagram, twitter } = this.parent;
              return Boolean(linkedin || facebook || instagram || twitter);
            },
          });
        }
        return yup.string();
      }),
      facebook: yup.string().when("has_social_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "facebook",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkedin, facebook, instagram, twitter } = this.parent;
              return Boolean(linkedin || facebook || instagram || twitter);
            },
          });
        }
        return yup.string();
      }),
      instagram: yup.string().when("has_social_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "instagram",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkedin, facebook, instagram, twitter } = this.parent;
              return Boolean(linkedin || facebook || instagram || twitter);
            },
          });
        }
        return yup.string();
      }),
      twitter: yup.string().when("has_social_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "twitter",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkedin, facebook, instagram, twitter } = this.parent;
              return Boolean(linkedin || facebook || instagram || twitter);
            },
          });
        }
        return yup.string();
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);
          const data = {
            has_web_page: !!values.has_web_page,
            web_page: values.web_page || "",
            has_social_media: !!values.has_social_media,
            linkedin: values.linkedin || "",
            facebook: values.facebook || "",
            instagram: values.instagram || "",
            x_twitter: values.twitter || "",
          };
          const next_status = STATUS_CATALOG.datos_accionista.code;
          const next_url = STATUS_CATALOG.datos_accionista.url;

          let result;

          if (digitalPresence.id) {
            result = await patchSocialMedia({
              client_details_id: clientDetails.id,
              data,
              social_media_id: digitalPresence.id,
            }).unwrap();
          } else {
            result = await postSocialMedia({ client_details_id: clientDetails.id, data }).unwrap();

            await triggerPostNextStage({
              applicationId: application.id,
              statusCode: next_status,
              username: `TOFU - ${accountUser.email}`,
            }).unwrap();

            dispatch(setLoanStatus(next_status));
          }

          dispatch(
            setDigitalPresence({
              id: result.id,
              has_web_page: !!values.has_web_page,
              web_page: values.web_page,
              has_social_media: !!values.has_social_media,
              linkedin: values.linkedin,
              facebook: values.facebook,
              instagram: values.instagram,
              twitter: values.twitter,
            }),
          );

          navigate(next_url);
        } catch (error: any) {
          console.log("Ha ocurrido un error", error);
          if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center" color={"#002652"}>
            Presencia digital
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={6} />
          </Container>

          <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3}>
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} textAlign={"center"} color="#002652">
              Conocer la información de los canales digitales de tu negocio nos permite mejorar tu oferta personalizada
            </Typography>
          </Box>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box my={2}>
                <FormControl fullWidth>
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                    ¿La empresa cuenta con página web?
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="tienes_web"
                    name="has_web_page"
                    sx={{ marginTop: "1em" }}
                    value={formik.values.has_web_page?.toString()}
                    onChange={(event) => {
                      const new_value = event.target.value === "true";
                      formik.setFieldValue("has_web_page", new_value);
                      if (!new_value) {
                        formik.setFieldValue("web_page", "");
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4} md={4}>
                        <FormControlLabel value={true} control={<Radio />} label="Si" disabled={formik.isSubmitting} />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <FormControlLabel value={false} control={<Radio />} label="No" disabled={formik.isSubmitting} />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Box>

              {formik.values.has_web_page && (
                <TextField
                  id="web_page"
                  name="web_page"
                  label="Página web"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.web_page && Boolean(formik.errors.web_page)}
                  helperText={formik.touched.web_page && formik.errors.web_page}
                  value={formik.values.web_page}
                  disabled={formik.isSubmitting}
                />
              )}

              <Box my={2}>
                <FormControl fullWidth>
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                    ¿La empresa cuenta con redes sociales?
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="tienes_redes"
                    name="has_social_media"
                    sx={{ marginTop: "1em" }}
                    value={formik.values.has_social_media.toString()}
                    onChange={(event) => {
                      const new_value = event.target.value === "true";
                      formik.setFieldValue("has_social_media", new_value);
                      if (!new_value) {
                        formik.setFieldValue("linkedin", "");
                        formik.setFieldValue("facebook", "");
                        formik.setFieldValue("instagram", "");
                        formik.setFieldValue("twitter", "");
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4} md={4}>
                        <FormControlLabel value={true} control={<Radio />} label="Si" disabled={formik.isSubmitting} />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <FormControlLabel value={false} control={<Radio />} label="No" disabled={formik.isSubmitting} />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Box>

              {formik.values.has_social_media && (
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      id="linkedin"
                      name="linkedin"
                      label="LinkedIn"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
                      helperText={formik.touched.linkedin && formik.errors.linkedin}
                      value={formik.values.linkedin}
                      disabled={formik.isSubmitting}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      id="facebook"
                      name="facebook"
                      label="Facebook"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.facebook && Boolean(formik.errors.facebook)}
                      helperText={formik.touched.facebook && formik.errors.facebook}
                      value={formik.values.facebook}
                      disabled={formik.isSubmitting}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      id="instagram"
                      name="instagram"
                      label="Instagram"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                      helperText={formik.touched.instagram && formik.errors.instagram}
                      value={formik.values.instagram}
                      disabled={formik.isSubmitting}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      id="twitter"
                      name="twitter"
                      label="X (Twitter)"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.twitter && Boolean(formik.errors.twitter)}
                      helperText={formik.touched.twitter && formik.errors.twitter}
                      value={formik.values.twitter}
                      disabled={formik.isSubmitting}
                    />
                  </FormControl>
                </Box>
              )}

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PresenciaDigitalPm;
