/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Container,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RFC_PM } from "@utils/expreciones";
import { useAppDispatch, type RootState } from "@store/store";
import { ProgressBar } from "@components/ProgressBar";
import { setCompanyData, setLoanStatus } from "@store/slices/registerSlice";
import { useLazyAddCompanyDataQuery } from "@api/account";
import { usePostValidateNextStageMutation } from "@api/applications";
import img from "@assets/img/right_5.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { STATUS_CATALOG } from "@helpers/constantes";

const DatosEmpresaPm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [triggerAddCompanyData] = useLazyAddCompanyDataQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const formik = useFormik({
    initialValues: {
      rfc: companyData.rfc || "",
      name: companyData.name || "",
      legal_name: companyData.legal_name || "",
    },
    validationSchema: yup.object().shape({
      rfc: yup.string().required("Campo requerido").matches(RFC_PM, "Formato incorrecto, inténtalo de nuevo"),
      name: yup.string().required("Campo requerido"),
      legal_name: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) {
        return;
      }

      try {
        setSubmitting(true);

        const next_status = STATUS_CATALOG.consulta_ciec.code;
        const next_url = STATUS_CATALOG.consulta_ciec.url;

        if (!companyData.is_correct_ciec) {
          await triggerAddCompanyData({
            id: clientDetails.id,
            body: {
              rfc: values.rfc,
              name: values.name,
              legal_name: values.legal_name,
            },
          }).unwrap();

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: next_status,
            username: `TOFU - ${accountUser.email}`,
          }).unwrap();

          dispatch(
            setCompanyData({
              id: clientDetails.id,
              rfc: values.rfc,
              name: values.name,
              legal_name: values.legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_correct_ciec: false,
            }),
          );
        }

        dispatch(setLoanStatus(next_status));

        navigate(next_url);
      } catch (error: any) {
        if (error?.data?.errors) {
          formik.setFieldError("rfc", `Existe una cuenta con RFC - ${values.rfc}`);
          return;
        }
        if (error?.status === 400 || error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
        }
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  const handleUpperChange = (values: string) => {
    formik.setFieldValue("rfc", values.toUpperCase());
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center" color="#002652">
            Registro de empresa
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={3} />
          </Container>

          <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3}>
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} textAlign="center" color="#002652">
              Ingresa los datos de la empresa
            </Typography>
          </Box>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <FormControl fullWidth>
                <TextField
                  id="rfc"
                  name="rfc"
                  label="RFC de la empresa"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={(e) => handleUpperChange(e.target.value)}
                  value={formik.values.rfc}
                  error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                  helperText={formik.touched.rfc ? formik.errors.rfc : ""}
                  inputProps={{ maxLength: 12 }}
                  disabled={formik.isSubmitting || companyData.is_correct_ciec}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label="Nombre comercial"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                  inputProps={{ maxLength: 40 }}
                  disabled={formik.isSubmitting}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  id="legal_name"
                  name="legal_name"
                  label="Razón social"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.legal_name}
                  error={formik.touched.legal_name && Boolean(formik.errors.legal_name)}
                  helperText={formik.touched.legal_name ? formik.errors.legal_name : ""}
                  inputProps={{ maxLength: 40 }}
                  disabled={formik.isSubmitting}
                />
              </FormControl>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DatosEmpresaPm;
