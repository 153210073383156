/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { usePostValidateNextStageMutation } from "@api/applications";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useFormik } from "formik";
import * as yup from "yup";
import img from "@assets/img/img_digital_right.svg";
import { ProgressBar } from "@components/ProgressBar";
import { usePatchSocialMediaDataMutation, usePostSocialMediaMutation } from "@api/socialMediaApi";
import { WEP_PAGE } from "@utils/expreciones";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { useEffect, useState } from "react";
import { setLoanStatus } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";

const PresenciaDigitalPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const dispatch = useAppDispatch();
  const [postSocialMedia, { isLoading }] = usePostSocialMediaMutation();
  const [patchMedia] = usePatchSocialMediaDataMutation();
  const [idMedia, _setIdMedia] = useState("");

  const application = useSelector((state: RootState) => state.register.application);
  const person = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const formik = useFormik({
    initialValues: {
      has_web: "",
      pagina_web: "",
      has_solcial_media: "",
      linkin: "",
      face: "",
      insta: "",
      x_twttiter: "",
    },
    validationSchema: yup.object().shape({
      has_web: yup.boolean().required("Campo requerido"),
      pagina_web: yup.string().when("has_web", {
        is: true,
        then: (schema) => schema.required("Campo requerido").matches(WEP_PAGE, "URL no válida"),
      }),
      has_solcial_media: yup.boolean().required("Campo requerido"),
      linkin: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "linkin",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),

      face: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "face",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),
      insta: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "insta",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),

      x_twttiter: yup.string().when("has_solcial_media", (hasSocialMedia, schema) => {
        if (hasSocialMedia[0]) {
          return schema.matches(WEP_PAGE, "URL no válida").test({
            name: "x_twttiter",
            message: "Debes completar al menos una red social con una URL válida",
            test: function () {
              const { linkin, face, insta, x_twttiter } = this.parent;
              return Boolean(linkin || face || insta || x_twttiter);
            },
          });
        }
        return yup.string();
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);
          const data = {
            has_web_page: Boolean(values.has_web),
            web_page: values.pagina_web,
            has_social_media: Boolean(values.has_solcial_media),
            linkedin: values.linkin || "",
            facebook: values.face || "",
            instagram: values.insta || "",
            x_twitter: values.x_twttiter || "",
          };
          const next_status = STATUS_CATALOG.consulta_buro_pfae.code;
          const next_url = STATUS_CATALOG.consulta_buro_pfae.url;

          if (idMedia !== "" && idMedia !== undefined) {
            await patchMedia({ client_details_id: String(person.id), data, social_media_id: idMedia }).unwrap();
          } else {
            await postSocialMedia({ client_details_id: String(person.id), data }).unwrap();

            await triggerPostNextStage({
              applicationId: application.id,
              statusCode: next_status,
              username: `TOFU - ${accountUser.email}`,
            }).unwrap();

            dispatch(setLoanStatus(next_status));
          }
          navigate(next_url);
        } catch (error: any) {
          if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    if (!formik.values.has_web) {
      formik.setFieldValue("pagina_web", "");
    }
  }, [formik.values.has_web]);

  useEffect(() => {
    if (!formik.values.has_solcial_media) {
      formik.resetForm({
        values: {
          ...formik.values,
          linkin: "",
          face: "",
          insta: "",
          x_twttiter: "",
        },
      });
    }
  }, [formik.values.has_solcial_media]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center" color="#002652">
              Presencia digital
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={7} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} align="center" color="#002652">
                Conocer la información de los canales digitales de tu negocio nos permite mejorar tu oferta
                personalizada
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <FormControl fullWidth>
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                    ¿Tu negocio cuenta con página web?
                  </Typography>

                  <RadioGroup
                    row
                    aria-labelledby="tienes_web"
                    name="has_web"
                    sx={{ mt: 1 }}
                    value={formik.values.has_web.toString()}
                    onChange={(event) => formik.setFieldValue("has_web", event.target.value === "true")}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Si" disabled={isLoading} />

                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                      sx={{ mx: 1 }}
                      disabled={isLoading}
                    />
                  </RadioGroup>
                </FormControl>

                {formik.values.has_web && (
                  <FormControl fullWidth>
                    <TextField
                      id="pagina_web"
                      name="pagina_web"
                      label="Página web"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.pagina_web && Boolean(formik.errors.pagina_web)}
                      helperText={formik.touched.pagina_web && formik.errors.pagina_web}
                      value={formik.values.pagina_web}
                      disabled={isLoading}
                    />
                  </FormControl>
                )}

                <Box>
                  <FormControl>
                    <Typography variant={isMobileOrTablet ? "body2" : "body1"} color="#002652">
                      ¿Tu negocio cuenta con redes sociales?
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="tienes_redes"
                      name="has_solcial_media"
                      sx={{ marginTop: "1em" }}
                      value={formik.values.has_solcial_media.toString()}
                      onChange={(event) => formik.setFieldValue("has_solcial_media", event.target.value === "true")}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Si" disabled={isLoading} />

                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                        sx={{ mx: 1 }}
                        disabled={isLoading}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {formik.values.has_solcial_media && (
                  <>
                    <FormControl fullWidth>
                      <TextField
                        id="linkin"
                        name="linkin"
                        label="LinkedIn"
                        margin="normal"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.linkin && Boolean(formik.errors.linkin)}
                        helperText={formik.touched.linkin && formik.errors.linkin}
                        value={formik.values.linkin}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="face"
                        name="face"
                        label="Facebook"
                        margin="normal"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.face && Boolean(formik.errors.face)}
                        helperText={formik.touched.face && formik.errors.face}
                        value={formik.values.face}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="insta"
                        name="insta"
                        label="Instagram"
                        margin="normal"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.insta && Boolean(formik.errors.insta)}
                        helperText={formik.touched.insta && formik.errors.insta}
                        value={formik.values.insta}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="x_twttiter"
                        name="x_twttiter"
                        label="X (Twitter)"
                        margin="normal"
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.x_twttiter && Boolean(formik.errors.x_twttiter)}
                        helperText={formik.touched.x_twttiter && formik.errors.x_twttiter}
                        value={formik.values.x_twttiter}
                        disabled={isLoading}
                      />
                    </FormControl>
                  </>
                )}

                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                  <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PresenciaDigitalPfae;
