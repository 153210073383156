/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { RootState } from "@store/store";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { setCompanySupplierReference } from "@store/slices/bofuSlice";
import { useGetClientsSuppliersByRfcQuery } from "@api/proveedorClienteApi";
import { customStylesAsterisk, customSelect } from "@components/customStylesInputs/inputsStyles";
import { usePatchReferencesMutation, usePostReferencesMutation } from "@api/referenciasApi";
import { useGetReferenceTypesQuery } from "@api/catalogs";
import img from "@assets/img/img_tofu/img_tofu_13.svg";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { getOrdinalText } from "@helpers/funciones";

export const ReferenciasProveedores = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const client = useSelector((state: RootState) => state.register.personal_data);
  const companyData = useSelector((state: RootState) => state.bofu.digital_file.company);
  const company = useSelector((state: RootState) => state.register.company);

  const [triggerPostReferences] = usePostReferencesMutation();
  const [triggerPatchReferences] = usePatchReferencesMutation();
  const supplierReferences = useGetClientsSuppliersByRfcQuery(String(company?.rfc));
  const proveedorReferenceType = useGetReferenceTypesQuery();
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const initialValues = {
    referencia: [
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
    ],
  };

  const validationSchema = yup.object().shape({
    referencia: yup.array().of(
      yup.object().shape({
        country_code: yup.string().required("Campo requerido"),
        phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
        proveedor: yup.string().required("Campo requerido"),
        email: yup
          .string()
          .required("Campo requerido")
          .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      }),
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!formik.isValid) {
          return;
        }

        setSubmitting(true);
        const reference_type = proveedorReferenceType.data?.find((item) => item.code === "CRTPR");
        const has_references = companyData.references.suppliers.length !== 0;

        for (let index = 0; index < values.referencia.length; index++) {
          const reference = values.referencia[index];
          const supplierItem = supplierReferences?.data?.data?.suppliers?.find(
            (supplier) => supplier.rfc === reference.proveedor,
          );
          let response = undefined;

          const referenceData = {
            client_details_id: client.id,
            email: reference.email,
            id_ext: "4444",
            phone_number: reference.phone,
            reference_type_id: reference_type?.id,
            code_phone_number: reference.country_code,
            supplier_name: supplierItem?.name,
            supplier_rfc: supplierItem?.rfc,
          };

          if (has_references) {
            response = await triggerPatchReferences({
              referenceId: companyData.references.suppliers.at(index)?.id,
              body: referenceData,
            }).unwrap();
          } else {
            response = await triggerPostReferences(referenceData).unwrap();
          }

          dispatch(
            setCompanySupplierReference({
              clientNumber: index,
              reference: {
                id: response.data.id,
                client_id: reference.proveedor,
                email: reference.email,
                code_phone: reference.country_code,
                phone_number: reference.phone,
              },
            }),
          );
        }

        const data = {
          references_completed: true,
        };

        await putAapplicationCompleted({ id_sol: applicationData.id, body: data });

        navigate("/producto/creditosimple/avance_expediente_digital");

        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (error: any) {
        if (error?.status === 500 || error?.status === 409) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onChangeEmail = (index: number, value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue(`referencia[${index}].email`, low);
  };

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.referencia?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  useEffect(() => {
    if (supplierReferences.isSuccess && companyData.references.suppliers.length !== 0) {
      formik.setFieldValue(
        "referencia",
        companyData.references.suppliers.map((item) => {
          return {
            country_code: item.code_phone,
            phone: item.phone_number,
            email: item.email,
            proveedor: item.client_id,
          };
        }),
      );
    }
  }, [supplierReferences, companyData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={800} color={"#002652"}>
                Referencias
              </Typography>
            </Box>

            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={400} color={"#002652"}>
                <span style={{ fontWeight: 800 }}>2</span> de 2
              </Typography>
            </Box>

            <Box my={1}>
              <Typography textAlign={"center"} fontSize={"14px"} fontWeight={600} color={"#528CD6"}>
                Ahora, escribe 3 referencias que sean tus proveedores, considera que podríamos contactarlos.
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                {formik.values.referencia.map((_, index) => (
                  <div key={index}>
                    <Box my={2} mb={2}>
                      <Typography textAlign={"left"} fontSize={"14px"} fontWeight={400} color={"#002652"}>
                        {getOrdinalText(index + 1)} referencia
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        ...customSelect,
                        mt: 1,
                      }}
                      error={Boolean(
                        formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                      )}
                    >
                      <InputLabel id={`${index}proveedor`}>Proveedores</InputLabel>
                      <Select
                        labelId="proveedor"
                        name={`referencia[${index}].proveedor`}
                        id={`proveedor${index}`}
                        fullWidth
                        required
                        label="Proveedores"
                        value={formik.values.referencia?.[index]?.proveedor}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                        )}
                        disabled={formik.isSubmitting}
                      >
                        <MenuItem value="">Selecciona</MenuItem>
                        {supplierReferences.isSuccess &&
                          supplierReferences?.data?.data?.suppliers
                            ?.filter(
                              (supplier) =>
                                !formik.values.referencia.some(
                                  (ref, refIndex) => refIndex !== index && ref.proveedor === supplier.rfc,
                                ),
                            )
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((supplier) => (
                              <MenuItem key={supplier.rfc} value={supplier.rfc}>
                                {supplier.name.toUpperCase()}
                              </MenuItem>
                            ))}
                      </Select>

                      {formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index) && (
                        <FormHelperText>{getFieldError("proveedor", index)}</FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      name={`referencia[${index}].email`}
                      id={`email${index}`}
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => onChangeEmail(index, e.target.value)}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.touched.referencia?.[index]?.email && getFieldError("email", index))}
                      helperText={formik.touched.referencia?.[index]?.email && getFieldError("email", index)}
                      value={formik.values.referencia?.[index]?.email}
                      disabled={formik.isSubmitting}
                    />

                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomContryCode
                          label="Código"
                          name={`referencia[${index}].country_code`}
                          value={formik.values.referencia?.[index]?.country_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.referencia?.[index]?.country_code && getFieldError("country_code", index),
                          )}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          name={`referencia[${index}].phone`}
                          id={`phone${index}`}
                          type="text"
                          variant="outlined"
                          label="Teléfono"
                          fullWidth
                          required
                          value={formik.values.referencia?.[index]?.phone}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `referencia[${index}].phone`,
                              event.target.value.replace(/[^0-9]/g, ""),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.touched.referencia?.[index]?.phone && getFieldError("phone", index))}
                          helperText={formik.touched.referencia?.[index]?.phone && getFieldError("phone", index)}
                          inputProps={{
                            maxLength: 10,
                            inputMode: "numeric",
                          }}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <Grid container spacing={1} my={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="blue_outlined"
                      type="button"
                      fullWidth
                      onClick={() => {
                        navigate("/producto/creditosimple/avance_expediente_digital");
                      }}
                    >
                      Regresar al inicio
                    </Button>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
