import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import MainLayout from "../../../layouts/MainLayout";

import CrearCuenta from "@pages/producto/credito_simple/tofu/pm/CrearCuenta";
import RegistroDatosPersonales from "@pages/producto/credito_simple/tofu/pm/RegistroDatosPersonales";
import ValidacionTelefono from "@pages/producto/credito_simple/tofu/pm/ValidacionTelefono";
import DatosEmpresaPm from "@pages/producto/credito_simple/tofu/pm/DatosEmpresaPm";
import ConsultaCiec from "@pages/producto/credito_simple/tofu/pm/ConsultaCiec";
import UsoDeCredito from "@pages/producto/credito_simple/tofu/pm/UsoDeCredito";
import PresenciaDigitalPm from "@pages/producto/credito_simple/tofu/pm/PresenciaDigitalPm";
import DatosAccionista from "@pages/producto/credito_simple/tofu/pm/DatosAccionista";
import RfcCurp from "@pages/producto/credito_simple/tofu/pm/RfcCurp";
import DomicilioAccionista from "@pages/producto/credito_simple/tofu/pm/DomicilioAccionista";
import ConsultaBuro from "@pages/producto/credito_simple/tofu/pm/ConsultaBuro";
import Oferta from "@pages/producto/credito_simple/tofu/pm/Oferta";
import Rechazo from "@pages/producto/credito_simple/tofu/pm/Rechazo";
import WaitingRoom from "@pages/producto/credito_simple/tofu/pm/WaitingRoom";
import DatosEmpresaPfae from "@pages/producto/credito_simple/tofu/pfae/DatosEmpresaPfae";
import ConsultaBuroPfae from "@pages/producto/credito_simple/tofu/pfae/ConsultaBuroPfae";
import DomicilioPfae from "@pages/producto/credito_simple/tofu/pfae/DomicilioPfae";
import PresenciaDigitalPfae from "@pages/producto/credito_simple/tofu/pfae/PresenciaDigitalPfae";

import {
  ListaRevision,
  DocsEmpresa,
  InformacionAval,
  InformacionBancaria,
  ReferenciasClientes,
  ReferenciasProveedores,
  DocsAval,
  ExpedienteDigitalCompleto,
  SalidaTemporal,
  InfoDocumentosPFAE,
  AvanceExpedienteDigital,
  SolicitudCreditoPart1,
  SolicitudCreditoPart3,
  PropietarioRealPM,
  DatosPropietarioRealPart1,
  DatosPropietarioRealPart2,
  InformacionAvalP2,
  AgendaVisitaOcularPM,
  InformacionTransaccional,
  ValidandoExpediente,
} from "@pages/producto/credito_simple/bofu/pm";

import {
  ReferenciasClientesPfae,
  ReferenciasProveedoresPfae,
  ValidarIndentidadPfae,
  AvanceExpedienteDigitalPFAE,
  SolicitudCreditoPart1PFAE,
  SolicitudCreditoPart2PFAE,
  SolicitudCreditoPart3PFAE,
  AgendaVisitaOcularPFAE,
  ValidandoExpedientePFAE,
  InformacionBancariaPfae,
} from "@pages/producto/credito_simple/bofu/pfae";
import ConfirmacionDomicilio from "@pages/producto/credito_simple/bofu/pm/ConfirmacionDomicilio";
import ValidacionIdentidad from "@pages/producto/credito_simple/bofu/pm/ValidacionIdentidadPersona";

import { ConfirmacionVisitaAgendada, RechazoManual, AgendaVisita, FirmaDocumentos } from "./bofu";
import { RootState } from "@store/store";
import { TAX_SYSTEMS, STATUS_TOFU_BY_SCREEN_PFAE, STATUS_TOFU_BY_SCREEN_PM } from "@helpers/constantes";
import NotFound from "@pages/NotFound";
import ProtectRouteByStatus from "@routes/ProtectRouteByStatus";

export const CreditoSimple = () => {
  const appData = useSelector((state: RootState) => state.app);
  const application = useSelector((state: RootState) => state.register.application);
  const flow_config = useSelector((state: RootState) => state.register.flow_config);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate to={appData.process_page} />} />

        <Route path="crear_cuenta" element={<CrearCuenta />} />

        {flow_config.is_tofu && application.type === TAX_SYSTEMS.PFAE.code && (
          <React.Fragment>
            <Route
              path="registro_datos_personales"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.registro_datos_personales}>
                  <RegistroDatosPersonales />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="validacion_telefono"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.validacion_telefono}>
                  <ValidacionTelefono />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="datos_empresa_pfae"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.datos_empresa_pfae}>
                  <DatosEmpresaPfae />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="domicilio_pfae"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.domicilio_pfae}>
                  <DomicilioPfae />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="consulta_ciec"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.consulta_ciec}>
                  <ConsultaCiec />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="uso_de_credito"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.uso_de_credito}>
                  <UsoDeCredito />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="presencia_digital_pfae"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.presencia_digital_pfae}>
                  <PresenciaDigitalPfae />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="consulta_buro_pfae"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.consulta_buro_pfae}>
                  <ConsultaBuroPfae />
                </ProtectRouteByStatus>
              }
            />
          </React.Fragment>
        )}

        {flow_config.is_tofu && application.type === TAX_SYSTEMS.PM.code && (
          <React.Fragment>
            <Route
              path="registro_datos_personales"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.registro_datos_personales}>
                  <RegistroDatosPersonales />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="validacion_telefono"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.validacion_telefono}>
                  <ValidacionTelefono />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="datos_empresa_pm"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.datos_empresa_pm}>
                  <DatosEmpresaPm />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="consulta_ciec"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.consulta_ciec}>
                  <ConsultaCiec />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="uso_de_credito"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.uso_de_credito}>
                  <UsoDeCredito />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="presencia_digital"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.presencia_digital}>
                  <PresenciaDigitalPm />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="datos_accionista"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.datos_accionista}>
                  <DatosAccionista />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="rfc_curp"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.rfc_curp}>
                  <RfcCurp />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="domicilio_accionista"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.domicilio_accionista}>
                  <DomicilioAccionista />
                </ProtectRouteByStatus>
              }
            />
            <Route
              path="consulta_buro"
              element={
                <ProtectRouteByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.consulta_buro}>
                  <ConsultaBuro />
                </ProtectRouteByStatus>
              }
            />
          </React.Fragment>
        )}

        {flow_config.is_waiting_room && (
          <React.Fragment>
            <Route path="waiting_room" element={<WaitingRoom />} />
          </React.Fragment>
        )}

        <Route path="credito_rechazado" element={<Rechazo />} />
        <Route path="oferta" element={<Oferta />} />

        {flow_config.is_bofu && (
          <React.Fragment>
            {application.type === TAX_SYSTEMS.PFAE.code && (
              <React.Fragment>
                <Route path="informacion_documentos_pfae" element={<InfoDocumentosPFAE />} />
                <Route path="avance_expediente_digitalPFAE" element={<AvanceExpedienteDigitalPFAE />} />
                <Route path="solicitud_credito_1PFAE" element={<SolicitudCreditoPart1PFAE />} />
                <Route path="solicitud_credito_2PFAE" element={<SolicitudCreditoPart2PFAE />} />
                <Route path="solicitud_credito_3PFAE" element={<SolicitudCreditoPart3PFAE />} />
                <Route path="referencias_clientes_pfae" element={<ReferenciasClientesPfae />} />
                <Route path="referencias_proveedores_pfae" element={<ReferenciasProveedoresPfae />} />
                <Route path="info_bancaria_pfae" element={<InformacionBancariaPfae />} />
                <Route path="agenda_visita_pfae" element={<AgendaVisitaOcularPFAE />} />
                <Route path="validacion_identidad_pfae" element={<ValidarIndentidadPfae />} />
                <Route
                  path="validando_expediente_pfae"
                  element={<ValidandoExpedientePFAE isValidExpediente={true} />}
                />
                <Route path="errores_expediente_pfae" element={<ValidandoExpedientePFAE isValidExpediente={false} />} />
              </React.Fragment>
            )}

            {application.type === TAX_SYSTEMS.PM.code && (
              <React.Fragment>
                <Route path="check_list" element={<ListaRevision />} />
                <Route path="errores_expediente_pm" element={<ValidandoExpediente isValidExpediente={false} />} />
                <Route path="validando_expediente_pm" element={<ValidandoExpediente isValidExpediente={true} />} />
                <Route path="docs_empresa" element={<DocsEmpresa />} />
                <Route path="avance_expediente_digital" element={<AvanceExpedienteDigital />} />
                <Route path="solicitud_credito_1" element={<SolicitudCreditoPart1 />} />
                <Route path="solicitud_credito_2" element={<InformacionTransaccional />} />
                <Route path="solicitud_credito_3" element={<SolicitudCreditoPart3 />} />
                <Route path="referencias_clientes" element={<ReferenciasClientes />} />
                <Route path="referencias_proveedores" element={<ReferenciasProveedores />} />
                <Route path="info_bancaria_empresa" element={<InformacionBancaria type="EMPRESA" />} />
                <Route path="validacion_representante_legal" element={<ValidacionIdentidad person="REP_LEGAL" />} />
                <Route path="agenda_visita_pm" element={<AgendaVisitaOcularPM />} />
                <Route path="propietario_real_pm" element={<PropietarioRealPM />} />
                <Route path="datos_propietario_real_1" element={<DatosPropietarioRealPart1 />} />
                <Route path="datos_propietario_real_2" element={<DatosPropietarioRealPart2 />} />
                <Route path="docs_aval" element={<DocsAval />} />
                <Route path="informacion_datos_aval" element={<InformacionAval />} />
                <Route path="informacion_datos_aval_2" element={<InformacionAvalP2 />} />
                <Route path="info_bancaria_aval" element={<InformacionBancaria type="AVAL" />} />
                <Route path="validacion_identidad_aval" element={<ValidacionIdentidad person="AVAL" />} />
              </React.Fragment>
            )}

            <Route path="completado" element={<ExpedienteDigitalCompleto />} />
            <Route path="agenda_visita" element={<AgendaVisita />} />
            <Route path="confirmacion_domicilio" element={<ConfirmacionDomicilio />} />
            <Route path="confirmacion_visita_agendada" element={<ConfirmacionVisitaAgendada />} />
            <Route path="visita_ocular_concluida" element={<SalidaTemporal type="ConfVisOcCon" />} />
            <Route path="firma_documentos" element={<FirmaDocumentos />} />
            <Route path="bofu_completado" element={<SalidaTemporal type="ConfBofuComp" />} />
            <Route path="rechazado_documentacion" element={<RechazoManual />} />
          </React.Fragment>
        )}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
