import { Box, IconButton, Modal, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";

const sxModalBox: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  outline: "none",
};

const sxIconButton: SxProps = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "#A3D4E8",
};

export type ModalType =
  | "dictamen_not_available"
  | "company_not_available"
  | "solicitud_credito"
  | "identity_validation_aval"
  | "beneficial_owner_not_available";

export interface ModalExpedienteProps {
  type: ModalType;
  openModal: boolean;
  closeModal: () => void;
}

interface ModalTextParams {
  title: string;
  message: string;
  message_2?: string;
  message_3?: string;
}

export const ModalExpediente: React.FC<ModalExpedienteProps> = (props) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [params, setParams] = React.useState<ModalTextParams>({
    title: "",
    message: "",
  });

  useEffect(() => {
    if (props.type === "company_not_available") {
      setParams({
        title: "Sección empresa no disponible",
        message:
          "La sección empresa, estará disponible una vez que se encuentren cargados los documentos del dictamen.",
      });
    } else if (props.type === "solicitud_credito") {
      setParams({
        title: "Sección no disponible",
        message: "La sección de Solicitud de credito, estará disponible una vez que se encuentre validado el dictamen",
      });
    } else if (props.type === "identity_validation_aval") {
      setParams({
        title: "Sección no disponible",
        message:
          "La sección de Validación de identidad, estará disponible una vez que se encuentre validado el dictamen",
      });
    } else if (props.type === "dictamen_not_available") {
      setParams({
        title: "Sección no disponible",
        message:
          "Estamos realizando el dictamen jurídico de tu empresa para identificar al accionista mayoritario y a los propietarios reales.",
        message_2: "Las siguientes secciones del expediente se habilitarán una vez que este proceso se haya concluido.",
        message_3: "Te notificaremos tan pronto como el dictamen esté completo.",
      });
    } else if (props.type === "beneficial_owner_not_available") {
      setParams({
        title: "Sección de propietario real no disponible",
        message:
          "La sección de propietario real, no se encuentra disponible ya que la empresa no cuenta con ningún propietario real adicional al aval.",
      });
    }
  }, []);

  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <Box sx={sxModalBox}>
        <IconButton aria-label="close" onClick={props.closeModal} sx={sxIconButton}>
          <CloseIcon />
        </IconButton>
        <Typography color={"#528CD6"} fontWeight={600} fontSize={"17px"}>
          {params.title}
        </Typography>

        <Box mt={2}>
          <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"}>
            {params.message}
          </Typography>

          {params.message_2 && (
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} mt={2}>
              {params.message_2}
            </Typography>
          )}

          {params.message_3 && (
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} mt={2}>
              {params.message_3}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
