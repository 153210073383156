/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from "react";
import { Box, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import imgDictamen from "@assets/img/iconTap/dicatamen.svg";
import imgEmpresa from "@assets/img/iconTap/empresa.svg";
import imgAval from "@assets/img/iconTap/aval.svg";
import imgProp from "@assets/img/iconTap/propietario.svg";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useGetApplicationByApplicationIdQuery } from "@api/applications";
import { useLazyGetPersonsQuery } from "@api/personasApi";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckIcon from "@mui/icons-material/Check";
import { DocumentResponse, PersonaStructure } from "@interfaces/index";
import { DOCUMENTS_CATALOG, PERSON_CODES_AVAL, PERSON_CODES_PROPIETARIO_REAL } from "@helpers/constantes";
import CustomButtonTabExpediente from "@components/CustomButtonTabExpediente";
import { ModalExpediente } from "@components/CustomModalExpediente";

interface DocumentStatusResult {
  icon?: ReactNode;
  label?: string;
  color?: string;
}

const validao: DocumentStatusResult = {
  icon: <CheckIcon style={{ color: "#01A388", fontSize: "22px", margin: "0px" }} />,
  label: "Validado",
  color: "#01A388",
};

const cargado: DocumentStatusResult = {
  icon: <CheckIcon style={{ color: "#A3D4E8", fontSize: "22px", margin: "0px" }} />,
  label: "Cargado",
  color: "#A3D4E8",
};

const faltantes: DocumentStatusResult = {
  icon: <WarningAmberIcon style={{ color: "#F2704F", fontSize: "22px", margin: "0px" }} />,
  label: "Faltantes",
  color: "#F2704F",
};

interface OwnerDocs {
  id_propietario: string;
  file_anexo_2?: DocumentResponse | null;
  file_identificacion_oficial?: DocumentResponse | null;
  file_constancia_situacion_fiscal?: DocumentResponse | null;
}

export const TabStepBofuPm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [dictamenComplete, setDictamenComplete] = useState<any>(false);
  const [documentsDictamenUploaded, setDocumentsDictamenUploaded] = useState<boolean>(false);
  const [dictamenProps, setDictamenProps] = useState<DocumentStatusResult | null>();
  const [solicitudProps, setSolicitudProps] = useState<DocumentStatusResult | null>(null);
  const [avalProps, setAvalProps] = useState<DocumentStatusResult | null>(null);
  const [propietarioProps, setPropietarioProps] = useState<DocumentStatusResult | null>(null);
  const [listPropietarios, setListPropietarios] = useState<PersonaStructure[]>([]);
  const [isSuccessAval, setIsSuccessAval] = useState<boolean>(false);
  const [isSuccessPropietario, setIsSuccessPropietario] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"dictamen" | "empresa" | "aval" | "propietario">("dictamen");
  const [showModalCompanyNotAvailable, setShowModalCompanyNotAvailable] = useState(false);
  const [showModalDictamenNotAvailable, setShowModalDictamenNotAvailable] = useState(false);
  const [showModalBeneficialOwnerNotAvailable, setShowModalBeneficialOwnerNotAvailable] = useState(false);

  const applicationData = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [getDocumentsByAppId, { data: dataDocuments }] = useLazyGetDocumentAppIdQuery();
  const [getDocumentsAval] = useLazyGetDocumentAppIdQuery();
  const [getDocumentsPropietario] = useLazyGetDocumentAppIdQuery();
  const [getPersonsByCode] = useLazyGetPersonsQuery();
  const { data: appData } = useGetApplicationByApplicationIdQuery(applicationData.id);

  const handleButtonClick = (seccion: string) => {
    if (seccion === "empresa") {
      if (!documentsDictamenUploaded) {
        setShowModalCompanyNotAvailable(true);
        return;
      }
    } else if (seccion === "aval") {
      if (!dictamenComplete) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
    } else if (seccion === "propietario") {
      if (!dictamenComplete) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
      if (listPropietarios.length === 0) {
        setShowModalBeneficialOwnerNotAvailable(true);
        return;
      }
    }
  };

  useEffect(() => {
    const validateActiveTab = () => {
      switch (location.pathname) {
        case "/producto/creditosimple/docs_empresa":
          setActiveTab("dictamen");
          break;
        case "/producto/creditosimple/avance_expediente_digital":
          setActiveTab("empresa");
          break;
        case "/producto/creditosimple/docs_aval":
          setActiveTab("aval");
          break;
        case "/producto/creditosimple/propietario_real_pm":
          setActiveTab("propietario");
          break;
      }
    };

    validateActiveTab();
  }, [location]);

  // generar tab activo
  useEffect(() => {
    getDocumentsByAppId({ clientDetailsId: clientDetails.id, loanApplicationId: applicationData.id });
  }, [clientDetails, applicationData]);

  // redirecciones
  useEffect(() => {
    if (isSuccessAval && isSuccessPropietario) {
      if (location.pathname === "/producto/creditosimple/docs_aval") {
        if (!dictamenComplete) {
          navigate("/producto/creditosimple/docs_empresa");
        }
      } else if (location.pathname === "/producto/creditosimple/propietario_real_pm") {
        if (!dictamenComplete || listPropietarios.length === 0) {
          navigate("/producto/creditosimple/docs_empresa");
        }
      }
    }
  }, [isSuccessAval, isSuccessPropietario, dictamenComplete, listPropietarios, location]);

  // tab dictamen
  useEffect(() => {
    if (dataDocuments && appData) {
      const { acta_constitutiva, identificacion_representante_legal, acta_adicional } = DOCUMENTS_CATALOG;

      const doc_acta_constitutiva = dataDocuments.find(
        (item) => item.file_code === acta_constitutiva.code && item.status,
      );
      const doc_identificacion_representante = dataDocuments.find(
        (item) => item.file_code === identificacion_representante_legal.code && item.status,
      );
      const doc_acta_adicional = dataDocuments.filter((item) => item.file_code === acta_adicional.code && item.status);

      if (!doc_acta_constitutiva || !doc_identificacion_representante) {
        return;
      }

      const valid_dictamen: boolean = appData?.data[0]?.decision_validated;
      setDictamenComplete(valid_dictamen);

      if (valid_dictamen === true) {
        setDictamenProps(validao);
      } else if (valid_dictamen === null) {
        if (doc_acta_adicional.length > 0) {
          if (doc_acta_constitutiva && doc_identificacion_representante) {
            setDictamenProps(cargado);
          }
        } else {
          if (doc_acta_constitutiva && doc_identificacion_representante) {
            setDictamenProps(cargado);
          }
        }
      } else if (valid_dictamen === false) {
        setDictamenProps(faltantes);
      }
    }
  }, [dataDocuments, appData]);

  // tab empresa
  useEffect(() => {
    if (appData && dataDocuments) {
      const loan_application = appData.data[0];

      const document_solicitud = dataDocuments.find(
        (file) => file.file_code === DOCUMENTS_CATALOG.solicitud_credito.code && file.status,
      );
      const document_comprobante_domicilio = dataDocuments.find(
        (file) => file.file_code === DOCUMENTS_CATALOG.comprobante_domicilio.code && file.status,
      );
      const document_acta_constitutiva = dataDocuments.find(
        (item) => item.file_code === DOCUMENTS_CATALOG.acta_constitutiva.code && item.status,
      );
      const document_identificacion_representante_legal = dataDocuments.find(
        (item) => item.file_code === DOCUMENTS_CATALOG.identificacion_representante_legal.code && item.status,
      );

      const documents_dictamen_uploaded =
        document_acta_constitutiva && document_identificacion_representante_legal ? true : false;
      setDocumentsDictamenUploaded(documents_dictamen_uploaded);

      const allDocsValid = [document_solicitud, document_comprobante_domicilio].every((doc) => doc?.validated === true);
      const someInvalid = [document_solicitud, document_comprobante_domicilio].some((doc) => doc?.validated === false);
      const allDocsNullOrTrue = [document_solicitud, document_comprobante_domicilio].every(
        (doc) => doc?.validated === null || doc?.validated === true,
      );

      if (loan_application?.completed) {
        setSolicitudProps(validao);
      } else {
        if (document_solicitud && document_comprobante_domicilio) {
          if (allDocsValid) {
            setSolicitudProps(validao);
          } else if (someInvalid) {
            setSolicitudProps(faltantes);
          } else if (allDocsNullOrTrue) {
            if (
              loan_application?.identity_validation_completed &&
              loan_application?.bank_information_completed &&
              loan_application?.references_completed &&
              loan_application?.loan_application_completed
            ) {
              setSolicitudProps(cargado);
            }
          }
        }
      }
    }
  }, [dataDocuments, appData]);

  // tab aval
  useEffect(() => {
    (async () => {
      try {
        setIsSuccessAval(false);
        const avales = await getPersonsByCode({
          loanApplicationId: applicationData.id,
          personTypeCode: [...PERSON_CODES_AVAL],
        }).unwrap();

        const person = avales[0];
        if (!person) return;

        const documents_aval = await getDocumentsAval({
          loanApplicationId: applicationData.id,
          personId: person.id,
          status: true,
        }).unwrap();

        const { constancia_situacion_fiscal, comprobante_domicilio, anexo_1 } = DOCUMENTS_CATALOG;

        const doc_constancia_situacion_fiscal = documents_aval.find(
          (item) => item.file_code === constancia_situacion_fiscal.code && item.status,
        );
        const doc_comprobante_domicilio = documents_aval.find(
          (item) => item.file_code === comprobante_domicilio.code && item.status,
        );
        const doc_anexo_1 = documents_aval.find((file) => file.file_code === anexo_1.code && file.status);

        const is_all_docs_uploaded = doc_constancia_situacion_fiscal && doc_comprobante_domicilio;
        const is_all_forms_completed =
          person.information_person_completed &&
          person.identity_validation_completed &&
          person.bank_references_completed;

        const is_all_docs_valid = [doc_anexo_1, doc_comprobante_domicilio, doc_constancia_situacion_fiscal].every(
          (doc) => doc?.validated === true,
        );

        const some_doc_invalid = [doc_anexo_1, doc_comprobante_domicilio, doc_constancia_situacion_fiscal].some(
          (doc) => doc?.validated === false,
        );

        if (is_all_forms_completed && is_all_docs_uploaded) {
          if (is_all_docs_valid) {
            setAvalProps(validao);
          } else if (some_doc_invalid) {
            setAvalProps(faltantes);
          } else {
            setAvalProps(cargado);
          }
        }
      } catch (error) {
        console.log("Ha ocurrido un error al generar el tab de aval", error);
      } finally {
        setIsSuccessAval(true);
      }
    })();
  }, []);

  // tab propietario
  useEffect(() => {
    (async () => {
      try {
        setIsSuccessPropietario(false);

        const getPropietarioDocuments = async (personId: string): Promise<OwnerDocs> => {
          return new Promise(async (resolve) => {
            try {
              const documents_propietario: DocumentResponse[] = await getDocumentsPropietario({
                loanApplicationId: applicationData.id,
                personId: personId,
                status: true,
              }).unwrap();

              const { identificacion_oficial, constancia_situacion_fiscal, anexo_2 } = DOCUMENTS_CATALOG;

              const doc_anexo_2 = documents_propietario.find(
                (item) => item.file_code === anexo_2.code && item.status && item.person_id === personId,
              );
              const doc_identificacion_oficial = documents_propietario.find(
                (item) => item.file_code === identificacion_oficial.code && item.status && item.person_id === personId,
              );
              const doc_constancia_situacion_fiscal = documents_propietario.find(
                (item) =>
                  item.file_code === constancia_situacion_fiscal.code && item.status && item.person_id === personId,
              );

              resolve({
                id_propietario: String(personId),
                file_anexo_2: doc_anexo_2 || null,
                file_identificacion_oficial: doc_identificacion_oficial || null,
                file_constancia_situacion_fiscal: doc_constancia_situacion_fiscal || null,
              });
            } catch (error) {
              console.log("Ha ocurrido un error al generar el tab de propietario", error);
              resolve({
                id_propietario: String(personId),
                file_anexo_2: null,
                file_identificacion_oficial: null,
                file_constancia_situacion_fiscal: null,
              });
            }
          });
        };

        const propietarios: PersonaStructure[] = await getPersonsByCode({
          loanApplicationId: applicationData.id,
          personTypeCode: [...PERSON_CODES_PROPIETARIO_REAL],
        }).unwrap();

        if (!propietarios.length) {
          return;
        }

        const promesas = propietarios.map((owner) => getPropietarioDocuments(owner.id));
        const documentos_propietario: OwnerDocs[] = await Promise.all(promesas);

        const all_identificacion_oficial = documentos_propietario.every(
          (owner) => owner.file_identificacion_oficial !== null,
        );
        const all_doc_identificacion_oficial_valid = documentos_propietario.every(
          (owner) => owner.file_identificacion_oficial?.validated === true,
        );
        const all_constancia_situacion_fiscal = documentos_propietario.every(
          (owner) => owner.file_constancia_situacion_fiscal !== null,
        );
        const all_doc_constancia_situacion_fiscal_valid = documentos_propietario.every(
          (owner) => owner.file_constancia_situacion_fiscal?.validated === true,
        );
        const all_anexo_2 = documentos_propietario.every((owner) => owner.file_anexo_2 !== null);
        const all_doc_anexo_2_valid = documentos_propietario.every((owner) => owner.file_anexo_2?.validated === true);

        const some_doc_invalid = documentos_propietario.some(
          (owner) =>
            owner.file_anexo_2?.validated === false ||
            owner.file_identificacion_oficial?.validated === false ||
            owner.file_constancia_situacion_fiscal?.validated === false,
        );

        const is_all_forms_completed = propietarios.every((owner) => owner.information_person_completed === true);
        const is_all_docs_uploaded = all_identificacion_oficial && all_constancia_situacion_fiscal && all_anexo_2;
        const is_all_docs_valid =
          all_doc_anexo_2_valid && all_doc_identificacion_oficial_valid && all_doc_constancia_situacion_fiscal_valid;

        if (is_all_forms_completed && is_all_docs_uploaded) {
          if (is_all_docs_valid) {
            setPropietarioProps(validao);
          } else if (some_doc_invalid) {
            setPropietarioProps(faltantes);
          } else {
            setPropietarioProps(cargado);
          }
        }

        setListPropietarios(propietarios);
      } catch (error) {
        console.log("Ha ocurrido un error al generar el tab de propietario", error);
      } finally {
        setIsSuccessPropietario(true);
      }
    })();
  }, []);

  return (
    <>
      {isSuccessAval && isSuccessPropietario ? (
        <>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#E0F7FA",
              p: 1,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={3} md={3}>
                <CustomButtonTabExpediente
                  isActive={activeTab === "dictamen"}
                  onClick={() => {
                    navigate("/producto/creditosimple/docs_empresa");
                  }}
                >
                  {dictamenProps ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {dictamenProps.icon}
                        <br />
                        Dictamen
                        <br />
                        <span
                          style={{
                            color: dictamenProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {dictamenProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Dictamen
                      <img
                        src={imgDictamen}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "18px",
                          height: "18px",
                          transition: "filter 0.3s",
                          filter: activeTab === "dictamen" ? "brightness(0) invert(1)" : "",
                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </CustomButtonTabExpediente>
              </Grid>

              <Grid item xs={3} md={3}>
                <CustomButtonTabExpediente
                  isActive={activeTab === "empresa"}
                  onClick={() => navigate("/producto/creditosimple/avance_expediente_digital")}
                  disabled={!documentsDictamenUploaded}
                  onClickDisabled={() => handleButtonClick("empresa")}
                >
                  {solicitudProps ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {solicitudProps.icon}
                        <br />
                        Empresa
                        <br />
                        <span
                          style={{
                            color: solicitudProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {solicitudProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Empresa
                      <img
                        src={imgEmpresa}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "18px",
                          height: "18px",
                          transition: "filter 0.3s",
                          filter: !documentsDictamenUploaded
                            ? "brightness(0) saturate(100%) invert(75%)"
                            : activeTab === "empresa"
                            ? "brightness(0) invert(1)"
                            : "",
                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </CustomButtonTabExpediente>
              </Grid>

              <Grid item xs={3} md={3}>
                <CustomButtonTabExpediente
                  isActive={activeTab === "aval"}
                  onClick={() => navigate("/producto/creditosimple/docs_aval")}
                  disabled={!dictamenComplete}
                  onClickDisabled={() => handleButtonClick("aval")}
                >
                  {avalProps ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {avalProps.icon}
                        <br />
                        Aval
                        <br />
                        <span
                          style={{ color: avalProps.color, fontSize: "9px", margin: 0, padding: 0, fontWeight: 500 }}
                        >
                          {avalProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Aval
                      <img
                        src={imgAval}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          transition: "filter 0.3s",
                          filter: !dictamenComplete
                            ? "brightness(0) saturate(100%) invert(75%)"
                            : activeTab === "aval"
                            ? "brightness(0) invert(1)"
                            : "",
                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "5px" : "0px",
                        }}
                      />
                    </>
                  )}
                </CustomButtonTabExpediente>
              </Grid>

              <Grid item xs={3} md={3}>
                <CustomButtonTabExpediente
                  isActive={activeTab === "propietario"}
                  onClick={() => navigate("/producto/creditosimple/propietario_real_pm")}
                  disabled={!dictamenComplete || listPropietarios.length === 0}
                  onClickDisabled={() => handleButtonClick("propietario")}
                >
                  {propietarioProps ? (
                    <>
                      <span style={{ display: "inline-block", textAlign: "center", lineHeight: "1" }}>
                        {propietarioProps.icon}
                        <br />
                        Propietario
                        <br />
                        <span
                          style={{
                            color: propietarioProps.color,
                            fontSize: "9px",
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                          }}
                        >
                          {propietarioProps.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      Propietario
                      <img
                        src={imgProp}
                        alt="Icono"
                        className="icon"
                        style={{
                          width: "24px",
                          height: "24px",
                          transition: "filter 0.3s",
                          filter:
                            !dictamenComplete || listPropietarios.length === 0
                              ? "brightness(0) saturate(100%) invert(75%)"
                              : activeTab === "propietario"
                              ? "brightness(0) invert(1)"
                              : "",

                          marginLeft: isMobileOrTablet ? "0px" : "10px",
                          marginTop: isMobileOrTablet ? "0px" : "0px",
                        }}
                      />
                    </>
                  )}
                </CustomButtonTabExpediente>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" height={50} />
        </>
      )}

      <ModalExpediente
        type="company_not_available"
        openModal={showModalCompanyNotAvailable}
        closeModal={() => {
          setShowModalCompanyNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="dictamen_not_available"
        openModal={showModalDictamenNotAvailable}
        closeModal={() => {
          setShowModalDictamenNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="beneficial_owner_not_available"
        openModal={showModalBeneficialOwnerNotAvailable}
        closeModal={() => {
          setShowModalBeneficialOwnerNotAvailable(false);
        }}
      />
    </>
  );
};
