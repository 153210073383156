/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import CustomRadioGroup from "@components/CustomRadioGroup";

import {
  useLazyGetPaymentDetailsQuery,
  usePutApplicationCompletedMutation,
  usePutPaymentDetailsMutation,
} from "@api/applications";
import {
  useLazyGetCargoPublicoQuery,
  useLazyGetDependeciasQuery,
  useLazyGetPepsTypeQuery,
  useLazyGetRelationshipQuery,
} from "@api/catalogs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  usePotsPepsDataMutation,
  useLazyGetPepsByPersonQuery,
  usePatchPepsDataMutation,
  useDeletePepsMutation,
} from "@api/pepsApi";

import img from "@assets/img/img_tofu/img_tofu_12.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { CargoPublicoData, DependenciasData, RelationshipData } from "@interfaces/index";
import { InactivityHandler } from "@components/InactivityHandler";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const validationSchema = yup.object({
  socioFuncionario: yup.string().required("Requerido"),
  conyuge: yup.string().required("Requerido"),
  peps_socio: yup.array().of(
    yup.object().shape({
      nombre: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_paterno: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_materno: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      dependencia: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      cargo: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      actualmente: yup
        .string()
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      start_date: yup
        .string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .when("socioFuncionario", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$socioFuncionario", ([socioFuncionario], schema) =>
          socioFuncionario === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      end_date: yup
        .string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .when("actualmente", {
          is: "false",
          then: (schema) =>
            schema
              .required("Campo requerido")
              .test(
                "end_date_greater_than_start_date",
                "La fecha de término no puede ser menor que la fecha de inicio",
                function (value) {
                  const { start_date } = this.parent;
                  if (start_date && value) {
                    return dayjs(value).isSameOrAfter(dayjs(start_date), "day");
                  }
                  return true;
                },
              )
              .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
                if (value) {
                  return dayjs(value).isSameOrBefore(dayjs(), "day");
                }
                return true;
              }),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
  ),
  peps_conyuge: yup.array().of(
    yup.object().shape({
      parentesco_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      nombre_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_paterno_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      ap_materno_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      dependencia_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      cargo_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      actualmente_conyuge: yup
        .string()
        .when("conyuge", {
          is: "true",
          then: (schema) => schema.required("Campo requerido"),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      start_date_conyuge: yup
        .string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .when("conyuge", {
          is: "true",
          then: (schema) =>
            schema
              .required("Campo requerido")
              .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
                if (value) {
                  return dayjs(value).isSameOrBefore(dayjs(), "day");
                }
                return true;
              }),
          otherwise: (schema) => schema.notRequired(),
        })
        .when("$conyuge", ([conyuge], schema) =>
          conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
        ),
      end_date_conyuge: yup
        .string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .when("actualmente_conyuge", {
          is: "false",
          then: (schema) =>
            schema
              .required("Campo requerido")
              .test(
                "end_date_greater_than_start_date",
                "La fecha de término no puede ser menor que la fecha de inicio",
                function (value) {
                  const { start_date_conyuge } = this.parent;
                  if (start_date_conyuge && value) {
                    return dayjs(value).isSameOrAfter(dayjs(start_date_conyuge), "day");
                  }
                  return true;
                },
              )
              .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
                if (value) {
                  return dayjs(value).isSameOrBefore(dayjs(), "day");
                }
                return true;
              }),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
  ),
});

export const SolicitudCreditoPart3: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const applicationData = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);

  const [patchPepsData] = usePatchPepsDataMutation();
  const [deletePeps] = useDeletePepsMutation();

  const [triggerPutPaymentDetails] = usePutPaymentDetailsMutation();
  const [postPeps] = usePotsPepsDataMutation();
  const [getPepsType, { data: dataPeps, isLoading: loadingPeps, isSuccess: isSuccessPepsType }] =
    useLazyGetPepsTypeQuery();
  const [pepsIdSocio, setPepsSocioId] = useState("");
  const [pepsIdFamiliar, setPepsFamiliarId] = useState("");
  const [listDependecias, setListDependecias] = useState<DependenciasData[]>([]);
  const [listCargo, setListCargo] = useState<CargoPublicoData[]>([]);
  const [listFamiliar, setListFamiliar] = useState<RelationshipData[]>([]);
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableAddSocio, setDisableAddSocio] = useState(false);
  const [idDetalle, setIdDetalle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [getDetalles, { data: dataDetalle, isSuccess: isSuccessDetalle }] = useLazyGetPaymentDetailsQuery();

  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const [getPepsByPersonSocio, { data: pepsDataSocio, isSuccess: isSuccessSocio }] = useLazyGetPepsByPersonQuery();
  const [getPepsByPersonConyuge, { data: pepsDataConyuge, isSuccess: isSuccessConyugue }] =
    useLazyGetPepsByPersonQuery();

  const [getFamiliar, { data: dataFamiliar, isLoading: loadingFamiliar, isSuccess: isSuccessFamiliar }] =
    useLazyGetRelationshipQuery();

  const [
    getDependeciasLista,
    { data: dataDependencia, isLoading: loadingDependencia, isSuccess: isSuccessDependence },
  ] = useLazyGetDependeciasQuery();
  const [getCargoPulico, { data: dataCargo, isLoading: loadingCargo, isSuccess: isSuccessCargo }] =
    useLazyGetCargoPublicoQuery();

  const formik = useFormik({
    initialValues: {
      socioFuncionario: "",
      conyuge: "",
      peps_socio: [
        {
          nombre: "",
          ap_paterno: "",
          ap_materno: "",
          dependencia: "",
          cargo: "",
          actualmente: "",
          start_date: "",
          end_date: "",
          id: "",
        },
      ],
      peps_conyuge: [
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
          id: "",
        },
      ],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (idDetalle) {
          await triggerPutPaymentDetails({
            solicitudId: applicationData.id,
            detailId: idDetalle,
            body: {
              pep_shareholder: values.socioFuncionario === "true",
              pep_members: values.conyuge === "true",
            },
          }).unwrap();
        }
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }

      if (values.socioFuncionario === "true") {
        for (const pep of values.peps_socio) {
          const dataPepsSocio = {
            name: `${pep.nombre} ${pep.ap_paterno} ${pep.ap_materno}`,
            position: pep.cargo,
            dependency: pep.dependencia,
            active: pep.actualmente === "true",
            start_date: pep.start_date,
            loan_application_id: applicationData.id,
            pep_type_id: pepsIdSocio,
            end_date: pep.end_date === "" ? null : pep.end_date,
            client_details_id: String(personalData.id),
            relationship_id: null,
          };

          if (pep.id !== "" && pep.id !== undefined) {
            try {
              await patchPepsData({ id: pep.id, body: dataPepsSocio });
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          } else {
            try {
              await postPeps(dataPepsSocio);
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          }
        }
      } else if (values.socioFuncionario === "false") {
        for (const pep of values.peps_socio) {
          if (pep.id !== "" && pep.id !== undefined) {
            try {
              await deletePeps({ id: pep.id }).unwrap();
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          }
        }
      }

      if (values.conyuge === "true") {
        for (const pep of values.peps_conyuge) {
          const dataPepsFamiliar = {
            name: `${pep.nombre_conyuge} ${pep.ap_paterno_conyuge} ${pep.ap_materno_conyuge}`,
            position: pep.cargo_conyuge,
            dependency: pep.dependencia_conyuge,
            active: pep.actualmente_conyuge === "true",
            start_date: pep.start_date_conyuge,
            loan_application_id: applicationData.id,
            pep_type_id: pepsIdFamiliar,
            end_date: pep.end_date_conyuge === "" ? null : pep.end_date_conyuge,
            client_details_id: String(personalData.id),
            relationship_id: pep.parentesco_conyuge,
          };

          if (pep.id !== "" && pep.id !== undefined) {
            try {
              await patchPepsData({ id: pep.id, body: dataPepsFamiliar });
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          } else {
            try {
              await postPeps(dataPepsFamiliar);
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          }
        }
      } else if (values.conyuge === "false") {
        for (const pep of values.peps_conyuge) {
          if (pep.id !== "" && pep.id !== undefined) {
            try {
              await deletePeps({ id: pep.id }).unwrap();
            } catch (error: any) {
              if (error?.status === 500 || error?.status === 403) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
                return;
              } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
                return;
              }
            }
          }
        }
      }

      try {
        const data = {
          loan_application_completed: true,
        };

        await putAapplicationCompleted({ id_sol: applicationData.id, body: data });
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }

      window.location.href = "/producto/creditosimple/avance_expediente_digital";
    },
  });

  const getDependecia = async () => {
    try {
      await getDependeciasLista();
    } catch (error) {
      console.log(error);
    }
  };

  const getCargo = async () => {
    try {
      await getCargoPulico();
    } catch (error) {
      console.log(error);
    }
  };

  const getPeps = async () => {
    try {
      await getPepsType();
    } catch (error) {
      console.log(error);
    }
  };

  const getFamiliares = async () => {
    try {
      await getFamiliar();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getDependecia();
      getCargo();
      getPeps();
      getFamiliares();
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getDetalles({ solicitudId: String(applicationData.id) });
    }, 1000);
  }, [applicationData.id]);

  useEffect(() => {
    if (isSuccessDetalle && dataDetalle) {
      setTimeout(() => {
        formik.setFieldValue("socioFuncionario", `${dataDetalle?.data?.pep_shareholder}`);
        formik.setFieldValue("conyuge", `${dataDetalle?.data?.pep_members}`);
        setIdDetalle(String(dataDetalle?.data?.id));
      }, 800);
    }
  }, [isSuccessDetalle, dataDetalle, idDetalle]);

  useEffect(() => {
    if (dataPeps) {
      const pepsTypeId = dataPeps.find((entity) => entity.name === "Familiar");
      const pepsSocioTypeId = dataPeps.find((entity) => entity.name === "Socio");
      setPepsFamiliarId(String(pepsTypeId?.id));
      setPepsSocioId(String(pepsSocioTypeId?.id));
    }
  }, [dataPeps, loadingPeps]);

  useEffect(() => {
    if (applicationData.client_details_id && pepsIdSocio) {
      setTimeout(() => {
        getPepsByPersonSocio({ personId: applicationData.client_details_id, pepsId: pepsIdSocio });
      }, 1000);
    }
  }, [applicationData.client_details_id, pepsIdSocio]);

  useEffect(() => {
    if (applicationData.client_details_id && pepsIdFamiliar) {
      setTimeout(() => {
        getPepsByPersonConyuge({ personId: applicationData.client_details_id, pepsId: pepsIdFamiliar });
      }, 1000);
    }
  }, [applicationData.client_details_id, pepsIdFamiliar]);

  useEffect(() => {
    if (dataDependencia) {
      setListDependecias(dataDependencia);
    }
  }, [dataDependencia, loadingDependencia]);

  useEffect(() => {
    if (dataCargo) {
      setListCargo(dataCargo);
    }
  }, [dataCargo, loadingCargo]);

  useEffect(() => {
    if (dataFamiliar) {
      setListFamiliar(dataFamiliar);
    }
  }, [dataFamiliar, loadingFamiliar]);

  const handleAddConyuge = () => {
    if (formik.values.peps_conyuge.length < 5) {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
        },
      ]);
    } else {
      setDisableAdd(true);
    }
  };
  const handleRemoveConyuge = async (index: number, id: string) => {
    if (id !== "" && id !== undefined) {
      try {
        await deletePeps({ id: id });
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }
    }
    const updatedPepsConyuge = formik.values.peps_conyuge.filter((_, i) => i !== index);
    formik.setFieldValue("peps_conyuge", updatedPepsConyuge);
    if (updatedPepsConyuge.length === 0) {
      formik.setFieldValue("conyuge", `false`);
    }
    if (updatedPepsConyuge.length < 5) {
      setDisableAdd(false);
    }
  };

  const handleAddSocio = () => {
    if (formik.values.peps_socio.length < 5) {
      formik.setFieldValue("peps_socio", [
        ...formik.values.peps_socio,
        {
          nombre: "",
          ap_paterno: "",
          ap_materno: "",
          dependencia: "",
          cargo: "",
          actualmente: "",
          start_date: "",
          end_date: "",
          id: "",
        },
      ]);
    } else {
      setDisableAddSocio(true);
    }
  };

  const handleRemoveSocio = async (index: number, id: string) => {
    if (id !== "" && id !== undefined) {
      try {
        await deletePeps({ id: id });
      } catch (error: any) {
        if (error?.status === 500) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }
    }
    const updatedPepsConyuge = formik.values.peps_socio.filter((_, i) => i !== index);
    formik.setFieldValue("peps_socio", updatedPepsConyuge);

    if (updatedPepsConyuge.length === 0) {
      formik.setFieldValue("socioFuncionario", `false`);
    }

    if (updatedPepsConyuge.length < 5) {
      setDisableAdd(false);
    }
  };

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.peps_conyuge?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  const getFieldErrorSocion = (fieldName: string, index: number) => {
    const error: any = formik.errors.peps_socio?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  useEffect(() => {
    if (formik.values.peps_conyuge.length === 0 && formik.values.conyuge === "true") {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
          id: "",
        },
      ]);
    }
  }, [formik.values.conyuge, formik.values.peps_conyuge]);

  useEffect(() => {
    if (formik.values.peps_socio.length === 0 && formik.values.socioFuncionario === "true") {
      formik.setFieldValue("peps_socio", [
        ...formik.values.peps_socio,
        {
          nombre: "",
          ap_paterno: "",
          ap_materno: "",
          dependencia: "",
          cargo: "",
          actualmente: "",
          start_date: "",
          end_date: "",
          id: "",
        },
      ]);
    }
  }, [formik.values.socioFuncionario, formik.values.peps_socio]);

  useEffect(() => {
    if (pepsDataSocio) {
      const dataSocio = pepsDataSocio.filter((item) => item.pep_type_id === pepsIdSocio);

      if (dataSocio.length > 0) {
        const pepsConyugeData = dataSocio.map((item) => {
          const nombreCompleto = separarNombres(item.name);
          return {
            nombre: nombreCompleto.nombre,
            ap_paterno: nombreCompleto.apellido1 || "",
            ap_materno: nombreCompleto.apellido2 || "",
            dependencia: item.dependency,
            cargo: item.position,
            actualmente: item.active ? "true" : "false",
            start_date: dayjs(item.start_date).format("YYYY-MM-DD"),
            end_date: item.end_date === null ? "" : dayjs(item.end_date).format("YYYY-MM-DD"),
            id: item.id,
          };
        });
        formik.setFieldValue("peps_socio", pepsConyugeData);
      }
    }
  }, [pepsDataSocio, pepsIdSocio, isSuccessSocio]);

  useEffect(() => {
    if (pepsDataConyuge) {
      const dataFamiliar = pepsDataConyuge.filter((item) => item.pep_type_id === pepsIdFamiliar);

      if (dataFamiliar.length > 0) {
        const pepsConyugeData = dataFamiliar.map((item) => {
          const nombreCompleto = separarNombres(item.name);
          return {
            parentesco_conyuge: item.relationship_id,
            nombre_conyuge: nombreCompleto.nombre,
            ap_paterno_conyuge: nombreCompleto.apellido1 || "",
            ap_materno_conyuge: nombreCompleto.apellido2 || "",
            dependencia_conyuge: item.dependency,
            cargo_conyuge: item.position,
            actualmente_conyuge: item.active ? "true" : "false",
            start_date_conyuge: dayjs(item.start_date).format("YYYY-MM-DD"),
            end_date_conyuge: item.end_date === null ? "" : dayjs(item.end_date).format("YYYY-MM-DD"),
            id: item.id,
          };
        });
        formik.setFieldValue("peps_conyuge", pepsConyugeData);
      }
    }
  }, [pepsDataConyuge, isSuccessConyugue, pepsIdFamiliar]);

  const separarNombres = (nombreCompleto: string) => {
    const partes = nombreCompleto.split(" ");

    let nombre = "";
    let apellido1 = "";
    let apellido2 = "";

    if (partes.length >= 2) {
      nombre = partes.slice(0, partes.length - 2).join(" ");
      apellido1 = partes[partes.length - 2];
      apellido2 = partes[partes.length - 1];
    } else if (partes.length === 1) {
      nombre = partes[0];
    }

    return {
      nombre,
      apellido1,
      apellido2,
    };
  };

  useEffect(() => {
    if (
      isSuccessCargo &&
      isSuccessConyugue &&
      isSuccessDependence &&
      isSuccessFamiliar &&
      isSuccessPepsType &&
      isSuccessSocio
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [isSuccessCargo, isSuccessConyugue, isSuccessDependence, isSuccessFamiliar, isSuccessPepsType, isSuccessSocio]);

  return (
    <Grid container mb={2}>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box textAlign={"center"} maxWidth="sm" mx="auto">
          <Typography mt={4} mb={3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            Solicitud de crédito
          </Typography>
          <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={800}>
            3{" "}
            <Typography component="span" variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400}>
              de 3
            </Typography>
          </Typography>
          <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
            Datos de la empresa
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                name="socioFuncionario"
                label="¿Alguno de sus socios, accionistas o propietarios reales, desempeña o ha desempeñado funciones públicas destacadas en México o en el extranjero, altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos, en el último año?"
                options={[
                  { value: "true", label: "Sí" },
                  { value: "false", label: "No" },
                ]}
                value={formik.values.socioFuncionario}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.socioFuncionario && Boolean(formik.errors.socioFuncionario)}
                helperText={formik.touched.socioFuncionario ? String(formik.errors.socioFuncionario) : ""}
              />

              {formik.values.socioFuncionario === "true" && (
                <>
                  {formik.values.peps_socio.map((pep, index) => (
                    <Box key={index}>
                      <TextField
                        id={`nombre-${index}`}
                        name={`peps_socio[${index}].nombre`}
                        label="Nombre(s)"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_socio?.[index]?.nombre && getFieldErrorSocion("nombre", index),
                        )}
                        helperText={formik.touched.peps_socio?.[index]?.nombre && getFieldErrorSocion("nombre", index)}
                        value={pep.nombre}
                      />

                      <TextField
                        id={`ap_paterno-${index}`}
                        name={`peps_socio[${index}].ap_paterno`}
                        label="Apellido paterno"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_socio?.[index]?.ap_paterno && getFieldErrorSocion("ap_paterno", index),
                        )}
                        helperText={
                          formik.touched.peps_socio?.[index]?.ap_paterno && getFieldErrorSocion("ap_paterno", index)
                        }
                        value={pep.ap_paterno}
                      />

                      <TextField
                        id={`ap_materno-${index}`}
                        name={`peps_socio[${index}].ap_materno`}
                        label="Apellido materno"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_socio?.[index]?.ap_materno && getFieldErrorSocion("ap_materno", index),
                        )}
                        helperText={
                          formik.touched.peps_socio?.[index]?.ap_materno && getFieldErrorSocion("ap_materno", index)
                        }
                        value={pep.ap_materno}
                      />

                      <FormControl
                        fullWidth
                        required
                        sx={{
                          ...customStylesAsterisk,
                          ...customSelect,
                          mt: 2,
                        }}
                        error={Boolean(
                          formik.touched.peps_socio?.[index]?.dependencia && getFieldErrorSocion("dependencia", index),
                        )}
                      >
                        <InputLabel id={`dependencia-${index}`}>Dependencia del cargo</InputLabel>
                        <Select
                          labelId={`dependencia-${index}`}
                          id={`dependencia-${index}`}
                          name={`peps_socio[${index}].dependencia`}
                          fullWidth
                          required
                          label="Dependencia del cargo"
                          value={pep.dependencia}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_socio?.[index]?.dependencia &&
                              getFieldErrorSocion("dependencia", index),
                          )}
                        >
                          <MenuItem value={""}>Selecciona</MenuItem>
                          {listDependecias.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.peps_socio?.[index]?.dependencia &&
                          getFieldErrorSocion("dependencia", index) && (
                            <FormHelperText>{getFieldErrorSocion("dependencia", index)}</FormHelperText>
                          )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        required
                        sx={{
                          ...customStylesAsterisk,
                          ...customSelect,
                          mt: 2,
                        }}
                        error={Boolean(
                          formik.touched.peps_socio?.[index]?.cargo && getFieldErrorSocion("cargo", index),
                        )}
                      >
                        <InputLabel id={`cargo-${index}`}>Cargo público</InputLabel>
                        <Select
                          labelId={`cargo-${index}`}
                          id={`cargo-${index}`}
                          name={`peps_socio[${index}].cargo`}
                          fullWidth
                          required
                          label="Cargo público"
                          value={pep.cargo}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_socio?.[index]?.cargo && getFieldErrorSocion("cargo", index),
                          )}
                        >
                          <MenuItem value={""}>Selecciona</MenuItem>
                          {listCargo.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.peps_socio?.[index]?.cargo && getFieldErrorSocion("cargo", index) && (
                          <FormHelperText>{getFieldErrorSocion("cargo", index)}</FormHelperText>
                        )}
                      </FormControl>

                      <Box textAlign={"left"} my={2}>
                        <CustomRadioGroup
                          label={"¿Actualmente esta en ese cargo?​"}
                          name={`peps_socio[${index}].actualmente`}
                          value={pep.actualmente}
                          onChange={(e: any) => {
                            formik.handleChange(e);

                            if (e.target.value === "true") {
                              formik.setFieldValue(`peps_socio[${index}].end_date`, "");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.peps_socio?.[index]?.actualmente &&
                              getFieldErrorSocion("actualmente", index),
                          )}
                          helperText={
                            formik.touched.peps_socio?.[index]?.actualmente && getFieldErrorSocion("actualmente", index)
                          }
                          options={[
                            {
                              label: "Si",
                              value: "true",
                            },
                            {
                              label: "No",
                              value: "false",
                            },
                          ]}
                        />
                      </Box>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                          <DatePicker
                            label="Fecha de Inicio"
                            value={pep.start_date ? dayjs(pep.start_date) : null}
                            onChange={(date) => {
                              formik.setFieldValue(`peps_socio[${index}].start_date`, dayjs(date).format("YYYY-MM-DD"));
                              const actualmente = formik.values.peps_socio?.[index]?.actualmente;
                              if (actualmente === "false") {
                                formik.setFieldValue(`peps_socio[${index}].end_date`, dayjs(date).format("YYYY-MM-DD"));
                              }
                            }}
                            maxDate={dayjs()}
                            slotProps={{
                              textField: {
                                name: `peps_socio[${index}].start_date`,
                                onBlur: () => {
                                  const inputValue = formik.values.peps_socio?.[index]?.start_date;
                                  const actualmente = formik.values.peps_socio?.[index]?.actualmente;
                                  const currentDate = dayjs();

                                  if (inputValue) {
                                    const inputDate = dayjs(inputValue);

                                    if (!inputDate.isValid() || inputDate.isAfter(currentDate, "day")) {
                                      formik.setFieldValue(
                                        `peps_socio[${index}].start_date`,
                                        currentDate.format("YYYY-MM-DD"),
                                      );

                                      if (actualmente === "false") {
                                        formik.setFieldValue(
                                          `peps_socio[${index}].end_date`,
                                          currentDate.format("YYYY-MM-DD"),
                                        );
                                      }
                                      return;
                                    }

                                    if (actualmente === "false") {
                                      formik.setFieldValue(
                                        `peps_socio[${index}].end_date`,
                                        dayjs(inputDate).format("YYYY-MM-DD"),
                                      );
                                    }
                                  }
                                },
                                error: Boolean(
                                  formik.touched.peps_socio?.[index]?.start_date &&
                                    getFieldErrorSocion("start_date", index),
                                ),
                                helperText: formik.touched.peps_socio?.[index]?.start_date
                                  ? getFieldErrorSocion("start_date", index)
                                  : "",
                                required: true,
                                inputProps: {
                                  "aria-invalid": Boolean(
                                    formik.touched.peps_socio?.[index]?.start_date &&
                                      getFieldErrorSocion("start_date", index),
                                  ),
                                  "aria-hidden": false,
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </FormControl>

                      {formik.values.peps_socio[index].actualmente === "false" && (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker
                              label="Fecha de termino"
                              value={pep.end_date ? dayjs(pep.end_date) : null}
                              onChange={(date) => {
                                formik.setFieldValue(`peps_socio[${index}].end_date`, dayjs(date).format("YYYY-MM-DD"));
                              }}
                              minDate={pep.start_date ? dayjs(pep.start_date) : undefined}
                              maxDate={dayjs()}
                              slotProps={{
                                textField: {
                                  name: `peps_socio[${index}].end_date`,
                                  onBlur: () => {
                                    const endDateValue = formik.values.peps_socio?.[index]?.end_date;
                                    const startDateValue = formik.values.peps_socio?.[index]?.start_date;
                                    const currentDate = dayjs();

                                    if (endDateValue) {
                                      const endDate = dayjs(endDateValue);
                                      const startDate = dayjs(startDateValue);

                                      if (!endDate.isValid() || endDate.isAfter(currentDate, "day")) {
                                        formik.setFieldValue(
                                          `peps_socio[${index}].end_date`,
                                          currentDate.format("YYYY-MM-DD"),
                                        );
                                      } else if (startDateValue && endDate.isBefore(startDate, "day")) {
                                        formik.setFieldValue(
                                          `peps_socio[${index}].end_date`,
                                          startDate.format("YYYY-MM-DD"),
                                        );
                                      }
                                    }
                                  },
                                  error: Boolean(
                                    formik.touched.peps_socio?.[index]?.end_date &&
                                      getFieldErrorSocion("end_date", index),
                                  ),
                                  helperText: formik.touched.peps_socio?.[index]?.end_date
                                    ? getFieldErrorSocion("end_date", index)
                                    : "",
                                  required: true,
                                  inputProps: {
                                    "aria-invalid": Boolean(
                                      formik.touched.peps_socio?.[index]?.end_date &&
                                        getFieldErrorSocion("end_date", index),
                                    ),
                                    "aria-hidden": false,
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}

                      <Typography
                        onClick={() => handleRemoveSocio(index, pep.id)}
                        color={"#F2704F"}
                        fontSize={"15px"}
                        fontWeight={400}
                        mt={3}
                        sx={{ cursor: disableAdd ? "not-allowed" : "pointer", textDecoration: "" }}
                      >
                        - Remover Persona
                      </Typography>

                      <hr />
                    </Box>
                  ))}
                  <Typography
                    onClick={handleAddSocio}
                    color={"#528CD6"}
                    fontSize={"15px"}
                    fontWeight={400}
                    mt={3}
                    sx={{ cursor: disableAddSocio ? "not-allowed" : "pointer" }}
                  >
                    + Agregar Otro
                  </Typography>
                </>
              )}

              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  name="conyuge"
                  label="¿El cónyuge o alguno de los padres, abuelos, hijos, nietos, hermanos, suegros, hijos políticos, abuelos políticos o cuñados de los socios, accionistas o propietarios reales, desempeña o ha desempeñado funciones públicas destacadas en México o en el extranjero, altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos?"
                  options={[
                    { value: "true", label: "Sí" },
                    { value: "false", label: "No" },
                  ]}
                  value={formik.values.conyuge}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.conyuge && Boolean(formik.errors.conyuge)}
                  helperText={formik.touched.conyuge ? String(formik.errors.conyuge) : ""}
                />
                {formik.values.conyuge === "true" && (
                  <>
                    {formik.values.peps_conyuge.map((pep, index) => (
                      <Box key={index}>
                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            ...customSelect,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                              getFieldError("parentesco_conyuge", index),
                          )}
                        >
                          <InputLabel id={`parentesco_conyuge-${index}`}>Parentesco</InputLabel>
                          <Select
                            labelId={`parentesco_conyuge-${index}`}
                            id={`parentesco_conyuge-${index}`}
                            name={`peps_conyuge[${index}].parentesco_conyuge`}
                            fullWidth
                            required
                            label="Parentesco"
                            value={pep.parentesco_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                                getFieldError("parentesco_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listFamiliar.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                            getFieldError("parentesco_conyuge", index) && (
                              <FormHelperText>{getFieldError("parentesco_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <TextField
                          id={`nombre_conyuge-${index}`}
                          name={`peps_conyuge[${index}].nombre_conyuge`}
                          label="Nombre(s)"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.nombre_conyuge &&
                              getFieldError("nombre_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.nombre_conyuge &&
                            getFieldError("nombre_conyuge", index)
                          }
                          value={pep.nombre_conyuge}
                        />

                        <TextField
                          id={`ap_paterno_conyuge-${index}`}
                          name={`peps_conyuge[${index}].ap_paterno_conyuge`}
                          label="Apellido paterno"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                              getFieldError("ap_paterno_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                            getFieldError("ap_paterno_conyuge", index)
                          }
                          value={pep.ap_paterno_conyuge}
                        />

                        <TextField
                          id={`ap_materno_conyuge-${index}`}
                          name={`peps_conyuge[${index}].ap_materno_conyuge`}
                          label="Apellido materno"
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                              getFieldError("ap_materno_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                            getFieldError("ap_materno_conyuge", index)
                          }
                          value={pep.ap_materno_conyuge}
                        />

                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            ...customSelect,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                              getFieldError("dependencia_conyuge", index),
                          )}
                        >
                          <InputLabel id={`dependencia_conyuge-${index}`}>Dependencia del cargo</InputLabel>
                          <Select
                            labelId={`dependencia_conyuge-${index}`}
                            id={`dependencia_conyuge-${index}`}
                            name={`peps_conyuge[${index}].dependencia_conyuge`}
                            fullWidth
                            required
                            label="Dependencia del cargo"
                            value={pep.dependencia_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                                getFieldError("dependencia_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listDependecias.map((item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                            getFieldError("dependencia_conyuge", index) && (
                              <FormHelperText>{getFieldError("dependencia_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          required
                          sx={{
                            ...customStylesAsterisk,
                            ...customSelect,
                            mt: 2,
                          }}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                              getFieldError("cargo_conyuge", index),
                          )}
                        >
                          <InputLabel id={`cargo_conyuge-${index}`}>Cargo público</InputLabel>
                          <Select
                            labelId={`cargo_conyuge-${index}`}
                            id={`cargo_conyuge-${index}`}
                            name={`peps_conyuge[${index}].cargo_conyuge`}
                            fullWidth
                            required
                            label="Cargo público"
                            value={pep.cargo_conyuge}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                                getFieldError("cargo_conyuge", index),
                            )}
                          >
                            <MenuItem value={""}>Selecciona</MenuItem>
                            {listCargo.map((item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                            getFieldError("cargo_conyuge", index) && (
                              <FormHelperText>{getFieldError("cargo_conyuge", index)}</FormHelperText>
                            )}
                        </FormControl>

                        <Box textAlign={"left"} my={2}>
                          <CustomRadioGroup
                            label={"¿Actualmente esta en ese cargo?​"}
                            name={`peps_conyuge[${index}].actualmente_conyuge`}
                            value={pep.actualmente_conyuge}
                            onChange={(e: any) => {
                              formik.handleChange(e);

                              if (e.target.value === "true") {
                                formik.setFieldValue(`peps_conyuge[${index}].end_date_conyuge`, "");
                              }
                            }}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                                getFieldError("actualmente_conyuge", index),
                            )}
                            helperText={
                              formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                              getFieldError("actualmente_conyuge", index)
                            }
                            options={[
                              {
                                label: "Si",
                                value: "true",
                              },
                              {
                                label: "No",
                                value: "false",
                              },
                            ]}
                          />
                        </Box>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker
                              label="Fecha de Inicio"
                              value={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : null}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `peps_conyuge[${index}].start_date_conyuge`,
                                  dayjs(date).format("YYYY-MM-DD"),
                                );
                              }}
                              maxDate={dayjs()}
                              slotProps={{
                                textField: {
                                  name: `peps_conyuge[${index}].start_date_conyuge`,
                                  onBlur: () => {
                                    const inputValue = formik.values.peps_conyuge?.[index]?.start_date_conyuge;
                                    const actualmente = formik.values.peps_conyuge?.[index]?.actualmente_conyuge;
                                    const currentDate = dayjs();

                                    if (inputValue) {
                                      const inputDate = dayjs(inputValue);

                                      if (!inputDate.isValid() || inputDate.isAfter(currentDate, "day")) {
                                        formik.setFieldValue(
                                          `peps_conyuge[${index}].start_date_conyuge`,
                                          currentDate.format("YYYY-MM-DD"),
                                        );

                                        if (actualmente === "false") {
                                          formik.setFieldValue(
                                            `peps_conyuge[${index}].end_date_conyuge`,
                                            currentDate.format("YYYY-MM-DD"),
                                          );
                                        }
                                        return;
                                      }

                                      if (actualmente === "false") {
                                        formik.setFieldValue(
                                          `peps_conyuge[${index}].end_date_conyuge`,
                                          dayjs(inputDate).format("YYYY-MM-DD"),
                                        );
                                      }
                                    }
                                  },
                                  error: Boolean(
                                    formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                      getFieldError("start_date_conyuge", index),
                                  ),
                                  helperText: formik.touched.peps_conyuge?.[index]?.start_date_conyuge
                                    ? getFieldError("start_date_conyuge", index)
                                    : "",
                                  required: true,
                                  inputProps: {
                                    "aria-invalid": Boolean(
                                      formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                                        getFieldError("start_date_conyuge", index),
                                    ),
                                    "aria-hidden": false,
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        {formik.values.peps_conyuge[index].actualmente_conyuge === "false" && (
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                              <DatePicker
                                label="Fecha de termino"
                                value={pep.end_date_conyuge ? dayjs(pep.end_date_conyuge) : null}
                                onChange={(date) => {
                                  formik.setFieldValue(
                                    `peps_conyuge[${index}].end_date_conyuge`,
                                    dayjs(date).format("YYYY-MM-DD"),
                                  );
                                }}
                                maxDate={dayjs()}
                                minDate={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : undefined}
                                slotProps={{
                                  textField: {
                                    name: `peps_conyuge[${index}].end_date_conyuge`,
                                    onBlur: () => {
                                      const endDateValue = formik.values.peps_conyuge?.[index]?.end_date_conyuge;
                                      const startDateValue = formik.values.peps_conyuge?.[index]?.start_date_conyuge;
                                      const currentDate = dayjs();

                                      if (endDateValue) {
                                        const endDate = dayjs(endDateValue);
                                        const startDate = dayjs(startDateValue);

                                        if (!endDate.isValid() || endDate.isAfter(currentDate, "day")) {
                                          formik.setFieldValue(
                                            `peps_conyuge[${index}].end_date_conyuge`,
                                            currentDate.format("YYYY-MM-DD"),
                                          );
                                        } else if (startDateValue && endDate.isBefore(startDate, "day")) {
                                          formik.setFieldValue(
                                            `peps_conyuge[${index}].end_date_conyuge`,
                                            startDate.format("YYYY-MM-DD"),
                                          );
                                        }
                                      }
                                    },
                                    error: Boolean(
                                      formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                        getFieldError("end_date_conyuge", index),
                                    ),
                                    helperText: formik.touched.peps_conyuge?.[index]?.end_date_conyuge
                                      ? getFieldError("end_date_conyuge", index)
                                      : "",
                                    required: true,
                                    inputProps: {
                                      "aria-invalid": Boolean(
                                        formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                                          getFieldError("end_date_conyuge", index),
                                      ),
                                      "aria-hidden": false,
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        )}

                        <Typography
                          onClick={() => handleRemoveConyuge(index, pep.id)}
                          color={"#F2704F"}
                          fontSize={"15px"}
                          fontWeight={400}
                          mt={3}
                          sx={{ cursor: disableAdd ? "not-allowed" : "pointer", textDecoration: "" }}
                        >
                          - Remover Persona
                        </Typography>

                        <hr />
                      </Box>
                    ))}
                    <Typography
                      onClick={handleAddConyuge}
                      color={"#528CD6"}
                      fontSize={"15px"}
                      fontWeight={400}
                      mt={3}
                      sx={{ cursor: disableAdd ? "not-allowed" : "pointer" }}
                    >
                      + Agregar Otro
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Grid container spacing={2} mt={4} mb={4}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  fullWidth
                  onClick={() => navigate("/producto/creditosimple/avance_expediente_digital")}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="default"
                  fullWidth
                  color="primary"
                  disabled={!formik.isValid || isLoading || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
