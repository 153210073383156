/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "@store/store";
import { CustomCheckList } from "@components/CustomCheckList";
import { CustomDialog } from "@components/CustomDialog";

import img from "@assets/img/right_2.svg";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

interface ModalInfo {
  title: string;
  body: string;
  open: boolean;
}

export const ListaRevision = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [modalInfo, setModalInfo] = useState<ModalInfo>({ body: "", open: false, title: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const application = useSelector((state: RootState) => state.register.application);
  const companyData = useSelector((state: RootState) => state.register.company);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const dispatch = useAppDispatch();

  const handleOnClickNext = async () => {
    try {
      setIsLoading(true);
      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSBEX",
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();
      navigate("/producto/creditosimple/docs_empresa");
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickInfo = (id: string | undefined) => {
    if (id === "empresa") {
      setModalInfo({
        title: "Empresa",
        body: "Una empresa es una entidad formada por un grupo de personas que se unen con el objetivo de producir bienes o servicios para satisfacer necesidades del mercado.",
        open: true,
      });
    } else if (id === "aval") {
      setModalInfo({
        title: "Aval",
        body: "Es el accionista mayoritario de la empresa, que a su vez, será la persona encargada de asegurar que se saldará la deuda.",
        open: true,
      });
    } else if (id === "propietario") {
      setModalInfo({
        title: "Propietario real",
        body: "Persona Física que directa o indirectamente sea titular del 25% de las acciones de la empresa, o que siendo titular de un porcentaje menor, ejerza el control sobre la misma, regularmente el representante legal.",
        open: true,
      });
    }
  };

  const handleOnCloseModal = () => {
    setModalInfo({
      body: "",
      title: "",
      open: false,
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} textAlign={"center"} variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500}>
              ¡Hola {companyData.legal_name}!
            </Typography>
            <Typography mb={3} textAlign={"center"} variant={isMobileOrTablet ? "body2" : "body1"}>
              Ahora completa el expediente digital. <br /> Conforme avances, deberás cargar los siguientes documentos,
              si no los tienes a la mano puedes guardar tu avance y completarlo después
            </Typography>
            <CustomCheckList
              id="empresa"
              sx={{ mt: 3 }}
              title="De la empresa"
              onClickInfo={handleOnClickInfo}
              items={[
                "Acta constitutiva y sus adicionales",
                "Comprobante de domicilio operativo no mayor a 3 meses",
                "Últimos 3 estados de cuenta",
                "Fiel de la empresa",
              ]}
            />
            <CustomCheckList
              id="aval"
              sx={{ mt: 3 }}
              title="Del aval o accionista mayoritario"
              onClickInfo={handleOnClickInfo}
              items={[
                "Comprobante de domicilio no mayor a 3 meses",
                "Últimos 3 estados de cuenta",
                "Constancia de situación fiscal",
                "Identificación oficial vigente (INE, Pasaporte, FM2)",
              ]}
            />
            <CustomCheckList
              id={"propietario"}
              sx={{ my: 3 }}
              title="Del propietario real"
              onClickInfo={handleOnClickInfo}
              items={["Constancia de situación fiscal", "Identificación oficial vigente (INE, Pasaporte, FM2)"]}
            />

            <Button sx={{ margin: "5px 5px" }} variant="default" onClick={handleOnClickNext} disabled={isLoading}>
              Continuar
            </Button>
          </Box>
        </Grid>
        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomDialog
        colorClose="#F2704F"
        open={modalInfo.open}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography variant="body1" color={"#528CD6"} textAlign={"center"} fontWeight={600}>
            {modalInfo.title}
          </Typography>
        }
        itemContent={
          <Typography variant="body2" textAlign={"center"}>
            {modalInfo.body}
          </Typography>
        }
      />
    </>
  );
};
