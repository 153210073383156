/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { Spinner } from "@components/Spinner";
import { QuotationOferta } from "@components/index";
import { formatCurrency } from "@helpers/index";
import {
  usePostValidateNextStageMutation,
  useLazyGetApplicationByApplicationIdQuery,
  useLazyGetConditionsByIdQuery,
  usePostConditionMutation,
} from "@api/applications";
import iconLeft from "@assets/img/icon_left_bottom.svg";
import iconRight from "@assets/img/icon_right_top.svg";
import iconD from "@assets/img/icon_donwload.svg";
import { setLoanStatus, setNewConditionLoan } from "@store/slices/registerSlice";
import { usePostDocumentGenarateMutation } from "@api/documents";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetTaxSystemsQuery } from "@api/catalogs";
import { LoanCondiciones } from "@interfaces/condiciones";
import { TaxSystem } from "@interfaces/catalogs";
import { STATUS_CATALOG, TAX_SYSTEMS } from "@helpers/constantes";

interface NewLoanCondition {
  has_change: boolean;
  amount: number;
  term: number;
  monthlyPayment: number;
}

const Oferta = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getConditions] = useLazyGetConditionsByIdQuery();
  const [getTaxSystems] = useLazyGetTaxSystemsQuery();
  const [getLoanApplication] = useLazyGetApplicationByApplicationIdQuery();
  const [postNewConditions] = usePostConditionMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [createDocument, { isLoading: loadingDocument }] = usePostDocumentGenarateMutation();

  const [condition, setCondition] = useState<LoanCondiciones | null>(null);
  const [taxSystems, setTaxSystems] = useState<TaxSystem | null>(null);
  const [loanApplication, setApplicationData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newConditions, setNewConditions] = useState<NewLoanCondition>({
    has_change: false,
    amount: 0,
    term: 0,
    monthlyPayment: 0,
  });

  const handleOnClickUpdateConditionLoan = async () => {
    if (!condition || !loanApplication) return;

    try {
      setIsLoading(true);
      const dataPost = {
        loan_application: application.id,
        loan_amount: newConditions.amount,
        requested_term: newConditions.term,
      };

      await postNewConditions(dataPost).unwrap();

      dispatch(
        setNewConditionLoan({
          approved_amount: condition.approved_amount,
          approved_term: condition.approved_term,
          interest_rate: condition.interest_rate,
          loanAmount: newConditions.amount,
          loanTerm: newConditions.term,
          amountMonthly: newConditions.monthlyPayment,
        }),
      );

      const { oferta, check_list, informacion_documentos_pfae } = STATUS_CATALOG;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: oferta.code,
        username: `TOFU - ${accountUser.email}`,
      }).unwrap();

      dispatch(setLoanStatus(oferta.code));

      if (application.type == TAX_SYSTEMS.PM.code) {
        navigate(check_list.url);
      } else {
        navigate(informacion_documentos_pfae.url);
      }
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnUpdateValues = (amount: number, term: number, monthlyRate: number) => {
    if (!condition) return;

    const has_change_amount = condition.loan_amount !== amount;
    const has_change_term = condition.requested_term !== term;
    setNewConditions({
      has_change: has_change_amount || has_change_term,
      amount: amount,
      term: term,
      monthlyPayment: monthlyRate,
    });
  };

  const generateTablaAmortizacion = async () => {
    try {
      if (!condition || !loanApplication) return;

      setIsLoading(true);

      const dataPost = {
        loan_application: application.id,
        loan_amount: newConditions.amount,
        requested_term: newConditions.term,
      };

      await postNewConditions(dataPost).unwrap();

      dispatch(
        setNewConditionLoan({
          approved_amount: condition.approved_amount,
          approved_term: condition.approved_term,
          interest_rate: condition.interest_rate,
          loanAmount: newConditions.amount,
          loanTerm: newConditions.term,
          amountMonthly: newConditions.monthlyPayment,
        }),
      );

      const data = {
        loan_application_id: String(application.id),
        document_type: "tabla_amortizacion",
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status === 500) {
        throw new Error(JSON.stringify({ status: 500 }));
      }

      if (documents?.error?.status === 400) {
        throw new Error(JSON.stringify({ status: 400 }));
      }

      if (documents?.data) {
        openFileInNewTab(documents.data);
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const openFileInNewTab = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [conditions, taxSystems, loanApplication] = await Promise.all([
          getConditions(application.id).unwrap(),
          getTaxSystems(application.type).unwrap(),
          getLoanApplication(application.id).unwrap(),
        ]);

        const activeCondition = conditions.find((item) => item.active);
        if (activeCondition) {
          setCondition(activeCondition);
          setTaxSystems(taxSystems);
          setApplicationData(loanApplication.data[0]);
        }
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Spinner open={loadingDocument} />
      <Grid container>
        <Grid item xs={12} md={12} mt={5} sx={{ position: "relative" }}>
          <InactivityHandler />
          {!isMobileOrTablet && (
            <Box
              component="img"
              src={iconRight}
              alt="icono superior derecho"
              sx={{
                position: "absolute",
                top: "10px",
                right: "20px",
                width: "50px",
                height: "50px",
              }}
            />
          )}

          <Box
            component="img"
            src={iconLeft}
            alt="icono inferior izquierdo"
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "22px",
              width: "50px",
              height: "50px",
            }}
          />
          <Container maxWidth={"md"}>
            <Box
              sx={{
                my: 0,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 0, mb: 2 }}>
                <Typography color={"#528CD6"} fontWeight={"500"} align="center" fontSize={"22px"}>
                  ¡Felicidades{" "}
                  {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}!
                </Typography>
              </Box>

              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                  Tienes un crédito autorizado de hasta:
                </Typography>

                <Typography color={"#528CD6"} fontWeight={"600"} align="center" fontSize={"22px"}>
                  {formatCurrency(condition?.approved_amount || 0)} MXN
                </Typography>
              </Box>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                      Con un plazo de hasta:
                    </Typography>

                    <Typography color={"#002652"} fontWeight={"600"} align="center" fontSize={"15px"}>
                      {condition?.approved_term || 0} meses
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                      Y una tasa de:
                    </Typography>

                    <Typography color={"#002652"} fontWeight={"600"} align="center" fontSize={"15px"}>
                      {condition?.interest_rate || 0}%
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <QuotationOferta condition={condition} taxSystems={taxSystems} onUpdateValues={handleOnUpdateValues} />

              <Box mt={2} mb={2}>
                <Button variant="text" onClick={generateTablaAmortizacion}>
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={"#000"} align="center">
                    <img src={iconD} /> Descarga aquí tu tabla de amortización
                  </Typography>
                </Button>
              </Box>

              <Box sx={{ mt: 1, mb: 1 }}>
                <Button
                  variant="default"
                  onClick={handleOnClickUpdateConditionLoan}
                  disabled={isLoading}
                  sx={{ width: "130px", height: "40px" }}
                >
                  Continuar
                </Button>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Oferta;
